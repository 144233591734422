import getBusinessUserOcpsPolicies from '../services/getBusinessUserOcpsPolicies';
import {
    FEEDBACK_SETTING_ID,
    EMAILCOLLECTION_SETTING_ID,
    EMAILCOLLECTION_DEFAULT_SETTING_ID,
    NPS_SURVEY_SETTING_ID,
    SCREENSHOT_SETTING_ID,
    SCREENSHOT_DEFAULT_SETTING_ID,
    RECOMMEND_OUTLOOK_SETTING_ID,
    CHEKIN_INTERVAL_HEADER_KEY,
    LOG_CONTENT_SETTING_ID,
    CONTENT_SAMPLES_DEFAULT_SETTING_ID,
    CONTACT_SUPPORT_SETTING_ID,
    DIAGNOSTICS_TROUBLESHOOTING_SETTING_ID,
    OPTIONAL_CONNECTED_EXPERIENCES_SETTING_ID,
    CONNECTED_OFFICE_EXPERIENCES_ID,
    LOOP_SETTING_ID,
    LOOP_SETTING_ID_FOR_OUTLOOK,
    PERMISSION_SHARING_ID,
    EXPERIENCES_DOWNLOADING_CONTENT_SETTING_ID,
    EXPERIENCES_ANALYZING_CONTENT_SETTING_ID,
    USER_OCPS_POLICY_STORE,
    M365_LINKS_HANDLING_BROWSER_CHOICE_SETTING_ID,
    LOOP_POLLS_SETTING_ID,
    SHOW_CARD_LOOPS,
    LOOP_PLANNER_SETTING_ID,
    LOOP_VIDEO_PLAYBACK_SETTING_ID,
    LOOP_VIDEO_RECORD_SETTING_ID,
} from './constants';
import { getItem, setItem, itemExists } from 'owa-local-storage';
import { owaDate, addMinutes } from 'owa-datetime';
import type OcpsPolicyResponse from '../store/schema/OcpsPolicyResponse';
import type { MailboxInfo } from 'owa-client-types';
import cleanupDeprecatedStore from './cleanupDeprecatedStore';
import type { OcpsPolicyStore, UserOcpsPolicies } from '../store/schema/OcpsPolicyStore';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { PerformanceDatapoint } from 'owa-analytics';

export default async function fetchPoliciesAndSetStore(mailboxInfo: MailboxInfo): Promise<void> {
    let userOcpsPoliciesExpiration: string | null = '';
    const HTTP_RESPONSE_NO_CONTENT = 204;

    const mailboxInfoIndexer = getIndexerValueForMailboxInfo(mailboxInfo);

    let ocpsPolicyStore: OcpsPolicyStore;
    /**
     * If the policies are not configured, set them to default values present at
     * https://www.owiki.ms/wiki/Working_with_feedback_policies
     */
    const defaultOcpsPolicies: UserOcpsPolicies = {
        contactSupportEnabled: '1',
        diagnosticsTroubleshootingEnabled: '1',
        emailCollectionEnabled: '0',
        emailCollectionDefault: '0',
        feedbackEnabled: '1',
        logContentEnabled: '0',
        contentSamplesDefault: '1',
        npsSurveyEnabled: '1',
        optionalConnectedExperiencesEnabled: '1',
        connectedOfficeExperiencesEnabled: '0',
        outlookRecommendationEnabled: '1',
        screenshotEnabled: '0',
        screenshotDefault: '0',
        loopEnabled: '1',
        loopEnabledForOutlook: '1',
        disableSharingPermissions: '0',
        experiencesDownloadingContentEnabled: '1',
        experiencesAnalyzingContentEnabled: '1',
        expirationDate: '',
        M365LinksHandlingBrowserChoice: '',
        loopPollsEnabled: '1',
        showCardLoops: '1',
        loopPlannerEnabled: '1',
        loopVideoPlaybackEnabled: '1',
        loopVideoRecordEnabled: '1',
    };

    const defaultOcpsPolicyStore = {
        policies: {
            [mailboxInfoIndexer]: defaultOcpsPolicies,
        },
        retryFetchCount: 0,
    };

    const settingIdToPolicyMap: Record<string, keyof UserOcpsPolicies> = {
        [FEEDBACK_SETTING_ID]: 'feedbackEnabled',
        [EMAILCOLLECTION_SETTING_ID]: 'emailCollectionEnabled',
        [EMAILCOLLECTION_DEFAULT_SETTING_ID]: 'emailCollectionDefault',
        [NPS_SURVEY_SETTING_ID]: 'npsSurveyEnabled',
        [SCREENSHOT_SETTING_ID]: 'screenshotEnabled',
        [SCREENSHOT_DEFAULT_SETTING_ID]: 'screenshotDefault',
        [LOG_CONTENT_SETTING_ID]: 'logContentEnabled',
        [CONTENT_SAMPLES_DEFAULT_SETTING_ID]: 'contentSamplesDefault',
        [RECOMMEND_OUTLOOK_SETTING_ID]: 'outlookRecommendationEnabled',
        [CONTACT_SUPPORT_SETTING_ID]: 'contactSupportEnabled',
        [DIAGNOSTICS_TROUBLESHOOTING_SETTING_ID]: 'diagnosticsTroubleshootingEnabled',
        [OPTIONAL_CONNECTED_EXPERIENCES_SETTING_ID]: 'optionalConnectedExperiencesEnabled',
        [CONNECTED_OFFICE_EXPERIENCES_ID]: 'connectedOfficeExperiencesEnabled',
        [LOOP_SETTING_ID]: 'loopEnabled',
        [LOOP_SETTING_ID_FOR_OUTLOOK]: 'loopEnabledForOutlook',
        [PERMISSION_SHARING_ID]: 'disableSharingPermissions',
        [EXPERIENCES_DOWNLOADING_CONTENT_SETTING_ID]: 'experiencesAnalyzingContentEnabled',
        [EXPERIENCES_ANALYZING_CONTENT_SETTING_ID]: 'experiencesDownloadingContentEnabled',
        [M365_LINKS_HANDLING_BROWSER_CHOICE_SETTING_ID]: 'M365LinksHandlingBrowserChoice',
        [LOOP_POLLS_SETTING_ID]: 'loopPollsEnabled',
        [SHOW_CARD_LOOPS]: 'showCardLoops',
        [LOOP_PLANNER_SETTING_ID]: 'loopPlannerEnabled',
        [LOOP_VIDEO_PLAYBACK_SETTING_ID]: 'loopVideoPlaybackEnabled',
        [LOOP_VIDEO_RECORD_SETTING_ID]: 'loopVideoRecordEnabled',
    };

    if (itemExists(window, USER_OCPS_POLICY_STORE)) {
        ocpsPolicyStore = JSON.parse(
            getItem(window, USER_OCPS_POLICY_STORE) ?? JSON.stringify(defaultOcpsPolicyStore)
        );

        // Look for specific mailbox policies in case of multi account scenario
        if (ocpsPolicyStore.policies[mailboxInfoIndexer]) {
            userOcpsPoliciesExpiration =
                ocpsPolicyStore.policies[mailboxInfoIndexer].expirationDate;
        } else {
            ocpsPolicyStore.policies[mailboxInfoIndexer] = defaultOcpsPolicies;
        }
    } else {
        ocpsPolicyStore = defaultOcpsPolicyStore;
    }

    if (
        !userOcpsPoliciesExpiration ||
        owaDate('UTC') > owaDate('UTC', new Date(userOcpsPoliciesExpiration).toISOString())
    ) {
        const apiLatencyDatapoint = new PerformanceDatapoint('FetchOcpsPolicies_APILatency');
        const response = await getBusinessUserOcpsPolicies(mailboxInfo);
        apiLatencyDatapoint.end();
        if (response.status !== HTTP_RESPONSE_NO_CONTENT) {
            const checkinInterval = response.headers.has(CHEKIN_INTERVAL_HEADER_KEY)
                ? response.headers.get(CHEKIN_INTERVAL_HEADER_KEY)
                : '90';

            const ocpsResponse: OcpsPolicyResponse = await response.json();
            const today = owaDate('UTC', new Date().toISOString());
            if (checkinInterval) {
                ocpsPolicyStore.policies[mailboxInfoIndexer].expirationDate = addMinutes(
                    today,
                    parseInt(checkinInterval)
                ).toString();
            }
            if (ocpsResponse?.value?.[0]?.policiesPayload.length > 0) {
                ocpsResponse.value[0].policiesPayload.forEach(policy => {
                    const settingEntryName: keyof UserOcpsPolicies =
                        settingIdToPolicyMap[policy.settingId];
                    ocpsPolicyStore.policies[mailboxInfoIndexer][settingEntryName] = policy.value;
                });
                ocpsPolicyStore.retryFetchCount = 0;
                setItem(window, USER_OCPS_POLICY_STORE, JSON.stringify(ocpsPolicyStore));
                cleanupDeprecatedStore();
            }
        }
    }

    return;
}
