// TODO: This file is going to be removed after we graduate the flight: `fwk-new-modern-themes-v1`
// Tracking it here: https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/256287

import { getCdnUrl } from 'owa-config';
import { ThemeConstants } from 'owa-theme-shared';

const ASSETS_PATH = 'assets/mail/themes/modern/v2/mountain';

interface ThemeMap {
    [key: string]: {
        mappedToThemeId: string;
        themeId: string;
        backgroundImages?: {
            light: string;
            dark: string;
        };
    };
}

function modernThemeToClassicThemeMap(): ThemeMap {
    const cdnBaseUrl = getCdnUrl();

    return {
        ['modernmountain']: {
            themeId: 'modernmountain',
            mappedToThemeId: ThemeConstants.OFFICE_NEUTRAL_THEME_ID,
            backgroundImages: {
                light: `${cdnBaseUrl}${ASSETS_PATH}/light.jpg`,
                dark: `${cdnBaseUrl}${ASSETS_PATH}/dark.jpg`,
            },
        },
        ['modern_officeneutral']: {
            themeId: ThemeConstants.OFFICE_NEUTRAL_THEME_ID,
            mappedToThemeId: ThemeConstants.OFFICE_NEUTRAL_THEME_ID,
        },
    };
}

export const getMappedTheme = (modernThemeName: string) => {
    const mappedThemes = modernThemeToClassicThemeMap();
    return (
        mappedThemes[modernThemeName] ?? {
            themeId: modernThemeName,
            mappedToThemeId: modernThemeName,
        }
    );
};
