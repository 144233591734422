import type NavBarLinkData from 'owa-service/lib/contract/NavBarLinkData';
import { oneNoteAppName } from '../strings/oneNoteAppName.locstring.json';
import { appNameWord } from 'owa-locstrings/lib/strings/appNameWord.locstring.json';
import { appExcel } from 'owa-locstrings/lib/strings/appexcel.locstring.json';
import { appPowerPoint } from 'owa-locstrings/lib/strings/apppowerpoint.locstring.json';
import { isConsumer } from 'owa-session-store';
import loc from 'owa-localize';
import type NavBarData from 'owa-service/lib/contract/NavBarData';
import type RecursiveReadOnly from 'owa-service/lib/RecursiveReadOnly';
import getAppHostPath from 'owa-url/lib/getAppHostPath';
import { isEdu, doesActiveExperiencesInclude } from 'owa-bpos-utils';
import { isFeatureEnabled } from 'owa-feature-flags';
import { M365AppId } from '../data/M365AppId';
import { OneDriveText } from 'owa-locstrings/lib/strings/onedrivetext.locstring.json';
import type { MailboxInfo } from 'owa-client-types';
import { isMOSLaunchPagesEnabled } from './isMOSLaunchPagesEnabled';

const wordUrl =
    'https://www.microsoft365.com/launch/word?auth=1&from=OWAPromo&WT.mc_id=PROD_OL-Web_InApp_LeftNav_FreeOfficeBarWord';
const excelUrl =
    'https://www.microsoft365.com/launch/excel?auth=1&from=OWAPromo&WT.mc_id=PROD_OL-Web_InApp_LeftNav_FreeOfficeBarExcel';
const powerpointUrl =
    'https://www.microsoft365.com/launch/powerpoint?auth=1&from=OWAPromo&WT.mc_id=PROD_OL-Web_InApp_LeftNav_FreeOfficeBarPPT';
const oneNoteUrl = 'https://www.onenote.com/notebooks?auth=1';
const oneDriveUrl = 'https://onedrive.live.com?WT.mc_id=PROD_OL-Web_InApp_LeftNav_FreeOfficeBarOD';

const WORD_WORKLOAD_ID = 'ShellWordOnline';
const EXCEL_WORLOAD_ID = 'ShellExcelOnline';
const POWERPOINT_WORKLOAD_ID = 'ShellPowerPointOnline';
const YAMMER_WORKLOAD_ID = 'ShellYammer';
const ONENOTE_WORKLOAD_ID = 'ShellOneNoteOnline';
const BOOKINGS_WORKLOAD_ID = 'ShellBookings';
const ONEDRIVE_WORKLOAD_ID = 'ShellDocuments';

function getAppHostAppPath(appId: string): string {
    return getAppHostPath() + appId;
}

export function getNavBarLinkData(
    appId: string,
    navBarData?: RecursiveReadOnly<NavBarData>,
    mailboxInfo?: MailboxInfo
): RecursiveReadOnly<NavBarLinkData> | null {
    if (isConsumer(undefined, mailboxInfo)) {
        // getBposNavBarData does not work in consumer
        return getNavBarLinkDataForConsumer(appId);
    }

    let workloadId: string;
    switch (appId) {
        case M365AppId.AppStore:
            return {
                Url: getAppHostAppPath(appId),
                Title: 'App Store',
                Text: 'App Store',
            };
        case M365AppId.OrgExplorer:
            return isEdu(mailboxInfo) ||
                !isFeatureEnabled('mos-orgExplorer', mailboxInfo) ||
                !doesActiveExperiencesInclude('PeopleInViva', mailboxInfo)
                ? null
                : {
                      Url: isMOSLaunchPagesEnabled(mailboxInfo)
                          ? getAppHostAppPath(appId)
                          : undefined,
                      Text: 'Org Explorer',
                      Title: 'Org Explorer',
                  };
        case M365AppId.ToDoMetaOs:
            return {
                Url: isMOSLaunchPagesEnabled(mailboxInfo) ? getAppHostAppPath(appId) : undefined,
                Title: 'To Do',
                Text: 'To Do',
            };
        case M365AppId.Word:
            workloadId = WORD_WORKLOAD_ID;
            break;
        case M365AppId.Excel:
            workloadId = EXCEL_WORLOAD_ID;
            break;
        case M365AppId.PowerPoint:
            workloadId = POWERPOINT_WORKLOAD_ID;
            break;
        case M365AppId.OneNote:
            workloadId = ONENOTE_WORKLOAD_ID;
            break;
        case M365AppId.OneDrive:
        case M365AppId.OneDriveMetaOs:
        case M365AppId.OneDriveConsumerMetaOs:
            workloadId = ONEDRIVE_WORKLOAD_ID;
            break;
        case M365AppId.Yammer:
            workloadId = YAMMER_WORKLOAD_ID;
            break;
        case M365AppId.Bookings:
            workloadId = BOOKINGS_WORKLOAD_ID;
            break;
        default:
            return null;
    }

    return (
        navBarData?.WorkloadLinks?.find(linkData => linkData.Id === workloadId) ??
        navBarData?.AppsLinks?.find(linkData => linkData.Id === workloadId) ??
        null
    );
}

function getNavBarLinkDataForConsumer(app: string): RecursiveReadOnly<NavBarLinkData> | null {
    let url;
    let displayName;

    switch (app) {
        case M365AppId.ToDoMetaOs:
            return {
                Url: getAppHostAppPath(app),
                Title: 'To Do',
                Text: 'To Do',
            };
        case M365AppId.PhoneMetaOs:
            return {
                Url: getAppHostAppPath(app),
                Title: 'Phone',
                Text: 'Phone',
            };
        case M365AppId.Word:
            url = wordUrl;
            displayName = appNameWord;
            break;
        case M365AppId.Excel:
            url = excelUrl;
            displayName = appExcel;
            break;
        case M365AppId.PowerPoint:
            url = powerpointUrl;
            displayName = appPowerPoint;
            break;
        case M365AppId.OneNote:
            url = oneNoteUrl;
            displayName = oneNoteAppName;
            break;
        case M365AppId.OneDrive:
        case M365AppId.OneDriveMetaOs:
        case M365AppId.OneDriveConsumerMetaOs:
            url = oneDriveUrl;
            displayName = OneDriveText;
            break;
        default:
            return null;
    }

    return {
        Url: url,
        Text: loc(displayName),
        Title: loc(displayName),
    };
}
