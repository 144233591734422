import type { MailboxInfo } from 'owa-client-types';
import type { AcquisitionCategoryEncoding, PlatformType, StrictTitle } from '../types';

type TitleCreation = {
    id: string;
    nameResolver: (mailboxInfo?: MailboxInfo) => string;
    icons:
        | {
              useAppBarIcon: false;
              normalIcon: string;
              outlineIcon: string;
              useSelectedIcon: false;
          }
        | {
              useAppBarIcon: boolean;
              normalIcon: string;
              selectedIcon: string;
              outlineIcon: string;
              useSelectedIcon: boolean;
          };
    platformType: PlatformType;
};

const PRIMARY_COLOR = '#0078D4';

export function createNativeTitle(
    creationArgs: TitleCreation,
    mailboxInfo?: MailboxInfo
): StrictTitle {
    const { id, nameResolver, icons, platformType } = creationArgs;
    const categories: AcquisitionCategoryEncoding[] = [platformType];
    if (icons.useAppBarIcon) {
        categories.push('appbar_icon');
    }

    const resolvedName = nameResolver(mailboxInfo);
    return {
        __typename: 'Title',
        // Encoded values for tiling: Direct mapping
        ingestionId: id,
        name: resolvedName,
        // Encoded values for tiling: Indirect mapping
        categories,
        longDescription: resolvedName,
        shortDescription: resolvedName,
        iconSmall: {
            __typename: 'M365AcquisitionIcon',
            uri: icons.normalIcon,
        },
        iconLarge: {
            __typename: 'M365AcquisitionIcon',
            uri:
                icons.useAppBarIcon || icons.useSelectedIcon
                    ? icons.selectedIcon
                    : icons.normalIcon,
        },
        iconOutline: {
            __typename: 'M365AcquisitionIcon',
            uri: icons.outlineIcon,
        },
        elementDefinitions: {
            __typename: 'ElementDefinition',
            composeExtensions: [],
            exchangeAddIns: [],
            extensions: [],
            staticTabs: [
                {
                    __typename: 'LaunchPage',
                    entityId: id,
                    name: resolvedName,
                    contentUrl: 'https://example.org/',
                    websiteUrl: '',
                    scopes: ['personal'],
                },
            ],
        },
        // Default contextual values
        accentColor: PRIMARY_COLOR,
        // Default static values
        isFullScreen: false,
        isFullTrust: false,
        developerName: 'Microsoft Corporation',
        developerUrl: 'https://www.microsoft.com/',
        termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
        permissions: [],
        validDomains: [],
        showLoadingIndicator: true,
        blockStatus: false,
        ingestionSource: 'Hardcoded',
        scope: 'Tenant',
        webApplicationInfo: {
            __typename: 'WebApplicationInfo',
            id: '',
            resource: '',
        },
        version: '0.0.0', // NA
        cultureName: 'en-US',
        manifestVersion: '1.13',
        authorization: {
            __typename: 'AppAuthorization',
            permissions: {
                __typename: 'AppPermissions',
                orgWide: [],
                resourceSpecific: [],
            },
        },
        copilotEnabled: false,
        copilotSettings: {
            __typename: 'CopilotSettings',
            displayName: '',
            elementType: 'Extension',
        },
    };
}
