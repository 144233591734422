import { LazyAction, LazyModule, LazyImport } from 'owa-bundling-light';

// import orchestrators
import './orchestrators/applyDefaultOptionsOnLoadFail';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OutlookServiceOptions" */ './lazyIndex'),
    { name: 'OutlookServiceOptions' }
);

export const lazyCreateOrUpdateOptionsForFeature = new LazyAction(
    lazyModule,
    m => m.createOrUpdateOptionsForFeature
);

export const lazyUpdateAddinPinStatus = new LazyAction(lazyModule, m => m.updateAddinPinStatus);

export const lazyUpdateAddInArray = new LazyAction(lazyModule, m => m.updateAddInArray);

// Synchronous exports
export { default as getDefaultOptions } from './data/defaultOptions';

export const lazyGetServerOptionsForFeature = new LazyImport(
    lazyModule,
    m => m.getServerOptionsForFeature
);

// Load the options after bootstrap
// We may, in the future, move this to bootstrap and block boot on its resolution
//
// 'loadOptions' will be called by the following
// 'runAfterInitialRender'. It returns a promise, so if any consumer wants
// to act after this is completed, it can wait on its completion and do work
export const lazyLoadOptions = new LazyAction(lazyModule, m => m.loadOptions);

/* eslint-disable-next-line owa-custom-rules/forbid-package-reexport  -- (https://aka.ms/OWALintWiki)
 * Baseline comment; do not copy-paste. Rexporting from a separate package complicates the package graph.
 *	> Do not re-export from a separate package */
export {
    setOptionValue,
    assignOptionValue,
    initializeOptionValues,
    setExtendedOptionValue,
    setServiceOptionStoreData,
    getOptionsForFeature,
    getOwsOptionsStore,
    getServiceOptionStoreData,
    OwsOptionsFeatureType,
    MailOpenBoostBehavior,
    EditOptionsCommand,
    AnimationPreference,
    SearchScope,
    CommandingViewMode,
    ReopenItems,
    MonarchKeyboardShortcutsMode,
    PinnablePane,
} from 'owa-outlook-service-option-store';
/* eslint-disable-next-line owa-custom-rules/forbid-package-reexport  -- (https://aka.ms/OWALintWiki)
 * Baseline comment; do not copy-paste. Rexporting from a separate package complicates the package graph.
 *	> Do not re-export from a separate package */
export type {
    AdsAggregateOptions,
    ProofingOptions,
    ActiveProxyAddressOptions,
    BohemiaOptions,
    CalendarHelpOptions,
    ActivityFeedOptions,
    MentionEventNotificationsOptions,
    CalendarSurfaceOptions,
    TranslationOptions,
    LinkedInViewProfileOptions,
    EventCaptureOptions,
    ExternalImagesOptions,
    CalendarSurfaceAddinsOptions,
    OwsOptionsBase,
    EUEmailAdsOptions,
    OfficeNeutralThemeOptions,
    NativeAdsRemoveUpsellOptions,
    GdprAds2023Options,
    GdprAdsV3Options,
    LgpdAdsOptions,
    MicrosoftChoiceOptions,
    ComposeAssistanceOptions,
    ConfettiOptions,
    BoostOptions,
    WebPushNotificationsOptions,
    IsBusinessConsumerOptions,
    SurfaceActionsOptions,
    ComposeActionKey,
    ReadActionKey,
    HoverActionKey,
    ActionKey,
    SmartSuggestionsOptions,
    SkypeNotificationOptions,
    DiverseEmojisOptions,
    SxSOptions,
    ReadingPaneConversationOptions,
    ListViewColumnHeadersOptions,
    MailLayoutOptions,
    SearchOptions,
    OwsOptionsStore,
    CommandingOptions,
    StartUpOptions,
    KeyboardShortcutsOptions,
    EmailHeaderContentOptions,
    PinnedPaneOptions,
    EditorAutoFormatOptions,
    PremiumStatusInPrimarySettingsOptions,
    UseSystemDarkModeOptions,
    ComposeFormInPopOutOptions,
    TeamsEmbedChatOptions,
    AutoCorrectOptions,
    AppBarDisplayModeOptions,
} from 'owa-outlook-service-option-store';

export { LoadState, OwsOptionsLoadStateStore, getOptionsLoadState } from './store/store';
export { default as AddinPinModes } from './utils/AddinPinModes';
