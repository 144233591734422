import { LazyAction, LazyBootModule } from 'owa-bundling-light';

export const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "MsalAuth"*/ './lazyIndex')
);

export const lazyFetchAccessTokenFromMsal = new LazyAction(
    lazyModule,
    m => m.fetchAccessTokenFromMsal
);

export const lazyCreateMsalPublicClientApplication = new LazyAction(
    lazyModule,
    m => m.createMsalPublicClientApplication
);

export const lazySignoutFromMsal = new LazyAction(lazyModule, m => m.signoutFromMsal);

export const lazyAcquireAccessTokenMsal = new LazyAction(lazyModule, m => m.acquireAccessTokenMsal);

export const lazyGetAuthTokenForBootFromMsal = new LazyAction(
    lazyModule,
    m => m.getAuthTokenForBootFromMsal
);
export const lazyGetAnchorMailbox = new LazyAction(lazyModule, m => m.getAnchorMailbox);
export const lazyLoginUserMsal = new LazyAction(lazyModule, m => m.loginUserMsal);

export const lazyExecuteMsalNestedAppAuthRequest = new LazyAction(
    lazyModule,
    m => m.executeMsalNestedAppAuthRequest
);
