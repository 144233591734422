import { logStartGreyError } from 'owa-analytics-start';
import { getRawStoreInternalOnly } from '../store/store';

export function checkGlobalSettingsReady(context?: string) {
    const { allowDefaultDataReturn, userConfiguration } = getRawStoreInternalOnly();
    if (!allowDefaultDataReturn && userConfiguration === undefined) {
        const error = new Error('OwaSessionStoreGlobalSettingsNotReady');
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logStartGreyError(error.message, error, { context });
    }
}
