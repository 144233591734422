import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyRespondToMeetingInviteMutationWeb = createLazyResolver(
    'RESPONDTOMEETINGINVITE_MUTATION_WEB',
    () =>
        import(
            /* webpackChunkName: "RespondToMeetingInviteMutationWeb" */ './respondToMeetingInviteMutationWeb'
        ),
    m => m.respondToMeetingInviteMutationWeb
);
