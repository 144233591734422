import { isFeatureEnabled } from 'owa-feature-flags';
import type { PrivacySettings } from '../store/schema/PrivacySettings';
import getStore from '../store/store';
import { getPrivacySettingsFromStartupData } from './getPrivacySettingsFromStartupData';
import { getItem } from 'owa-local-storage';
import { PRIVACY_SETTINGS_LOCAL_STORAGE_KEY } from '../utils/constants';
import { logGreyErrorFromAccounts } from 'owa-account-analytics';

export function getFromStoreOrLocalStorageOrStartupData<TKey extends keyof PrivacySettings>(
    key: TKey
): PrivacySettings[TKey] {
    const { isInitialized, [key]: storeValue } = getStore();

    if (isInitialized) {
        return storeValue;
    }

    // Currently, the startup data doesn't work for roaming settings.
    // There is an auth blocker that prevents PFT creation from substrate to OWS'
    // As a workaround, we are using local storage as a cache instead of the startup data cache.
    // This code needs to be removed once the auth blocker is fixed.
    // https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/216289
    if (isFeatureEnabled('settings-privacySettingsRoaming')) {
        const privacySettingsJson = getItem(window, PRIVACY_SETTINGS_LOCAL_STORAGE_KEY);
        const privacySettings = privacySettingsJson ? JSON.parse(privacySettingsJson) : undefined;

        const localStorageValue = privacySettings?.[key];
        if (localStorageValue !== undefined) {
            return localStorageValue;
        } else {
            logPrivacyNotInitializedError(key, 'localStorage');
        }
    } else {
        const startupDataValue = getPrivacySettingsFromStartupData()?.[key];

        if (startupDataValue !== undefined) {
            return startupDataValue;
        } else {
            logPrivacyNotInitializedError(key, 'startupData');
        }
    }

    return storeValue;
}

function logPrivacyNotInitializedError<TKey extends keyof PrivacySettings>(
    key: TKey,
    errorOrigin: 'localStorage' | 'startupData'
) {
    // This should not happen, the data should come either from the initialized store,
    // the local storage or the startup data. Logging (but not throwing) for monitoring proposes.
    const error = new Error('PrivacySettingsNotInitialized using fallback value');
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
    logGreyErrorFromAccounts(error.message, error, { key, errorOrigin });
}
