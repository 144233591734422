import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PostSharedBoot" */ './lazyIndex'),
    { name: 'PostSharedBoot', prioritize: true }
);

export const lazySetupSharedPostBoot = new LazyAction(lazyModule, m => m.setupSharedPostBoot);
export const lazyUpdateAnalyticsSetup = new LazyAction(lazyModule, m => m.updateAnalyticsSetup);
export const lazyForceUpdateAnalyticsSetup = new LazyAction(
    lazyModule,
    m => m.forceUpdateAnalyticsSetup
);
