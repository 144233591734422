import type RequestOptions from 'owa-service/lib/RequestOptions';
import type MailboxRequestOptions from 'owa-service/lib/MailboxRequestOptions';
import { getRequestOptionsOrDefaultV2 } from './getRequestOptionsOrDefaultV2';
import { getConnectedAccountRequestOptionsV2 } from './getConnectedAccountRequestOptionsV2';
import { isConnectedAccount, isMonarchMultipleAccountsEnabled } from 'owa-accounts-store';
import { getExplicitLogonRequestOptionsV2 } from './getExplicitLogonRequestOptionsV2';
import { getArchiveMailboxRequestOptions } from './getArchiveMailboxRequestOptions';
import { isSharedTypeMailbox } from 'owa-connected-account-headers';
import type { MailboxInfo } from 'owa-client-types';
import { assertNever } from 'owa-assert';
import { isRunningOnWorker } from 'owa-config';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import setCoprincipalAccountExplicitLogonUserIfNeeded from './setCoprincipalAccountExplicitLogonUserIfNeeded';

/**
 * getMailboxSpecificRequestOptions is used to intercept requests at owa-service and add
 * appropriate headers specific to Connected Accounts, Explicit logon etc.
 * @param options specifies MailboxRequestOptions which contains userIdentity (upn of logged in user), mailboxSmtpAddress (upn of mailbox opened) and mailboxType
 */
export default function getMailboxSpecificRequestOptions(
    options: RequestOptions | undefined
): RequestOptions | undefined | Promise<RequestOptions> {
    const mailboxRequestOptions = options as MailboxRequestOptions;
    const userIdentity = mailboxRequestOptions?.mailboxInfo?.userIdentity;

    // Shared and delegate coprincipal mailboxes need to use an explicit logon, this helper function will return
    // the explicit logon if needed for the mailbox being accessed.
    setCoprincipalAccountExplicitLogonUserIfNeeded(options);

    if (!userIdentity || options?.skipMailboxSpecificRequestOptions) {
        return options;
    }

    const requestType = getMailboxRequestType(mailboxRequestOptions?.mailboxInfo);
    switch (requestType) {
        case 'Default':
            return getRequestOptionsOrDefaultV2(options);
        case 'Connected':
            return getConnectedAccountRequestOptionsV2(mailboxRequestOptions);
        case 'Shared':
            return getExplicitLogonRequestOptionsV2(mailboxRequestOptions);
        case 'Archive':
            return getArchiveMailboxRequestOptions(mailboxRequestOptions);
        case 'MonarchConnected':
            return getConnectedAccountRequestOptionsV2(mailboxRequestOptions);
        default:
            return assertNever(requestType);
    }
}

export type MailboxRequestType =
    | 'Default'
    | 'Connected'
    | 'Shared'
    | 'Archive'
    | 'MonarchConnected';

export function getMailboxRequestType(mailboxInfo: MailboxInfo): MailboxRequestType {
    // the worker MUST proxy this call back to main in worker initialize.ts because it
    // requires a fully functional accounts store
    if (isRunningOnWorker()) {
        throw new Error('Cannot compute mailbox request type on the worker!');
    }

    if (isConnectedAccount(mailboxInfo.userIdentity)) {
        return 'Connected';
    } else if (isSharedTypeMailbox(mailboxInfo.type, mailboxInfo.mailboxSmtpAddress)) {
        return 'Shared';
    } else if (mailboxInfo.type == 'ArchiveMailbox') {
        return 'Archive';
    } else if (isMonarchMultipleAccountsEnabled() && !isGlobalAccount(mailboxInfo)) {
        return 'MonarchConnected';
    } else {
        return 'Default';
    }
}

function isGlobalAccount(mailboxInfo: MailboxInfo) {
    const global = getGlobalSettingsAccountMailboxInfo();
    return getIndexerValueForMailboxInfo(global) == getIndexerValueForMailboxInfo(mailboxInfo);
}
