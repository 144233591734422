import type { HostSourceInformation } from 'owa-account-source-list';
import { addCoprincipalHostAccount } from 'owa-account-source-list/lib/utils/sourceList';
import { enableAccountStartupCheck } from 'owa-account-source-list/lib/utils/accountStartupCheck';
import { initializeTryLookupIndexer } from 'owa-account-source-list/lib/utils/initializeTryLookupIndexer';
import { initializeTryLookupIdentityInfo } from 'owa-account-source-list/lib/utils/initializeTryLookupIdentityInfo';
import { SourceAuthType, SourceType } from 'owa-account-source-list/lib/types';
import {
    ExternalDataType,
    getGlobalSettingsAccount,
    isAccountSourceListStoreInitialized,
} from 'owa-account-source-list-store';
import { getPersistenceIdForDefaultAccount } from './getPersistenceIdForDefaultAccount';
import type { MailboxType } from 'owa-client-types';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';

/**
 * Monarch multiple accounts introduces support for the owa-account-source-list-store, when not
 * in Monarch we initialize the store with the information in the default user configuration.
 */
export function initOwaAccountSourceListStore(userConfiguration: OwaUserConfiguration): string {
    if (isAccountSourceListStoreInitialized()) {
        // The account source list store is already initialized we do not need to
        // add the default account to the source list store.
        return getGlobalSettingsAccount().sourceId;
    }

    initializeTryLookupIndexer();
    initializeTryLookupIdentityInfo();

    // Enable the check to make sure the data set during startup is as expected
    enableAccountStartupCheck();

    const persistenceId = getPersistenceIdForDefaultAccount(userConfiguration);
    const settings = userConfiguration.SessionSettings;
    const email = settings?.UserEmailAddress ?? '';
    const displayName = settings?.UserPrincipalName || settings?.UserEmailAddress || email;

    let authProviderType = SourceAuthType.OutlookDotCom;
    if (settings?.WebSessionType === 0) {
        authProviderType = SourceAuthType.Microsoft365;
    } else if (settings?.WebSessionType === 2) {
        authProviderType = SourceAuthType.Google;
    }

    const account: HostSourceInformation = {
        sourceType: SourceType.Mailbox,
        authProviderType,
        displayName,
        contracts: {
            supportsCalendar: true,
            supportsContacts: true,
            supportsMail: true,
            supportsSettings: true,
        },
        externalData: { type: ExternalDataType.None },
        mailbox: {
            type: 'UserMailbox' as MailboxType,
            name: displayName,
            displayName,
            emailAddress: settings?.UserEmailAddress || email,
            loginAddress: settings?.LogonEmailAddress ?? '',
            userIdentity: settings?.LogonEmailAddress ?? '',
            routingHint: '',
            alternateId: '',
            accountUniqueId: '',
            imageUrl: '',
            profileUrl: '',
        },
        persistenceId,
        aliases: userConfiguration.SessionSettings?.UserProxyAddresses ?? [],
        userIdentity: settings?.LogonEmailAddress ?? '',
    };

    return addCoprincipalHostAccount(account);
}
