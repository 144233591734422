import { getPalette } from 'owa-theme';
import type { ICalloutContentStyles, ISearchBoxStyles, Theme } from '@fluentui/react';
import { createTheme } from '@fluentui/react';
import { computed } from 'mobx';
import type { OwaPalette } from 'owa-theme-shared';

const createCurrentRibbonTheme = computed((): Theme => {
    const owaPalette = getPalette();

    // Both OWA and WAC color themes are built around fluent's Theme interface,
    // however, there are some slight semantic differences in usage. This mapping, when
    // applied to only the ribbon region, resolves the problematic differences.

    // OWA's Toolbar uses neutralLight for background and neutralLight for pressed button background
    // Ribbon's background uses neutralLighterAlt and neutralLighter for button hover background
    // In short: These two values can be swapped
    // For reference: WAC semantic definition can be found in actionControlSemanticColorsWithTheme
    // within acui-common/src/styling/actionControlSematicColors.ts.
    const customPalette: OwaPalette = {
        ...owaPalette,
        neutralLight: owaPalette.neutralQuaternary, // activeBackgroundColor
        neutralLighter: owaPalette.neutralLight, // hoverBackgroundColor
        neutralLighterAlt: owaPalette.neutralSecondarySurface, // defaultBackgroundColor
        neutralQuaternary: owaPalette.neutralTertiaryAlt, // groupDividerColor
    };

    const calloutContentStyles: Partial<ICalloutContentStyles> = {
        calloutMain: {
            // Re-mapping `white` to `neutralPrimarySurface`
            ['--white']: owaPalette.neutralPrimarySurface,
        },
    };

    const searchBoxStyles: ISearchBoxStyles = {
        field: {
            '::placeholder': {
                color: customPalette.neutralPrimaryAlt,
            },
        },
    };

    return createTheme({
        components: {
            CalloutContent: { styles: calloutContentStyles },
            SearchBox: { styles: searchBoxStyles },
        },
        palette: customPalette,
    });
});

export const getCurrentRibbonTheme = () => createCurrentRibbonTheme.get();
