import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import { assertNever } from 'owa-assert';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isOcpsPolicyEnabled, OcpsPolicy } from 'owa-ocps-policy-store';
import { getOptionsLoadState, LoadState } from 'owa-outlook-service-options';
import {
    getBrowserInfo,
    getOs,
    isMinimumBrowserVersion,
    type Browser,
    type OperatingSystem,
} from 'owa-user-agent';
import getAttachmentPolicy from 'owa-session-store/lib/utils/getAttachmentPolicy';
import type { FluidOwaSource } from '../enums/FluidEnums';
import { isCollabSpaceEnabledForSource } from './isCollabSpaceEnabledForSource';
import type { MailboxInfo } from 'owa-client-types';
import { getBposNavBarData } from 'owa-bpos-store';
import { isOCPSPolicyEnabledForLoopType } from './isOCPSPolicyEnabledForLoopType';
import type { LoopType } from './constants';

export function isFluidEnabledForSource(
    source: FluidOwaSource,
    mailboxInfo: MailboxInfo,
    loopType: LoopType,
    skipCheckingUserOptions: boolean = false
): boolean {
    // Loop should be disabled for secondary accounts
    if (
        !isFeatureEnabled('mon-fluid-enabledLoopForSecondaryAccount') &&
        !!mailboxInfo &&
        !isPrimaryAccount(mailboxInfo)
    ) {
        const globalMailboxInfo = getGlobalSettingsAccountMailboxInfo();
        logUsage('FluidNotEnabledForSecondaryAccount', {
            source,
            isMailboxInfoPresent: !!mailboxInfo,
            isGlobalMailboxInfoPresent: !!globalMailboxInfo,
        });
        return false;
    }

    const hasAttachmentProvider = getAttachmentPolicy(mailboxInfo)?.AttachmentDataProviderAvailable;
    const hasBPOSNavBarData = getHasBPOSNavBarData(hasAttachmentProvider);
    const hasOneDriveForBusiness = hasAttachmentProvider || hasBPOSNavBarData;

    if (
        // once Fluid is supported in consumer, we want to continue restricting feature access to Cloud Cache users
        // therefore replace isConsumer() with getUserConfiguration().SessionSettings?.IsShadowMailbox
        // For preload we check if fluid is enabled before options are loaded.
        isConsumer(undefined /* smtpAddress */, mailboxInfo) ||
        !hasOneDriveForBusiness ||
        !isBrowserSupported() ||
        !(isOCPSPolicyEnabledForLoopType(loopType, mailboxInfo) || skipCheckingUserOptions)
    ) {
        if (getOptionsLoadState().loadState !== LoadState.OptionsLoaded) {
            logUsage('FluidEnabledNotFetchedWhenChecked', { source });
        } else if (!getAttachmentPolicy(mailboxInfo)) {
            logUsage('FluidEnabledAttachmentPolicyUndefined', { source });
        }
        logUsage('FluidNotEnabled', {
            source,
            isAttachmentPolicyEnabled: hasAttachmentProvider,
            isBrowserSupported: isBrowserSupported(),
            browser: getBrowserInfo().browser,
            isOcpsPolicyEnabled:
                isOcpsPolicyEnabled(OcpsPolicy.LoopEnabledForOutlook, true, mailboxInfo) &&
                isOcpsPolicyEnabled(OcpsPolicy.Loop, true, mailboxInfo),
            isConsumer: isConsumer(undefined /* smtpAddress */, mailboxInfo),
            hasBPOSNavBarData,
        });
        return false;
    }

    switch (source) {
        case 'MailReadingPane':
        case 'NewsletterPreview':
        case 'MailCompose':
        case 'NewsletterCompose':
        case 'CalendarEventBody':
        case 'ForwardEventEditor':
        case 'None':
            if (isFeatureEnabled('cmp-prague')) {
                return true;
            }
            logUsage('FluidNotEnabledFromFlightCheck', { source });
            return false;
        case 'CalendarReadingPane':
        case 'MailCalendarCard':
        case 'CalendarCompose':
            return isCollabSpaceEnabledForSource(source, mailboxInfo);
        case 'MOS':
            return isFeatureEnabled('mos-hoopApp');
        default:
            return assertNever(source);
    }
}

function isBrowserSupported(): boolean {
    const browser: Browser = getBrowserInfo().browser;
    switch (browser) {
        case 'Chrome':
        case 'Edge Anaheim':
            return isMinimumBrowserVersion([80]);
        case 'Firefox':
            return isMinimumBrowserVersion([78]);
        case 'Opera':
            return true;
        case 'Safari':
            return getOs() !== 'Windows' && isMinimumBrowserVersion([13, 4]);
        default:
            return false;
    }
}

function isPrimaryAccount(mailboxInfo: MailboxInfo): boolean {
    return (
        getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo()) ===
        getIndexerValueForMailboxInfo(mailboxInfo)
    );
}

function getHasBPOSNavBarData(hasAttachmentProvider: boolean | undefined): boolean {
    const bposNavBarData = getBposNavBarData();
    const hasNavBarData = !!bposNavBarData?.SPO_RootSiteUrl;
    if (hasNavBarData !== hasAttachmentProvider) {
        logUsage('FluidEnabledCheckNavBarDataMismatch', { hasNavBarData, hasAttachmentProvider });
    }
    return hasNavBarData;
}
