import { areFeatureFlagsInitialized, isFeatureEnabled } from 'owa-feature-flags';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { isBootFeatureEnabled } from 'owa-metatags';

import type { MailboxInfo } from 'owa-client-types';

export function isMonarchAccountManagementEnabled(mailboxInfo?: MailboxInfo): boolean {
    return (
        isHostAppFeatureEnabled('acctmonaccounts') &&
        // Do not throw if the flight settings are not yet enabled, this will only happen
        // when we are still is startup and loading the global settings accounts. At this point
        // we can assume that multi-account is enabled.
        (!areFeatureFlagsInitialized(mailboxInfo) ||
            isFeatureEnabled('acctmonaccounts', mailboxInfo))
    );
}

/**
 * To avoid having to fork the code between Monarch and OWA support is provided for initializing
 * the account-source-list-store when not in Monarch. This method checks the needed flights
 * returns true if the the non-Monarch source list support is enabled.
 * @returns true if the the non-Monarch source list support is enabled.
 */
export function isNonMonarchSourceListSupportEnabled() {
    return !isHostAppFeatureEnabled('acctmonaccounts');
}

/**
 * The source-list API includes the store in which account information is stored, and the owa-account-source-list
 * pacakge which provides support for getting this data from the source list
 * @returns true if the source-list api is enabled
 */
export function isSourceListSupportEnabled(mailboxInfo?: MailboxInfo): boolean {
    return isMonarchAccountManagementEnabled(mailboxInfo) || isNonMonarchSourceListSupportEnabled();
}

/**
 * To making the coding for multiple accounts easier this check is used to determine if
 * the API for multiple accounts should be enabled. This will return true when we are either
 * in Monarch with full multiple account support or if we are not in monarch and the 'accountsapi'
 * feature is enabled.
 * @returns true if the API for multiple accounts should be enabled
 */
export function isMonarchMultipleAccountsEnabled(mailboxInfo?: MailboxInfo): boolean {
    return (
        isMonarchAccountManagementEnabled(mailboxInfo) ||
        (!isHostAppFeatureEnabled('acctmonaccounts') && isBootFeatureEnabled('accountsapi'))
    );
}

/**
 * Returns true if the account start data functionality is enabled, when enabled the data used to start an
 * additional account is obtained from individual calls rather then a single StartupData call, this is done
 * to help make sure we do not impact the StartupData calls used for the global settings account
 */
export function isStartAccountDataEnabled(): boolean {
    return isBootFeatureEnabled('acctStartDataV25') || isBootFeatureEnabled('acctStartDataOWA');
}

/**
 * Returns true if the account source list and related account infra supports handling PST file accounts
 */
export function isPstFileAccountInfraEnabled(): boolean {
    return isBootFeatureEnabled('acct-pstFileInfra');
}
