import type RecursiveReadOnly from 'owa-service/lib/RecursiveReadOnly';
import type SessionSettingsType from 'owa-service/lib/contract/SessionSettingsType';

/**
 * Define a type for the isConsumer and isCloudCache properties so that the
 * TypeScript compiler can enforce that they are passed in the correct order
 */
export interface GetAnchorMailboxFromSessionSettingsProps {
    isConsumer: boolean;
    isCloudCache: boolean;
}

/**
 * Returns the anchor mailbox that should be used with the request
 * This is the lowest level to get the anchormailbox from mailbox Info
 * @param MailboxInfo Specifies the mailbox infor that should be used for routing the request
 * @returns the routing hint that should be used
 */
export function getAnchorMailboxFromSessionSettings(
    props: GetAnchorMailboxFromSessionSettingsProps,
    sessionSettings: RecursiveReadOnly<SessionSettingsType>
): string | undefined {
    const { isConsumer, isCloudCache } = props;

    // First try and get the PUID and tenant id
    const tenant = sessionSettings.ExternalDirectoryTenantGuid;
    const puid = sessionSettings.UserPuid;
    const puidRoutingHint = !!tenant && !!puid ? `PUID:${puid}@${tenant}` : undefined;

    // NOTE: We do not check for EasiID accounts because Monarch does not allow them to be
    // added as coprincipal accounts. And right now thie function only handles the routing
    // hint for coprincipal accounts.

    if (isCloudCache) {
        // Shadow mailboxes are not reliable using the PUID so we always want to route
        // based off of the SMTP address of the shadow mailbox, which will be in logon email address
        if (sessionSettings.LogonEmailAddress) {
            return `SMTP:${sessionSettings.LogonEmailAddress}`;
        }
    } else if (isConsumer) {
        // For consumer mailboxes use the PUID or fallback to MSA
        if (puidRoutingHint || sessionSettings.UserEmailAddress) {
            return puidRoutingHint ?? `MSA:${sessionSettings.UserEmailAddress}`;
        }
    } else {
        // Business will use the PUID or SMTP routing hint
        if (puidRoutingHint || sessionSettings.UserEmailAddress) {
            return puidRoutingHint ?? `SMTP:${sessionSettings.UserEmailAddress}`;
        }
    }

    return undefined;
}
