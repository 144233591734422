import { M365AppId } from '../M365AppId';
import officeSmall from 'owa-appbar-icons/lib/office_48x1.png';
import officeLarge from 'owa-appbar-icons/lib/office_48x2.png';

import type { StrictTitle, PlatformType } from '../../types';

const PRIMARY_COLOR = '#0078D4';

const AppStoreAppBar: StrictTitle = createAppStoreTitle(
    M365AppId.AppStore,
    'https://teams.microsoft.com/extensibility-apps/store/view?host=metaos&language={locale}&metaoshost={hostName}&clienttype={hostClientType}'
);

const AppStoreCompose: StrictTitle = createAppStoreTitle(
    M365AppId.AppStoreModal,
    'https://teams.microsoft.com/extensibility-apps/store/view?host=metaos&language={locale}&metaoshost={hostName}&clienttype={hostClientType}'
);

const AppStoreAbout: StrictTitle = createAppStoreTitle(
    M365AppId.AppStoreAbout,
    'https://teams.microsoft.com/extensibility-apps/appdetails/create?host=metaos&language={locale}&metaoshost={hostName}&clienttype={hostClientType}'
);

function createAppStoreTitle(appId: string, contentUrl: string): StrictTitle {
    return {
        __typename: 'Title',
        ingestionId: appId,
        name: 'App Store',
        categories: ['m365_native_app' as PlatformType],
        longDescription: '',
        shortDescription: '',
        iconSmall: {
            __typename: 'M365AcquisitionIcon',
            uri: officeSmall,
        },
        iconLarge: {
            __typename: 'M365AcquisitionIcon',
            uri: officeLarge,
        },
        iconOutline: {
            __typename: 'M365AcquisitionIcon',
            uri: officeSmall,
        },
        elementDefinitions: {
            __typename: 'ElementDefinition',
            composeExtensions: [],
            exchangeAddIns: [],
            extensions: [],
            staticTabs: [
                {
                    __typename: 'LaunchPage',
                    contentUrl,
                    entityId: 'browse',
                    name: 'App Store',
                    scopes: ['personal'],
                    websiteUrl: '',
                },
            ],
        },
        accentColor: PRIMARY_COLOR,
        isFullTrust: false,
        isFullScreen: true,
        developerName: 'Microsoft Corporation',
        developerUrl: 'https://www.microsoft.com/',
        termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
        permissions: [],
        validDomains: ['*.teams.microsoft.com', '*.teams.office.com'],
        showLoadingIndicator: false,
        blockStatus: false,
        ingestionSource: 'Hardcoded',
        scope: 'Tenant',
        webApplicationInfo: {
            __typename: 'WebApplicationInfo',
            id: appId,
            resource: 'https://teams.microsoft.com',
        },
        version: '0.0.0', // NA
        cultureName: 'en-US',
        manifestVersion: '1.13',
        authorization: {
            __typename: 'AppAuthorization',
            permissions: {
                __typename: 'AppPermissions',
                orgWide: [],
                resourceSpecific: [],
            },
        },
        copilotEnabled: false,
        copilotSettings: {
            __typename: 'CopilotSettings',
            displayName: '',
            elementType: 'Extension',
        },
    };
}

const AppStoreTitles = [AppStoreAppBar, AppStoreCompose, AppStoreAbout];

export { AppStoreTitles, AppStoreAppBar, AppStoreCompose, AppStoreAbout };
