import { observer } from 'owa-mobx-react';
import { FocusZone, FocusZoneDirection } from '@fluentui/react/lib/FocusZone';
import { Icon } from '@fluentui/react/lib/Icon';
import { Image } from '@fluentui/react/lib/Image';
import type { AriaProperties } from 'owa-accessibility';
import { AriaRoles, generateDomPropertiesForAria } from 'owa-accessibility';
import {
    getModuleUrl,
    getModuleUrlForNewAccount,
    lazyGetCloudCacheAccount,
    lazyAddCloudCacheAccount,
    store,
    AddCloudCacheAccountDialog,
    isEasiIdUser,
    isEasiIdUserWithoutCloudCache,
    userHadCloudCacheInSession,
    logUsageBasedOnPremiumFlag,
} from 'owa-cloud-cache-accounts-option';
import { isFeatureEnabled } from 'owa-feature-flags';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { getOwaResourceUrl } from 'owa-resource-url';
import React from 'react';
import isIndexedPath from 'owa-url/lib/isIndexedPath';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getAccountScopeUserSettings, isPremiumConsumer } from 'owa-session-store';
import MailAddRegular from 'owa-fluent-icons-svg/lib/icons/MailAddRegular';

import Outlook_icon_16 from './svg/Outlook_icon_16.svg';
import GOOGLE_SVG from './svg/Google.svg';
import {
    buttonContainer,
    container,
    floatingBorder,
    leftRailButton,
    outlookButton,
    leftRailButtonUnselected,
    gmailButton,
    addNewButton,
} from './AccountSwitcher.scss';

import classnames from 'owa-classnames';

export interface AccountSwitcherProps extends React.HTMLAttributes<HTMLAnchorElement> {
    /**
     *  The action to call when the item is clicked and it also represents the active session.
     * - (eg, clicking Gmail while in Gmail)
     */
    activeAccountAction?: (ev?: React.MouseEvent<unknown>) => void;
}

interface AccountSwitcherEntry {
    /**
     *  The name of the account icon to use.
     */
    iconUrl: string;

    /*
     * The action to call when the item is clicked.
     * If a method, the method will be called.
     * If a string, the window will be navigated to the given string as a url.
     */
    action?:
        | ((ev?: React.MouseEvent<unknown>, item?: AccountSwitcherEntry, index?: number) => void)
        | string;

    /**
     *  If the action is a string we will navigate to that string. This parameter allows us to define if we
     *  should open it in the same tab or not.
     */
    openInSameTab?: boolean;

    ariaLabel?: string;

    title: string;

    sessionType: WebSessionType;
}

export const AccountSwitcher = observer(function AccountSwitcher(props: AccountSwitcherProps) {
    React.useEffect(() => {
        if (
            getAccountScopeUserSettings(getGlobalSettingsAccountMailboxInfo()).SessionSettings
                ?.WebSessionType === 2
        ) {
            // since user can always sign into another account from Google sign in page,
            // we always need to make a Post call to keep the linking info in sync
            lazyAddCloudCacheAccount.importAndExecute();
        } else {
            lazyGetCloudCacheAccount.importAndExecute(
                /* eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion -- (https://aka.ms/OWALintWiki)
                 * Baseline. DO NOT COPY AND PASTE!
                 *	> Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong. */
                getAccountScopeUserSettings(getGlobalSettingsAccountMailboxInfo()).SessionSettings
                    ?.WebSessionType!
            );
        }
    }, []);
    const addNewAccountElement = React.useRef<HTMLElement>();
    const renderItem = (item: AccountSwitcherEntry, index: number) => {
        const isSelected =
            item.sessionType ==
            getAccountScopeUserSettings(getGlobalSettingsAccountMailboxInfo()).SessionSettings
                ?.WebSessionType;
        // If item is the selected session, we should render based on activeAccountAction instead.
        if (isSelected) {
            item.action = props.activeAccountAction;
        }
        /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
         * Baseline, please do not copy and paste this justification
         *	> JSX attribute values should not contain functions created in the same scope */
        const onClickCallback = (ev: React.MouseEvent<unknown>) => onItemClicked(ev, item, index);
        const itemAriaProps: AriaProperties = {
            role: AriaRoles.menuitem,
        };
        return (
            <a
                {...generateDomPropertiesForAria(itemAriaProps)}
                data-is-focusable={true}
                key={index}
                onClick={onClickCallback}
                className={buttonContainer}
            >
                {getAccountIconElement(item, isSelected)}
                <div className={getBorderButtonCSS(item, isSelected)} />
            </a>
        );
    };
    const getAccountIconElement = (
        item: AccountSwitcherEntry,
        isSelected: boolean
    ): JSX.Element => {
        if (item.sessionType === 2) {
            if (store.cloudCacheConfigItem.emailAddress === null) {
                return (
                    <>
                        {/*// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS2322 (136,30): Type '(ref: HTMLElement) => HTMLElement' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
                    // @ts-expect-error */}
                        <div ref={setTargetRef}>
                            <Icon
                                iconName={MailAddRegular}
                                className={getAccountButtonCSS(item, isSelected)}
                                ariaLabel={item.ariaLabel}
                                title={item.ariaLabel}
                            />
                        </div>
                        {isEasiIdUserWithoutCloudCache() && !userHadCloudCacheInSession() && (
                            <AddCloudCacheAccountDialog />
                        )}
                    </>
                );
            } else {
                return (
                    <>
                        {/*// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS2322 (136,30): Type '(ref: HTMLElement) => HTMLElement' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
                    // @ts-expect-error */}
                        <div ref={setTargetRef}>
                            <Image
                                shouldFadeIn={false}
                                src={getOwaResourceUrl(item.iconUrl)}
                                className={getAccountButtonCSS(item, isSelected)}
                                aria-label={item.ariaLabel}
                                title={item.ariaLabel}
                            />
                        </div>
                    </>
                );
            }
        } else {
            return (
                <Image
                    shouldFadeIn={false}
                    src={getOwaResourceUrl(item.iconUrl)}
                    className={getAccountButtonCSS(item, isSelected)}
                    aria-label={item.ariaLabel}
                    title={item.ariaLabel}
                />
            );
        }
    };
    const setTargetRef = (ref: HTMLElement) => {
        return (addNewAccountElement.current = ref);
    };

    let accounts: AccountSwitcherEntry[] = [];
    const containerAriaProps: AriaProperties = {
        role: AriaRoles.menu,
    };
    const containerClassName = classnames(props.className, container);
    if (
        isFeatureEnabled('auth-cloudCache') &&
        !getAccountScopeUserSettings(getGlobalSettingsAccountMailboxInfo()).IsConsumerChild &&
        (isPremiumConsumer(getGlobalSettingsAccountMailboxInfo()) ||
            /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
             * Adding IsShadowMailbox to restricted properties/methods.
             *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
            getAccountScopeUserSettings(getGlobalSettingsAccountMailboxInfo()).SessionSettings
                ?.IsShadowMailbox ||
            isEasiIdUser()) &&
        isIndexedPath()
    ) {
        accounts = [
            {
                iconUrl: Outlook_icon_16,
                action: getModuleUrl(1),
                openInSameTab: false,
                title: 'Outlook',
                sessionType: 1,
                ariaLabel:
                    store.cloudCacheConfigItem.exchangeSmtpAddress ||
                    getAccountScopeUserSettings(getGlobalSettingsAccountMailboxInfo())
                        .SessionSettings?.UserEmailAddress,
            },
            {
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2322 (220,17): Type 'string | null' is not assignable to type 'string'.
                // @ts-expect-error
                iconUrl: store.cloudCacheConfigItem.emailAddress ? GOOGLE_SVG : null,
                action: store.cloudCacheConfigItem.emailAddress
                    ? getModuleUrl(2) + '&login_hint=' + store.cloudCacheConfigItem.emailAddress
                    : getModuleUrlForNewAccount(),
                openInSameTab: false,
                title: store.cloudCacheConfigItem.emailAddress ? 'Gmail' : 'AddNew',
                sessionType: 2,
                ariaLabel: store.cloudCacheConfigItem.emailAddress || 'Add Gmail account',
            },
        ];
    }
    return accounts.length > 0 ? (
        <FocusZone
            className={containerClassName}
            isCircularNavigation={false}
            direction={FocusZoneDirection.vertical}
            {...generateDomPropertiesForAria(containerAriaProps)}
        >
            {accounts.map(renderItem)}
        </FocusZone>
    ) : null;
}, 'AccountSwitcher');

function getBorderButtonCSS(item: AccountSwitcherEntry, isSelected: boolean): string {
    switch (item.title) {
        case 'Outlook':
        case 'Gmail':
            return classnames(isSelected && floatingBorder);
        default:
            return '';
    }
}

function getAccountButtonCSS(item: AccountSwitcherEntry, isSelected: boolean): string {
    switch (item.title) {
        case 'Outlook':
            return classnames(
                leftRailButton,
                outlookButton,
                !isSelected && leftRailButtonUnselected
            );
        case 'Gmail':
            return classnames(leftRailButton, gmailButton, !isSelected && leftRailButtonUnselected);
        case 'AddNew':
            return classnames(addNewButton);
        default:
            return '';
    }
}

function onItemClicked(ev: React.MouseEvent<unknown>, item: AccountSwitcherEntry, index: number) {
    ev.preventDefault();
    ev.stopPropagation();

    if (item.sessionType == 2) {
        // If user clicked on Gmail account switcher
        if (item.title == 'Gmail') {
            logUsageBasedOnPremiumFlag('clickedGmailCloudCacheAccountFromAccountSwitcher');
        }
        if (item.title == 'AddNew') {
            logUsageBasedOnPremiumFlag('addCloudCacheAccountClickedFromAccountSwitcher');
        }
    }
    // If action is a string then open the url in new tab
    if (item.action instanceof String || typeof item.action === 'string') {
        window.open(item.action.toString(), '_blank');
    }
    // If we need to invoke an action do it if it is valid
    else if (item.action instanceof Function) {
        item.action(ev, item, index);
    }
}
