import { getCurrentCulture } from 'owa-localize';
import { getAccountScopeUserSettings, isShadowMailboxUser } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';

export function isChinaMarket(mailboxInfo?: MailboxInfo): boolean {
    const accountMailboxInfo = mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
    const userCulture = getCurrentCulture();

    if (!isShadowMailboxUser(accountMailboxInfo)) {
        // For MSA account, we use the live profile's region selected by the user
        // Fall back to the user culture if user has not selected any country in the MSA live profile.
        const userConfiguration = getAccountScopeUserSettings(accountMailboxInfo);

        // If AdMarket is null which means user does not select any country in the MSA live profile, and is not China market.
        if (!userConfiguration.AdMarket || !/^[a-zA-z\-]*$/.test(userConfiguration.AdMarket)) {
            return userCulture ? userCulture.toLowerCase().split('-')[1] == 'cn' : false;
        } else {
            return userConfiguration.AdMarket.toLowerCase().split('-')[1] == 'cn';
        }
    } else {
        // For the cloud cache account, we use the Monarch or OWA's language to determine China market.
        return userCulture ? userCulture.toLowerCase().split('-')[1] == 'cn' : false;
    }
}
