enum GovernPriority {
    // The framework tasks should all be sync and really fast
    Framework = 0,
    Lightning = 1,
    Notifications = 2,
    SuiteHeader = 3,
    Inbox = 4,
    Ribbon = 5,
    Compose = 6,
    Analytics = 7, // unused at the moment
    Messages = 8,
    Calendar = 9,
    Default = 10,
    // Everything after Default will be considered an idle task
    IdleInbox = 11,
    IdleCompose = 12,
    Idle = 20,
}

export { GovernPriority };
