// The enum is defined on the server, and should be kept in sync
// In the server-ows-api project: src/Microsoft.OWS.UserSettings/Models/OutlookOptions/OutlookSettingsFactory.cs
// Work item 11646 in Outlook Web tracks improving this
export enum OwsOptionsFeatureType {
    TestFeature = 1,
    SmartSuggestions = 2,
    DiverseEmojis = 4,
    SurfaceActions = 5,
    SkypeNotifications = 6,
    WebPushNotifications = 7,
    Confetti = 8,
    GdprAdsPrefs = 9,
    CalendarSurfaceAddins = 10,
    ExternalImages = 11,
    EventCapture = 12,
    Translation = 14,
    CalendarSurfaceOptions = 15,
    TxpEventNotifications = 16,
    MentionEventNotifications = 17,
    ComposeAssistance = 18,
    ActivityFeed = 19,
    CalendarHelp = 21,
    LinkedInViewProfile = 22,
    Bohemia = 23, //DEPRECATED
    GdprAdsV2 = 24,
    Proofing = 25,
    ActiveProxyAddress = 26,
    GdprAdsV3 = 28,
    SxS = 29,
    ReadingPaneConversation = 30,
    AdsAggregate = 31,
    ListViewColumnHeaders = 32,
    MailLayout = 33,
    Commanding = 34,
    Search = 35,
    LgpdAds = 36,
    MicrosoftChoice = 37,
    StartUp = 38,
    KeyboardShortcuts = 39,
    EmailHeaderContent = 40,
    PinnedPane = 41,
    EUEmailAds = 42,
    OnboardingCampaign = 43,
    OfficeNeutralTheme = 44,
    IsBusinessConsumer = 45,
    ApprovedSenders = 46,
    NativeAdsRemoveUpsell = 47,
    EditorAutoFormat = 48,
    PremiumStatusInPrimarySettings = 49,
    UseSystemDarkMode = 50,
    ComposeFormInPopOut = 51,
    ComposeFormInPopOutForOWA = 52,
    TeamsEmbedChat = 53,
    Boost = 54,
    GdprAds2023 = 55,
    AutoCorrect = 56,
    AppBarDisplayMode = 57,
    Dictation = 58,
}
