import { Module } from 'owa-workloads';
import { lazyBootstrapAppBarCache } from 'owa-appbar-bootstrap';
import { registerM365ApplicationTypePolicies } from 'owa-appbar-typepolicies';
import { bootstrapNovaCache, registerNovaTypePolicies } from 'owa-nova-cache';
import {
    registerM365AcquisitionsTypePolicies,
    bootstrapM365AcquisitionsCache,
} from 'owa-m365-acquisitions-cache';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { isFeatureEnabled, isAddinMultiAccountEnabled } from 'owa-feature-flags';

// Necessary to ensure the app bar selection state is initialized when the app is first loaded.
import 'owa-appbar-state';

import type { NormalizedCacheObject, ApolloClient } from '@apollo/client';
import type { BootstrapOptions } from '../types/BootstrapOptions';
import { trySetBposNavBarData } from 'owa-bpos-store';
import { getAccountScopeUserSettings } from 'owa-session-store';

/**
 * Bootstraps apollo cache promise
 * @param apolloClient
 * @param options Bootstrap options
 * @returns Apollo Client
 */
export async function bootstrapApolloCache(
    apolloClient: ApolloClient<NormalizedCacheObject>,
    options: BootstrapOptions,
    themePromise: Promise<void>
): Promise<ApolloClient<NormalizedCacheObject>> {
    // We need to cast it here to avoid a circular dep with owa-app-module's
    // AppBootstrapOptions declaration, which extends this module's BootstrapOptions.
    type AppBootstrapOptions = BootstrapOptions & {
        appModuleProps: {
            module: string;
        };
    };
    const currentModule: string | undefined = (options as AppBootstrapOptions).appModuleProps
        ?.module;

    if (!currentModule || !shouldPrimeAppBarCache(currentModule)) {
        return apolloClient;
    }

    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();

    if (isFeatureEnabled('mos-mos3AppService')) {
        if (isAddinMultiAccountEnabled()) {
            const userSettings = getAccountScopeUserSettings(mailboxInfo);
            if (userSettings.NavBarData) {
                trySetBposNavBarData(userSettings.NavBarData ?? null, mailboxInfo);
            }
        }
        // Primes local cached catalog and is integrated to Taos MOS3 app service
        await themePromise;

        registerM365AcquisitionsTypePolicies(apolloClient);
        bootstrapM365AcquisitionsCache(isAddinMultiAccountEnabled() ? mailboxInfo : undefined);
        registerNovaTypePolicies(apolloClient);
        bootstrapNovaCache();
    } else {
        lazyBootstrapAppBarCache.import();

        // Primes only app bar cache and is integrated to Teams MT app service
        await themePromise;

        registerM365ApplicationTypePolicies(apolloClient);
        await lazyBootstrapAppBarCache.importAndExecute(apolloClient);
    }

    return apolloClient;
}

export type BootstrapApolloCacheFunc = typeof bootstrapApolloCache;

/**
 * Determines whether app bar is enabled for a given module
 * @param currentModule
 * @returns true if app bar enabled
 */
function shouldPrimeAppBarCache(currentModule: string): boolean {
    const appBarEnabled = [
        Module.Mail,
        Module.Calendar,
        Module.AppHost,
        Module.People,
        Module.FilesHub,
        Module.Groups,
    ];

    return appBarEnabled.indexOf(currentModule as Module) !== -1;
}
