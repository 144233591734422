import { getLocalizedStringStore } from './store/store';
import { format } from './utilities/format';
import { orchestrator } from 'satcheljs';
import { addLocstringsToStore } from './actions/addLocstringsToStore';
import type { ResourceId } from './ResourceId';
import type { LocalizedString } from './LocalizedString';
import type { FormatArg } from './utilities/format';

export function loc(
    resourceId: ResourceId | null | undefined,
    ...args: FormatArg[]
): LocalizedString {
    const locString =
        (process.env.NODE_ENV === 'dev'
            ? (resourceId as LocalizedString)
            : getLocalizedStringStore().localizedStrings.get(resourceId || '')) ?? '';
    return args.length ? format(locString, ...args) : locString;
}

const unresolvedStringRequests = {} as Record<
    ResourceId,
    ((localizedString: LocalizedString) => void)[]
>;

/**
 * This function returns a Promise that is guaranteed to resolve with the actual localized string,
 * rather than returning an empty string if the string is not yet loaded. This is primarily intended
 * for GraphQL resolvers, not for observable components that can re-render when a string is loaded.
 */
export function getPromiseForLocalizedString(resourceId: ResourceId): Promise<LocalizedString> {
    if (process.env.NODE_ENV === 'dev') {
        return Promise.resolve(resourceId as LocalizedString);
    }

    if (getLocalizedStringStore().localizedStrings.has(resourceId)) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return Promise.resolve(getLocalizedStringStore().localizedStrings.get(resourceId)!);
    } else {
        return new Promise<LocalizedString>(resolve => {
            unresolvedStringRequests[resourceId] = unresolvedStringRequests[resourceId] ?? [];
            unresolvedStringRequests[resourceId].push(resolve);
        });
    }
}

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(addLocstringsToStore, actionMessage => {
    for (const resourceId of Object.keys(actionMessage.str) as ResourceId[]) {
        if (unresolvedStringRequests[resourceId]) {
            for (const callback of unresolvedStringRequests[resourceId]) {
                callback(actionMessage.str[resourceId] as LocalizedString);
            }
            delete unresolvedStringRequests[resourceId];
        }
    }
});
