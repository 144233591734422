import type { Hotkeys, KeyboardKeys } from './types/HotkeyCommand';
import type { TraceComponent } from 'owa-trace';
import { trace } from 'owa-trace';
import { getCharacterFromEvent, REVERSE_MAP } from './utils/getCharacterFromEvent';
import { addToTimingMap } from 'owa-performance/lib/utils/timingMap';

type KeyDownHandler = (evt: KeyboardEvent) => void;
type KeyBindingMap = {
    [P in KeyboardKeys]?: KeyDownHandler;
};

type KeyEvent = 'keypress' | 'keydown';

const traceComponent: TraceComponent = 'keyboard';

export class Keytrap {
    private eventMapping: {
        [P in KeyEvent]: KeyBindingMap;
    } = { keydown: {}, keypress: {} };
    private sequenceMapping: KeyBindingMap = {};
    private listenerFunc: {
        [P in KeyEvent]?: (ev: Event) => void;
    } = {};
    private hasSequences: boolean = false; // this is a perf optimization so we don't have to recalculate on each key
    private currentSequence: string = '';
    private timerHandle: number = 0;
    private ignoreNextKeyPressChar: string | undefined;
    constructor(private targetElement: Element) {}
    public reset() {
        for (const e of Object.keys(this.eventMapping)) {
            this.removeListener(e as KeyEvent);
        }
        this.eventMapping = { keydown: {}, keypress: {} };
        this.sequenceMapping = {};
        this.hasSequences = false;
        this.currentSequence = '';
        self.clearTimeout(this.timerHandle);
    }
    public bind(key: Hotkeys, action: KeyDownHandler) {
        this.forEachKey(key, k => {
            if (this.isSequence(k)) {
                this.addListener('keydown');
                this.hasSequences = true;
                this.sequenceMapping[k] = action;
            } else {
                // if there is a modifier then we will use the keydown event
                const eventToUse: KeyEvent =
                    k.indexOf('+') > -1 || REVERSE_MAP[k] ? 'keydown' : 'keypress';
                this.addListener(eventToUse);
                const keyBinding = (this.eventMapping[eventToUse] =
                    this.eventMapping[eventToUse] || {});
                keyBinding[k] = action;
            }
        });
    }
    public unbind(key: Hotkeys) {
        this.forEachKey(key, k => {
            if (this.isSequence(k)) {
                delete this.sequenceMapping[k];
            }

            Object.keys(this.eventMapping).map((e: string) => {
                const ev = e as KeyEvent;
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                 * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                 *	> Forbidden non-null assertion. */
                const keyBinding = this.eventMapping[ev]!;
                delete keyBinding[k];
            });
        });
        if (Object.keys(this.sequenceMapping).length == 0) {
            this.hasSequences = false;
        }

        // if there are no keydown callbacks and no sequences, them remove the listener
        if (isEmpty(this.eventMapping.keydown) && isEmpty(this.sequenceMapping)) {
            this.removeListener('keydown');
        }

        // if there are no keypress callbacks then remove the listener
        if (isEmpty(this.eventMapping.keypress)) {
            this.removeListener('keypress');
        }
    }
    private isSequence(k: KeyboardKeys): boolean {
        return k.indexOf(' ') > -1;
    }
    private handleKeyEvent(e: Event) {
        const ev = e as KeyboardEvent;
        const eventType = ev.type as KeyEvent;
        const charData = getCharacterFromEvent(ev);
        const key = charData.char as KeyboardKeys;
        addToTimingMap('keytrap', `${eventType}_${key}`);
        const baseChar = charData.baseChar;
        trace.verbose(`Received key ${key} for event ${eventType}`, traceComponent);

        if (this.hasSequences && eventType == 'keydown') {
            self.clearTimeout(this.timerHandle);
            this.currentSequence += key;
            const currentSequenceCb = this.sequenceMapping[this.currentSequence as KeyboardKeys];
            if (currentSequenceCb) {
                fireCallback(currentSequenceCb, ev, this.currentSequence as KeyboardKeys);
            }
            const allSequences = Object.keys(this.sequenceMapping);
            // if there are no sequences that start with the current sequence and are not that sequence
            // then reset the current sequence
            if (
                !allSequences.some(
                    s => s.startsWith(this.currentSequence) && s != this.currentSequence
                )
            ) {
                this.currentSequence = '';
            } else {
                this.currentSequence += ' ';
            }
            this.timerHandle = self.setTimeout(() => {
                this.currentSequence = '';
            }, 500);
        }

        if (
            this.ignoreNextKeyPressChar &&
            this.ignoreNextKeyPressChar == key &&
            eventType == 'keypress'
        ) {
            trace.info(`Ignore keypress for ${key}`);
        } else {
            const cb = this.eventMapping[eventType]?.[key];
            if (cb) {
                fireCallback(cb, ev, key);
            }
        }

        // if we are a keydown, we will store the basechar because we will want to
        // ignore the next keypress if it comes after
        this.ignoreNextKeyPressChar =
            eventType == 'keydown' && baseChar != key ? baseChar : undefined;
        setTimeout(() => {
            this.ignoreNextKeyPressChar = undefined;
        }, 200);
    }
    private addListener(e: KeyEvent) {
        if (!this.listenerFunc[e]) {
            const self = this;
            const listener = (this.listenerFunc[e] = ev => this.handleKeyEvent.apply(self, [ev]));
            trace.info(
                `Adding event listener ${e} for element with classes ${this.targetElement?.className}`,
                traceComponent
            );
            this.targetElement.addEventListener(e, listener);
        }
    }
    private removeListener(e: KeyEvent) {
        const listener = this.listenerFunc[e];
        if (listener) {
            trace.info(
                `Removing ${e} event listener for element with classes ${this.targetElement?.className}`,
                traceComponent
            );
            this.targetElement.removeEventListener(e, listener);
            delete this.listenerFunc[e];
        }
    }
    private forEachKey(hotKeys: Hotkeys, cb: (key: KeyboardKeys) => void) {
        if (Array.isArray(hotKeys)) {
            for (const k of hotKeys) {
                cb(k);
            }
        } else {
            cb(hotKeys);
        }
    }
}

function isEmpty(obj: object): boolean {
    return Object.keys(obj).length == 0;
}

function fireCallback(cb: KeyDownHandler, ev: KeyboardEvent, key: KeyboardKeys) {
    trace.info(`Found callback for event ${ev.type} on key ${key}`, traceComponent);
    cb(ev);
}
