import { getQueryStringParameter, hasQueryStringParameter } from 'owa-querystring';

interface AdditionalParameter {
    parameter: string;
    isEnabled: () => boolean;
    getValue: () => string;
}

const CommonAdditionalParameters = [
    ...[
        'ep',
        'version',
        'pm',
        'trackRender',
        'trackRenderCollapseGroups',
        'trackRenderSkipHooks',
        'trackRenderLogOnDifferentValues',
    ].map(parameter => ({
        parameter,
        isEnabled: () => hasQueryStringParameter(parameter),
        getValue: () => encodeURIComponent(getQueryStringParameter(parameter)),
    })),
];

export function appendParametersToContentUrl(
    contentUrl: string,
    additionalParameters: AdditionalParameter[] = []
) {
    for (const additionalParameter of [...CommonAdditionalParameters, ...additionalParameters]) {
        if (additionalParameter.isEnabled()) {
            const value = additionalParameter.getValue();
            const processedValue = value ? `=${value}` : '';
            contentUrl += `&${additionalParameter.parameter}${processedValue}`;
        }
    }
    return contentUrl;
}
