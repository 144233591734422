import {
    USER_OCPS_POLICIES_EXPIRATION,
    USER_OCPS_NPS_SURVEY_ENABLED,
    USER_OCPS_FEEDBACK_ENABLED,
    USER_OCPS_EMAIL_COLLECTION_ENABLED,
    USER_OCPS_SCREENSHOT_ENABLED,
    USER_OCPS_OUTLOOK_RECOMMENDATION_ENABLED,
    USER_OCPS_LOG_CONTENT_ENABLED,
    USER_OCPS_CONTACT_SUPPORT_ENABLED,
    USER_OCPS_DIAGNOSTICS_TROUBLESHOOTING_ENABLED,
    USER_OCPS_OPTIONAL_CONNECTED_EXPERIENCES_ENABLED,
    USER_OCPS_LOOP_ENABLED,
    USER_OCPS_LOOP_ENABLED_FOR_OUTLOOK,
    USER_OCPS_DISABLE_SHARING_PERMISSIONS,
    USER_OCPS_EXPERIENCES_DOWNLOADING_CONTENT_ENABLED,
    USER_OCPS_EXPERIENCES_ANALYZING_CONTENT_ENABLED,
} from './constants';

import { removeItem, itemExists } from 'owa-local-storage';

export default function cleanupDeprecatedStore() {
    const policies: string[] = [
        USER_OCPS_POLICIES_EXPIRATION,
        USER_OCPS_NPS_SURVEY_ENABLED,
        USER_OCPS_FEEDBACK_ENABLED,
        USER_OCPS_EMAIL_COLLECTION_ENABLED,
        USER_OCPS_SCREENSHOT_ENABLED,
        USER_OCPS_OUTLOOK_RECOMMENDATION_ENABLED,
        USER_OCPS_LOG_CONTENT_ENABLED,
        USER_OCPS_CONTACT_SUPPORT_ENABLED,
        USER_OCPS_DIAGNOSTICS_TROUBLESHOOTING_ENABLED,
        USER_OCPS_OPTIONAL_CONNECTED_EXPERIENCES_ENABLED,
        USER_OCPS_LOOP_ENABLED,
        USER_OCPS_LOOP_ENABLED_FOR_OUTLOOK,
        USER_OCPS_DISABLE_SHARING_PERMISSIONS,
        USER_OCPS_EXPERIENCES_DOWNLOADING_CONTENT_ENABLED,
        USER_OCPS_EXPERIENCES_ANALYZING_CONTENT_ENABLED,
    ];

    policies.forEach(policy => {
        if (itemExists(window, policy)) {
            removeItem(window, policy);
        }
    });
}
