import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type { ConsumerTokenRequestParams } from '../schema/TokenRequestParams';
import sleep from 'owa-sleep';
import { getOrigin } from 'owa-url/lib/getOrigin';
import type { ILogger } from './ILogger';
import { getResourceToken } from './getResourceToken';
import { shouldFetchFromSTS } from './shouldFetchFromSTS';
import { fetchTokenFromServer } from './fetchTokenFromServer';

export async function fetchTokenFromHostAppForConsumerWithRetry(
    logger: ILogger,
    retryCount: number,
    retryDelay: number,
    consumerParams: ConsumerTokenRequestParams
): Promise<TokenResponse> {
    if (retryCount < 1) {
        const error = new Error('Max retrying limit (1) reached');
        logger.addCustomError('FetchFromHostAppConsumer', error);
        return Promise.reject(error);
    }

    return fetchTokenFromHostAppForConsumer(logger, consumerParams).catch(() => {
        // Retry only on non-network related errors
        return sleep(retryDelay).then(() => {
            return fetchTokenFromHostAppForConsumerWithRetry(
                logger,
                retryCount - 1,
                retryDelay * 2,
                {
                    ...consumerParams,
                }
            );
        });
    });
}

export async function fetchTokenFromHostAppForConsumer(
    logger: ILogger,
    consumerParams: ConsumerTokenRequestParams
): Promise<TokenResponse> {
    const requestTokenFromServer = shouldFetchFromSTS(consumerParams.resource ?? '');

    if (!requestTokenFromServer) {
        logger.addCheckpoint('TokenRetrievalFromHostApp_Start');
        const token = await getResourceToken(
            getOrigin(),
            consumerParams.wwwAuthenticateHeader,
            consumerParams.mailboxInfo,
            consumerParams.scope
        );

        if (token) {
            logger.addCheckpoint('TokenRetrievalFromHostApp_End');
            return token;
        }
    }

    logger.addCheckpoint('FetchingSTSToken');
    return fetchTokenFromServer(logger, consumerParams);
}
