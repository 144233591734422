import { LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailBootProjectionPopout" */ './lazyFunctions')
);

export const ProjectionPopoutHostProxy = createLazyComponent(
    lazyModule,
    m => m.ProjectionPopoutHost
);
