import { logStartGreyError } from 'owa-analytics-start';
import { SourceIdPartDelimiter } from 'owa-client-types/lib/SourceIdPartDelimiter';

// Exported constants for the types of resources that are built
export const resourceTypeArchive = 'archive';
export const resourceTypeGroup = 'group';
export const resourceTypePublicFolder = 'public';
export const resourceTypeShared = 'shared';
export const resourceTypeTeams = 'teams';

/**
 * Creates a sourceId from the three component parts
 * @param coprincipalSourceIdentifier The identifier for the coprincipal account, or the persistenceId when acctPersistenceIdBasedSourceIds is enabled
 * @returns a string represeting the source
 */
export function buildCoprincipalSourceId(coprincipalSourceIdentifier: string): string {
    if (!coprincipalSourceIdentifier) {
        const errorMessage = 'AcctSourceId-EmptyCoprincipalIdentifier';
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        const e = new Error(errorMessage);
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logStartGreyError(errorMessage, e, {
            co: typeof coprincipalSourceIdentifier,
        });
    }

    return coprincipalSourceIdentifier;
}

/**
 * Creates a sourceId from the three component parts
 * @param coprincipalSourceIdentifier The identifier for the coprincipal account, or the persistenceId when acctPersistenceIdBasedSourceIds is enabled
 * @param resourceType The type of resource
 * @param resourceSourceIdentifier The identifier for the resource account
 * @returns a string represeting the resource source
 */
export function buildResourceSourceId(
    coprincipalSourceIdentifier: string,
    resourceType: string,
    resourceSourceIdentifier: string
): string {
    if (!resourceSourceIdentifier) {
        const errorMessage = 'AcctSourceId-EmptyResourceIdentifier';
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        const e = new Error(errorMessage);
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logStartGreyError(errorMessage, e, {
            rt: resourceType, // resource type is non PII
            re: typeof resourceSourceIdentifier,
        });
    }

    return [
        buildCoprincipalSourceId(coprincipalSourceIdentifier),
        resourceType,
        resourceSourceIdentifier,
    ].join(SourceIdPartDelimiter);
}
