import { OcpsPolicy, isOcpsPolicyEnabled } from 'owa-ocps-policy-store';
import { isFeatureEnabled } from 'owa-feature-flags';

export const isOptionalExperiencesAllowedByPolicy = () =>
    isFeatureEnabled('settings-privacySettingsRoaming')
        ? isOcpsPolicyEnabled(
              OcpsPolicy.OptionalConnectedExperiences,
              true /* enabledIfUndefined */
          )
        : true;
