import { getOpxHostApi } from 'owa-opx';
import { markFunction } from 'owa-performance';
import { hasQueryStringParameter } from 'owa-querystring';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { loadInitialTheme, getIsSystemDarkModePreferred } from 'owa-theme';
import { initializeIsMsHighContrastState } from 'owa-high-contrast';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import {
    lazyHandleThemeChange,
    lazyRegisterOnLoadHandler,
    lazyGetCachedContext,
    lazyGetContext,
} from 'owa-metaos-app-bootstrap';
import { lazyGetTeamsPalette } from 'owa-teams-palette';
import type { OwaPalette } from 'owa-theme-shared';
import { ThemeConstants } from 'owa-theme-shared';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { generateTeamsThemeId } from 'owa-teams-theme-utils';
import migrateThemeId from '../utils/migrateThemeId';

let isInitializeThemeRunningFirstTime = true;
export default markFunction(async function initializeTheme() {
    const userConfig = getUserConfiguration();
    const userOptions = userConfig?.UserOptions;
    let isDarkTheme = !!userOptions?.IsDarkModeTheme;
    let themeStorageId = userOptions?.ThemeStorageId;
    let themesAllowed = !!userConfig?.SegmentationSettings?.Themes;
    let palette: OwaPalette | undefined = undefined;
    let useSystemDarkModeSettings: boolean = false;

    if (isHostAppFeatureEnabled('loadThemeFromHostApp')) {
        const opxHostApi = getOpxHostApi();
        isDarkTheme = (await opxHostApi.isDarkTheme()) || false;
        themeStorageId = 'base';
        themesAllowed = true;
        palette = hasQueryStringParameter('useOwaTheme')
            ? undefined
            : await opxHostApi.getOpxPalette(isDarkTheme);

        if (isInitializeThemeRunningFirstTime) {
            opxHostApi.registerThemeChangedHandler(initializeTheme);
        }
    } else if (isHostAppFeatureEnabled('platformAppSdk')) {
        // Get the fresh metaOS context when app is resumed from the cached state, otherwise use the cached context.
        const teamsTheme = await (isInitializeThemeRunningFirstTime
            ? lazyGetCachedContext
            : lazyGetContext
        )
            .importAndExecute()
            .then(context => {
                return context.app.theme;
            });
        // keep in sync with isDarkTheme in owa-metaos-app-bootstrap
        isDarkTheme = teamsTheme === 'dark';

        if (
            isHostAppFeatureEnabled('useTeamsAppearance') &&
            !isFeatureEnabled('platform-disableHostAppSpecificCustomizations')
        ) {
            palette = await lazyGetTeamsPalette.importAndExecute(teamsTheme);
            // Setting a new `themeStorageId` here so that the palette is not picked up from cache.
            themeStorageId = generateTeamsThemeId(teamsTheme);
            if (teamsTheme === 'contrast') {
                isDarkTheme = true;
            }
        }
        // In certain MetaOS hubs we want to disable the accent colors (of buttons/links/etc) that the user might have set,
        // to better match the hub's theme which doesn't support them (e.g. in Outlook win32), so we use the base theme.
        else if (isHostAppFeatureEnabled('useBaseTheme')) {
            themeStorageId = 'base';
        }
        if (isInitializeThemeRunningFirstTime) {
            lazyHandleThemeChange.importAndExecute(initializeTheme);
            if (isFeatureEnabled('cal-init-registerInitializeThemeWithOnLoadHandler')) {
                // The callback function onTabLoaded passed in the registerOnLoadHandler will be called when the meta OS app is loaded back from the cache.
                // Please refer https://learn.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/app-caching#enable-app-caching for more details.
                lazyRegisterOnLoadHandler.importAndExecute({
                    key: 'initializeTheme',
                    handler: initializeTheme,
                });
            }
        }
    } else {
        // The loadThemeFromHostApp and platformAppSdk host app features
        // are not intended to execute in Monarch, (see flagsDefaults.ts). Additionally, this code needs to be
        // in the else chain, otherwise we would be overriding the dark mode for opx and hub apps
        const primeSettings = getUserConfiguration()?.PrimeSettings;

        useSystemDarkModeSettings = !!primeSettings?.Items?.filter(
            item => item.Id === 'UseSystemDarkMode'
        ).map(item => item.Value?.options?.[0].useSystemDarkMode)[0];

        if (useSystemDarkModeSettings) {
            isDarkTheme = getIsSystemDarkModePreferred();
        }
    }

    if (
        isHostAppFeatureEnabled('showOfficeNeutralTheme') &&
        themeStorageId == ThemeConstants.BASE_OFFICE_THEME_ID
    ) {
        // Use the office neutral theme in the primary Mail scenario now. Leave other scenarios like OPX to use the default blue Office theme's for their own logic.
        const primeSettings = getAccountScopeUserSettings(
            getGlobalSettingsAccountMailboxInfo()
        )?.PrimeSettings;
        let selectofficeNeutralThemeBit = false;

        if (primeSettings?.Items) {
            for (const primeSetting of primeSettings.Items) {
                if (primeSetting.Id === 'OfficeNeutralThemeOptions') {
                    const officeNeutralThemeBitOption: any = primeSetting.Value?.options?.[0];
                    selectofficeNeutralThemeBit =
                        officeNeutralThemeBitOption?.isSelected as boolean;
                    break;
                }
            }
        }

        if (selectofficeNeutralThemeBit) {
            themeStorageId = ThemeConstants.OFFICE_NEUTRAL_THEME_ID;
        }
    }

    if (
        // We want to map the previous modern mountain theme to the new modern arctic solitude theme
        // if the feature flag is enabled or the previous office neutral theme to the new
        // modern office neutral theme right away
        ((isFeatureEnabled('fwk-new-modern-themes-v1') ||
            isFeatureEnabled('fwk-bleed-through-themes')) &&
            themeStorageId === ThemeConstants.MODERN_MOUNTAIN_THEME_ID) ||
        themeStorageId === ThemeConstants.OFFICE_NEUTRAL_THEME_ID
    ) {
        themeStorageId =
            themeStorageId === ThemeConstants.MODERN_MOUNTAIN_THEME_ID
                ? ThemeConstants.MODERN_ARCTIC_SOLITUDE_THEME_ID
                : ThemeConstants.MODERN_OFFICE_NEUTRAL_THEME_ID;

        await migrateThemeId(themeStorageId);
    }

    await loadInitialTheme(
        themeStorageId,
        isDarkTheme,
        themesAllowed,
        useSystemDarkModeSettings,
        palette
    );

    if (isInitializeThemeRunningFirstTime) {
        initializeIsMsHighContrastState();
        isInitializeThemeRunningFirstTime = false;
    }
}, 'ti');
