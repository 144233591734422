import type OwsOptionsBase from '../OwsOptionsBase';

// The shape is defined on the server, and should be kept in sync
// In the server-ows-api project: src/Microsoft.OWS.UserSettings/Models/OutlookOptions/OptionsTypes/PinnedPaneOptions.cs
interface PinnedPaneOptions extends OwsOptionsBase {
    pinnedPane: PinnablePane;
}

/** Supported options for pane-pinning */
export enum PinnablePane {
    /** No pane */
    None = 0,

    /** My Day pane */
    MyDay = 1,
}

export default PinnedPaneOptions;
