import type {
    ActionSource,
    AnalyticsSchema,
    AriaDatapointType,
    CustomData,
    CustomDataMap,
    CustomDataType,
    DatapointOptions,
    InternalDatapointOptions,
} from 'owa-analytics-types';
import { getApp } from 'owa-config/lib/bootstrapOptions';
import { scrubForPii } from 'owa-config/lib/scrubForPii';
import { getUniquePropertyString } from '../utils/getUniquePropertyString';
import { getQueryStringParameter } from 'owa-querystring';
import { getSessionElapseTime } from 'owa-analytics-utils';
import { getThreadName } from 'owa-thread-config';
import { getPuidFromMailboxInfo } from '../settings/getPuidFromMailboxInfo';
import { isFeatureEnabled } from 'owa-feature-flags';

const eventTypeQueryStringParam = 'track';
export function isEventQueryStringEnabled(eventType: string): boolean {
    const eventQueryString = getQueryStringParameter(eventTypeQueryStringParam);
    return (
        !!eventQueryString && (eventQueryString == '*' || eventQueryString.indexOf(eventType) > -1)
    );
}

export function isClientVerboseQueryStringEnabled(): boolean {
    return isEventQueryStringEnabled(VerbosePerfEventType);
}

export const VerbosePerfEventType = 'client_verbose';

let sequenceNumber = 0;

export class AriaCoreDatapoint implements AriaDatapointType {
    public static sessionOccurrences: {
        [index: string]: number;
    } = {};

    protected eventName: string;
    protected options: InternalDatapointOptions;
    private customDataIndex: number = 1;
    private propertyBag: CustomDataMap | undefined;
    private properties: CustomDataMap;
    private creationTime: string;

    sessionOccurrence: number | undefined;
    hasEnded = false;

    constructor(
        eventName?: string,
        extraCustomData?: CustomData,
        options?: DatapointOptions,
        props?: CustomDataMap
    ) {
        this.eventName = (eventName && eventName.toString().replace('.', '_')) || '';
        this.creationTime = new Date().toISOString();
        this.properties = props || {};
        if (options?.mailbox) {
            if (
                isFeatureEnabled(
                    'fwk-acting-puid',
                    undefined /*mailboxInfo*/,
                    true /*dontThrowErrorIfNotInitialized*/
                )
            ) {
                const puid = getPuidFromMailboxInfo(options.mailbox);
                if (puid) {
                    this.addData('ActingPuid', puid);
                }
            }

            delete options.mailbox;
        }

        this.options = options ?? {};
        this.addCustomData(extraCustomData);

        if (options?.actionSource) {
            this.addActionSource(options.actionSource);
        }

        if (options?.cosmosOnlyData) {
            this.addCosmosOnlyData(options.cosmosOnlyData);
        }

        this.addData('App', options?.appOverride ?? getApp());

        if (this.eventName) {
            this.addData('EventName', this.eventName);
        }

        this.addData('SequenceNumber', sequenceNumber++);

        const errorComponent = options?.errorComponent;
        if (errorComponent) {
            this.addData('ErrorComponent', errorComponent);
        }

        this.updateSessionOccurences(this.eventName);

        const sessionElapseTime = getSessionElapseTime();
        if (sessionElapseTime) {
            this.addData('SessionElapseTime', sessionElapseTime);
        }

        this.addData('OriginalThread', getThreadName());
    }

    public getEventName() {
        return this.eventName;
    }

    public getOptions() {
        return this.options;
    }

    public addCustomData(extraCustomData?: CustomData) {
        if (extraCustomData && !this.hasEnded) {
            if (Array.isArray(extraCustomData)) {
                for (var ii = 0; ii < extraCustomData.length; ii++) {
                    this.addCustomProperty(
                        `owa_${(this.customDataIndex++).toString()}`,
                        extraCustomData[ii]
                    );
                }
            } else if (extraCustomData instanceof Object) {
                const props = Object.keys(extraCustomData);
                for (var jj = 0; jj < props.length; jj++) {
                    this.addCustomProperty(props[jj], extraCustomData[props[jj]]);
                }
            }
        }
    }

    public hasCustomData(key: string | number): boolean {
        return this.propertyBag?.[key] != undefined;
    }

    public getCustomData(key: string | number): CustomDataType {
        return this.propertyBag?.[key];
    }

    public getData(key: string) {
        return this.properties[key];
    }

    public getProperties() {
        return this.properties;
    }

    protected addData(key: AnalyticsSchema, value: CustomDataType) {
        if (!this.hasEnded) {
            this.addDataWithoutChecks(key, value);
        }
    }

    protected addDataWithoutChecks(key: AnalyticsSchema, value: CustomDataType) {
        this.properties[key] = value;
    }

    public addCosmosOnlyData(value: string) {
        this.addDataWithPiiScrubbing('ExtraData', value);
    }

    public addActionSource(value: ActionSource) {
        this.addData('ActionSource', value);
    }

    public getActionSource() {
        return this.getData('ActionSource');
    }

    public addCustomProperty(property: string, value: CustomDataType) {
        if (!this.propertyBag) {
            this.propertyBag = {};
        }
        const uniqueProperty = getUniquePropertyString(this.propertyBag, property);
        if (uniqueProperty) {
            this.propertyBag[uniqueProperty] = value;
        }
    }

    public getPropertyBag() {
        return this.propertyBag;
    }

    public getCreationTime() {
        return this.creationTime;
    }

    public static fromJSObject<ReturnType>(datapointObj: ReturnType): ReturnType {
        return Object.assign(new AriaCoreDatapoint(), datapointObj) as ReturnType;
    }

    protected addDataWithPiiScrubbing(key: AnalyticsSchema, value: string | null) {
        this.addData(key, scrubForPii(value));
    }

    private updateSessionOccurences(eventName: string): void {
        const sessionOccurences = AriaCoreDatapoint.sessionOccurrences[eventName];
        this.sessionOccurrence = AriaCoreDatapoint.sessionOccurrences[eventName] = sessionOccurences
            ? sessionOccurences + 1
            : 1;

        this.addData('SessionOccurences', this.sessionOccurrence);
    }
}
