import React from 'react';
import classnames from 'owa-classnames';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { observer } from 'owa-mobx-react';
import { getCurrentThemeId, getBackgroundImageData, getFallbackBackgroundColor } from 'owa-theme';
import { fetchWithTimeoutAndRetries } from '../utils/fetchWithTimeoutAndRetries';
import { backgroundImage } from './AppBackground.scss';

const AppBackground = observer(function AppBackground() {
    const [bgImage, setBgImage] = React.useState<string>('');
    const [isBackgroundImageLoaded, setBackgroundImageLoaded] = React.useState(false);
    const [shouldShowBackgroundImage, setShouldShowBackgroundImage] = React.useState(false);

    const isDarkTheme = getIsDarkTheme();
    const currentThemeId = getCurrentThemeId();
    const fallbackBackgroundColor = getFallbackBackgroundColor(currentThemeId, isDarkTheme);

    const loadBackgroundImageForTheme = React.useCallback(async () => {
        const bgImageURL = getBackgroundImageData(currentThemeId, isDarkTheme);
        setBackgroundImageLoaded(false);

        if (!bgImageURL) {
            setShouldShowBackgroundImage(false);
            return;
        }

        setShouldShowBackgroundImage(true);
        const bgImageResponse = await fetchWithTimeoutAndRetries(bgImageURL);

        if (bgImageResponse) {
            setBgImage(bgImageResponse);
            setBackgroundImageLoaded(true);
        } else {
            setShouldShowBackgroundImage(false);
        }
    }, [isDarkTheme, currentThemeId]);

    React.useEffect(() => {
        loadBackgroundImageForTheme();
    }, [isDarkTheme, currentThemeId]);

    const backgroundImageStyles = React.useMemo(() => {
        return isBackgroundImageLoaded
            ? {
                  backgroundImage: `url(${bgImage})`,
                  backgroundColor: fallbackBackgroundColor,
              }
            : {
                  backgroundColor: fallbackBackgroundColor,
              };
    }, [bgImage, fallbackBackgroundColor, isBackgroundImageLoaded]);

    return shouldShowBackgroundImage ? (
        <div className={classnames(backgroundImage)} style={backgroundImageStyles} />
    ) : null;
}, 'AppBackground');

export default AppBackground;
