import type AccessIssue from 'owa-attachment-policy-access-issue-checker/lib/schema/AccessIssue';
import getCombinedAccessIssue from 'owa-attachment-policy-access-issue-checker/lib/utils/getCombinedAccessIssue';
import type { MailboxInfo } from 'owa-client-types';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { getUserConfiguration, isConsumer } from 'owa-session-store';
import { isVanityDomain } from './isVanityDomain';

export function isSupportedAppServiceEnvironment(mailboxInfo?: MailboxInfo): boolean {
    const userConfiguration = getUserConfiguration();
    const isExplicitLogon = userConfiguration?.SessionSettings?.IsExplicitLogon;

    return (
        getCombinedAccessIssue(mailboxInfo ?? getModuleContextMailboxInfo()) === 2 &&
        !isExplicitLogon &&
        !isConsumer(undefined, mailboxInfo ?? getModuleContextMailboxInfo()) &&
        !isVanityDomain()
    );
}
