import {
    getColorfulIconsEnabledOriginalValue,
    getLabelsEnabledOriginalValue,
    isAppBarDisplayModeOptionsEnabled,
    isFeatureEnabled,
} from 'owa-feature-flags';
import type { StrictLaunchTileBehavior } from '../types';
import { isAppBarPinnedAppsEnabled } from 'owa-m365-acquisitions/lib/pinnedApps/isAppBarPinnedAppsEnabled';
import { OwsOptionsFeatureType, getOptionsForFeature } from 'owa-outlook-service-options';
import type { AppBarDisplayModeOptions } from 'owa-outlook-service-options';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import getGlobalSettingsAccountMailboxInfo from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';
import { isCapabilitySupported } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import type { LaunchTileBehaviorParams } from '../launchTileBehaviorParams';

export const getLaunchTilesDefaultBehavior: (
    params?: LaunchTileBehaviorParams
) => StrictLaunchTileBehavior = params => ({
    __typename: 'LaunchTileBehavior',
    transparent:
        isFeatureEnabled('mon-transparency-usingSystemBackdrop') ||
        isCapabilitySupported(bleedThroughCapability),
    pinningEnabled: isAppBarPinnedAppsEnabled(),
    shouldShowLabel: params?.shouldShowLabel ?? isAppBarDisplayModeShowLabelsEnabled(),
    platformAppCommands: {
        __typename: 'PlatformAppCommands',
        aboutCommandEnabled: true,
        uninstallCommandEnabled: true,
    },
    useHubColorForSelectedTileLabel: true,
    dragDropEnabled: isAppBarPinnedAppsEnabled(),
    useOutlineIcon: params ? !params?.useColorfulIcons : !isAppBarDisplayModeColorfulIconsEnabled(),

    // The localized version of these strings is provided by the TileContextMenu type policy
    tileContextMenu: {
        __typename: 'TileContextMenu',
        openInNewTabItemLabel: 'Open in new tab',
        aboutAppItemLabel: 'About',
        pinAppItemLabel: 'Pin',
        unpinAppItemLabel: 'Unpin',
        uninstallAppItemLabel: 'Uninstall',
    },
});

function isAppBarDisplayModeColorfulIconsEnabled(): boolean {
    if (!isAppBarDisplayModeOptionsEnabled()) {
        return true;
    }

    let colorfulIconsEnabled = undefined;

    const userOptions = getOptionsForFeature<AppBarDisplayModeOptions>(
        OwsOptionsFeatureType.AppBarDisplayMode
    );

    if (userOptions) {
        colorfulIconsEnabled = userOptions.colorfulIconsEnabled;
    } else {
        const primeSettingsItems = getAccountScopeUserSettings(
            getGlobalSettingsAccountMailboxInfo()
        )?.PrimeSettings?.Items;
        if (primeSettingsItems) {
            for (const primeSetting of primeSettingsItems) {
                if (primeSetting.Id === 'AppBarDisplayModeOptions') {
                    colorfulIconsEnabled = primeSetting.Value?.options?.[0]?.colorfulIconsEnabled;
                    break;
                }
            }
        }
    }

    return colorfulIconsEnabled ?? getColorfulIconsEnabledOriginalValue();
}

function isAppBarDisplayModeShowLabelsEnabled(): boolean {
    if (!isAppBarDisplayModeOptionsEnabled()) {
        return false;
    }

    const userOptions = getOptionsForFeature<AppBarDisplayModeOptions>(
        OwsOptionsFeatureType.AppBarDisplayMode
    );

    let showLabelsEnabled = undefined;

    if (userOptions) {
        showLabelsEnabled = userOptions.labelsEnabled;
    } else {
        const primeSettingsItems = getAccountScopeUserSettings(
            getGlobalSettingsAccountMailboxInfo()
        )?.PrimeSettings?.Items;
        if (primeSettingsItems) {
            for (const primeSetting of primeSettingsItems) {
                if (primeSetting.Id === 'AppBarDisplayModeOptions') {
                    showLabelsEnabled = primeSetting.Value?.options?.[0]?.labelsEnabled;
                    break;
                }
            }
        }
    }

    return showLabelsEnabled ?? getLabelsEnabledOriginalValue();
}
