import type { OwaPalette } from 'owa-theme-shared';
import { fetchThemeFile, ThemeConstants } from 'owa-theme-shared';
import { getThemeDataName } from '../utils/getThemeDataName';
import loadTheme from '../actions/loadTheme';
import { normalizeThemeRequest } from '../utils/normalizeThemeId';
import { setItem } from 'owa-local-storage';
import { store } from '../store/store';
import { addThemeData } from '../mutators/addThemeData';
import { mergeThemePalette } from '../mutators/mergeThemePalette';
import setUseSystemDarkModeSettings from '../mutators/setUseSystemDarkModeSettings';
import { registerOnSystemDarkModeChangeListener } from '../utils/registerOnSystemDarkModeChangeListener';
import { getMappedTheme } from '../utils/getMappedTheme';

export async function loadInitialTheme(
    themeStorageId: string | undefined,
    darkMode: boolean,
    themesAllowed: boolean,
    useSystemDarkModeSettings: boolean,
    palette?: OwaPalette
) {
    const { themeId, isDarkTheme } = normalizeThemeRequest(themeStorageId, darkMode, themesAllowed);
    // We want to load the theme data for the mapped theme
    const mappedThemeId = getMappedTheme(themeId).mappedToThemeId;
    const themeDataName = getThemeDataName(mappedThemeId, isDarkTheme);

    setItem(self, ThemeConstants.LOCAL_STORAGE_KEY, themeDataName);

    const themeResources =
        (await fetchThemeFile(themeDataName, 'sd')) ||
        // fall back to using the preloaded dark or base themes, if available.
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        store.themeData.get(
            isDarkTheme ? ThemeConstants.BASE_DARK_THEME_ID : ThemeConstants.BASE_THEME_ID
        )!;

    if (palette) {
        const themeResourcesFromStore = store.themeData.get(themeDataName);
        mergeThemePalette(
            themeResourcesFromStore ? themeResourcesFromStore : themeResources,
            palette
        );
    }
    addThemeData(themeDataName, themeResources);
    loadTheme(themeId, isDarkTheme, themesAllowed);
    setUseSystemDarkModeSettings(useSystemDarkModeSettings);
    registerOnSystemDarkModeChangeListener();
}
