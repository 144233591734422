import type MsalTokenCallback from './schema/MsalTokenCallback';
import { lazyCreateMsalPublicClientApplicationProxy } from 'owa-msaljs/lib/lazyAppBoot';
import { getAuthenticationUrl } from 'owa-tokenprovider-utils';
import acquireTokenMsal from './utils/acquireTokenMsal';
import loginUserAndAcquireTokenMsal from './utils/loginUserAndAcquireTokenMsal';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';

export async function getAccessTokenOnBehalfOfResourceMsal(
    clientId: string,
    redirectUri: string,
    resource: string,
    authChallenge: string,
    allowConsentPrompt: boolean,
    getAccessTokenCallback: MsalTokenCallback,
    mailboxInfo: MailboxInfo
): Promise<void> {
    let authUrl = await getAuthenticationUrl(mailboxInfo);
    // We support only Azure AD accounts for now in getAccessTokenAsync.
    // Hence, restricting authURL by adding 'organizations'.
    authUrl += 'organizations';

    const msalInstance = await lazyCreateMsalPublicClientApplicationProxy.importAndExecute(
        authUrl,
        clientId,
        redirectUri
    );

    const sessionSettings = getAccountScopeUserSettings(mailboxInfo).SessionSettings;
    let userPrincipalName = sessionSettings?.UserPrincipalName;

    if (getAccountScopeUserSettings(mailboxInfo).SessionSettings?.IsExplicitLogon) {
        userPrincipalName = sessionSettings?.LogonEmailAddress;
    }

    const userAccount = userPrincipalName
        ? msalInstance.getAccountByUsername(userPrincipalName)
        : null;

    if (userAccount) {
        acquireTokenMsal(
            msalInstance,
            resource,
            authChallenge,
            allowConsentPrompt,
            userAccount,
            userPrincipalName?.toString(),
            getAccessTokenCallback,
            mailboxInfo
        );
    } else {
        loginUserAndAcquireTokenMsal(
            msalInstance,
            resource,
            authChallenge,
            allowConsentPrompt,
            userPrincipalName?.toString(),
            getAccessTokenCallback,
            mailboxInfo
        );
    }
}
