import { getMappedTheme } from './getMappedTheme';

// TODO: This function is going to be removed after we graduate the flight: `fwk-new-modern-themes-v1`
// Removing this in favor of the new `getBackgroundImageData` function
// Tracking it here: https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/256287
const getThemeBackgroundImageURL = (isDarkTheme: boolean, themeId: string): string | undefined => {
    const mappedTheme = getMappedTheme(themeId);

    if (!mappedTheme.backgroundImages) {
        return;
    }

    return isDarkTheme ? mappedTheme.backgroundImages?.dark : mappedTheme.backgroundImages?.light;
};

export default getThemeBackgroundImageURL;
