export const CLICK_ACTION_SOURCE = 'Click'; // Action source for when user clicks on suggestion
export const DEFAULT_COLLAPSED_WIDTH = 356;
export const DEFAULT_EXPANDED_WIDTH = 928;
export const END_MATCH_DELIMITER = String.fromCharCode(57345); // This char marks end of highlighting
export const END_MATCH_DELIMITER_REGEXP = new RegExp(END_MATCH_DELIMITER, 'g');
export const FILTERS_BUTTON_ID = 'filtersButtonId';
export const NONBREAKING_SPACE_CHAR = String.fromCharCode(160);
export const PILL_LIMIT = 2;
export const SPACE_REGEX = /\s/g;
export const START_MATCH_DELIMITER = String.fromCharCode(57344); // This char marks start of highlighting
export const START_MATCH_DELIMITER_REGEXP = new RegExp(START_MATCH_DELIMITER, 'g');
export const SUGGESTIONS_CALLOUT_ID = 'searchSuggestionsCallout';
export const SUGGESTION_ID_PREFIX = 'searchSuggestion-';
export const SUGGESTION_QUICKACTION_ID_PREFIX = 'quickAction';
export const SMALL_SUGGESTION_HEIGHT = 38;
export const LARGE_SUGGESTION_HEIGHT = 52;
export const SEARCHBOX_ID = 'searchBoxId';
export const SEARCHBOX_ANCHOR_ID = 'searchBoxAnchorId';
export const SEARCH_SCOPE_SWITCHER_ID = 'searchScopeButtonId';
export const SEARCH_SCOPE_SWITCHER_ID_OPTION = 'searchScopeButtonId-option';
export const SEARCH_SCOPE_SWITCHER_ID_LIST = 'searchScopeButtonId-list';
export const SEARCH_SCOPE_SWITCHER_WRAPPER_ID = 'searchScopeWrapperId';
export const SEARCHBOX_COLUMN_CONTAINER_ID = 'searchBoxColumnContainerId';
export const SEARCHBOX_INPUT_ELEMENT_ID = 'topSearchInput';
