import { getAccessTokenforResourceAsLazy } from 'owa-tokenprovider';
import type { RequestQueue } from 'owa-service-utils';
import { createRequestQueue } from 'owa-service-utils';
import { buildAuthorizationHeader } from 'owa-tokenprovider-utils';

const MAXIMUM_NUM_REQUESTS = 1;
// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
// -> Error TS2322 (11,5): Type 'null' is not assignable to type 'RequestQueue'.
// @ts-expect-error
let requestQueue: RequestQueue = null;

const CONNECTED_NOTES_ENDPOINT = '/NotesFabric/api/v2.0/me/connectednotes';

export default async function getConnectedNotesService() {
    /* eslint-disable-next-line owa-custom-rules/require-mailboxInfoParameter  -- (https://aka.ms/OWALintWiki)
     * MailboxInfo must be provided to the function, see https://aka.ms/multiaccountlinter
     *	> MailboxInfo must be passed into the function to identify the account. */
    let [token, tokenPromise] = getAccessTokenforResourceAsLazy(
        window.location.origin,
        'OwaNotesFeedBootstrap'
    );
    // If token is not returned synchronously, we need to await on the tokenPromise
    if (!token) {
        token = await tokenPromise;
    }

    if (!requestQueue) {
        requestQueue = createRequestQueue(MAXIMUM_NUM_REQUESTS);
    }

    return requestQueue.add(() =>
        fetch(CONNECTED_NOTES_ENDPOINT, {
            method: 'GET',
            headers: {
                Authorization: buildAuthorizationHeader(token as string),
                'Content-Type': 'application/json',
            },
        })
    );
}
