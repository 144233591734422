import { getCdnUrl } from 'owa-config';
import getResourcesForTheme from './getResourcesForTheme';
import { getThemeIdFromParameter } from '../utils/optionalArgumentHelpers';

export default function getBackgroundImageData(
    themeId?: string,
    isDarkTheme?: boolean
): string | undefined {
    themeId = getThemeIdFromParameter(themeId);

    const backgroundImageUrl = getResourcesForTheme(themeId, isDarkTheme).background?.image;

    return resolveImageUrl(backgroundImageUrl);

    function resolveImageUrl(url?: string) {
        return url && `${getCdnUrl()}${url}`;
    }
}
