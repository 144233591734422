import type {
    AccountSource,
    AdditionalAccountInfo,
    Contracts,
    CoprincipalAccountSource,
    M365AccountSource,
    M365ArchiveMailboxAccountSource,
    M365ConnectedMailboxAccountSource,
    M365GroupMailboxAccountSource,
    M365Mailbox,
    M365PublicFolderMailboxAccountSource,
    M365SharedMailboxAccountSource,
    M365TeamsMailboxAccountSource,
    M365UserMailboxAccountSource,
} from 'owa-account-source-list-store';
import {
    AccountDataType,
    BootState,
    ExternalDataType,
    LoadState,
    M365AuthProvider,
    M365MailboxType,
    m365MailboxTypeToMailboxType,
} from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';
import { CoprincipalMailboxRank, type MailboxType, ResourceMailboxRank } from 'owa-client-types';
import { type HostSourceInformation, SourceType, SourceAuthType } from '../types';
import {
    buildResourceSourceId,
    resourceTypeArchive,
    resourceTypeGroup,
    resourceTypePublicFolder,
    resourceTypeShared,
    resourceTypeTeams,
} from './sourceIdBuilder';

export function buildOnlineArchiveMailboxSmtp(mailboxGuid: string, orgDomain: string): string {
    return `${mailboxGuid}@${orgDomain}`;
}

export function buildMailboxContracts(buildParams: Partial<Contracts>): Contracts {
    return {
        // default values
        supportsCalendar: false,
        supportsContacts: false,
        supportsMail: false,
        supportsSettings: false,
        isMicrosoft365Hosted: true,

        ...buildParams,
    };
}

export function buildMailboxContractsFromHostSourceInformation(
    hostAccount: HostSourceInformation
): Contracts {
    return buildMailboxContracts({
        ...hostAccount.contracts,
    });
}

export function buildAdditionalAccountInfo(
    coprincipalSourceId: string,
    loadState: LoadState = LoadState.NotStarted,
    sources: AccountSource[] = []
): AdditionalAccountInfo {
    return {
        coprincipalSourceId,
        loadState,
        sources,
    };
}

export function buildM365Mailbox(
    m365mailboxType: M365MailboxType,
    mailboxDisplayName: string,
    userIdentity: string,
    email: string,
    authProviderType: M365AuthProvider = M365AuthProvider.AAD,
    auxiliaryMailboxGuid?: string
): M365Mailbox {
    return {
        type: m365MailboxTypeToMailboxType(m365mailboxType),
        name: mailboxDisplayName,
        displayName: mailboxDisplayName,
        emailAddress: email,
        logonEmailAddress: email,
        userIdentity,
        authProviderType,
        auxiliaryMailboxGuid,
    };
}

export function buildM365MailboxFromHostSourceInformation(
    m365mailboxType: M365MailboxType,
    hostAccount: HostSourceInformation
): M365Mailbox {
    return hostAccount.mailbox
        ? {
              ...hostAccount.mailbox,
              type: m365MailboxTypeToMailboxType(m365mailboxType),
              logonEmailAddress: hostAccount.mailbox.loginAddress,
              authProviderType:
                  hostAccount.authProviderType === SourceAuthType.Microsoft365
                      ? M365AuthProvider.AAD
                      : M365AuthProvider.MSA,
          }
        : buildM365Mailbox(m365mailboxType, '', '', '');
}

type RequiredOnPartial<T, RequiredKeys extends keyof T> = Required<Pick<T, RequiredKeys>> &
    Partial<Omit<T, RequiredKeys>>;

function buildM365AccountSource(
    buildParams: RequiredOnPartial<
        M365AccountSource,
        'sourceId' | 'sourceType' | 'displayName' | 'contracts' | 'm365mailbox' // required params
    >
): M365AccountSource {
    return {
        // optional buildParams props with default values
        dataType: AccountDataType.M365Mailbox,
        externalData: { type: ExternalDataType.None },
        mailboxRank: ResourceMailboxRank,
        mailboxInfo: {
            type: buildParams.m365mailbox.type,
            auxiliaryMailboxGuid: buildParams.m365mailbox.auxiliaryMailboxGuid,
            userIdentity: buildParams.m365mailbox.userIdentity,
            mailboxSmtpAddress: buildParams.m365mailbox.emailAddress,
            sourceId: buildParams.sourceId,
            diagnosticData: 'ASLSM365Account',
        },
        // optional buildParams props with default values -- end here

        ...buildParams,
    };
}

function buildM365AccountSourceWithoutSourceId(
    m365mailboxType: M365MailboxType,
    displayName: string,
    smtpAddress: string,
    resourceType: string,
    hostingUserIdentity: string,
    partialContracts: Partial<Contracts>,
    coprincipalPersistenceId: string,
    auxiliaryMailboxGuid?: string
): M365AccountSource {
    const sourceId = buildResourceSourceId(coprincipalPersistenceId, resourceType, smtpAddress);
    const contracts = buildMailboxContracts({
        ...partialContracts,
    });

    const m365mailbox = buildM365Mailbox(
        m365mailboxType,
        smtpAddress,
        hostingUserIdentity,
        smtpAddress,
        M365AuthProvider.AAD,
        auxiliaryMailboxGuid
    );

    return buildM365AccountSource({
        sourceId,
        displayName,
        contracts,
        m365mailbox,
        sourceType: AccountSourceType.Office365,
    });
}

export function buildM365UserAccountSource(
    sourceId: string,
    displayName: string,
    email: string,
    userIdentity: string,
    persistenceId: string,
    sourceType?: AccountSourceType,
    aliases?: string[]
): M365UserMailboxAccountSource {
    const contracts = buildMailboxContracts({
        supportsMail: true,
        supportsCalendar: true,
        supportsContacts: true,
        supportsSettings: true,
    });

    const m365mailboxType = M365MailboxType.User;
    const m365mailbox = buildM365Mailbox(m365mailboxType, userIdentity, userIdentity, email);

    const m365AccountSource = buildM365AccountSource({
        sourceId,
        displayName,
        contracts,
        m365mailbox,
        sourceType: sourceType ?? AccountSourceType.Office365,
    });

    return {
        ...m365AccountSource,
        bootState: BootState.Booting,
        mailboxRank: CoprincipalMailboxRank,
        m365mailboxType,
        groupSources: buildAdditionalAccountInfo(sourceId),
        sharedSources: buildAdditionalAccountInfo(sourceId),
        archiveSources: buildAdditionalAccountInfo(sourceId),
        publicFolderSources: buildAdditionalAccountInfo(sourceId),
        teamsSources: buildAdditionalAccountInfo(sourceId),
        persistenceId,
        isSharedWithMe: false,
        aliases: aliases ?? [],
        licensingMailboxInfo: {
            ...m365AccountSource.mailboxInfo,
            sourceType: m365AccountSource.sourceType,
            mailboxRank: 'Licensing',
            diagnosticData: 'ASLSLicensingMailbox',
        },
    };
}

/**
 * Builds an account source that can be used as a place holder for a pending account, where a pending
 * account is one that we have information for but has not yet been added to the account source list
 * @param emailAddress SMTP address of the pending account
 * @param sourceType Type of the pending account
 */
export function buildPendingAccountSource(
    emailAddress: string,
    userIdentity: string,
    sourceType: AccountSourceType
): M365UserMailboxAccountSource {
    return buildM365UserAccountSource(
        '', // Empty source id
        emailAddress,
        emailAddress,
        userIdentity,
        '', // empty persistence id
        sourceType
    );
}

function getPersistenceIdFromHostAccount(hostAccount: HostSourceInformation): string {
    return hostAccount.persistenceId;
}

export function buildCoprincipalAccountSourceFromHostSourceInformation(
    sourceId: string,
    hostAccount: HostSourceInformation
): CoprincipalAccountSource {
    if (hostAccount.sourceType === SourceType.Mailbox) {
        return buildM365CoprincipalAccountSourceFromHostSourceInformation(sourceId, hostAccount);
    } else if (hostAccount.sourceType === SourceType.PstFile) {
        return buildPstFileAccountSourceFromHostSourceInformation(sourceId, hostAccount);
    } else {
        throw new Error('Unsupported sourceType on hostAccount');
    }
}

function buildPstFileAccountSourceFromHostSourceInformation(
    sourceId: string,
    hostAccount: HostSourceInformation
): CoprincipalAccountSource {
    const persistenceId = getPersistenceIdFromHostAccount(hostAccount);
    const mailboxInfo = {
        type: 'PstFile' as MailboxType,
        userIdentity: hostAccount.userIdentity,
        mailboxSmtpAddress: hostAccount.displayName,
        sourceId,
        mailboxRank: CoprincipalMailboxRank,
        auxiliaryMailboxGuid: undefined,
        diagnosticData: 'ASLSMPstFile',
    };

    return {
        // AccountSource
        sourceId,
        sourceType: AccountSourceType.PstFile,
        dataType: AccountDataType.PstFile,
        mailboxRank: CoprincipalMailboxRank,
        displayName: hostAccount.displayName,
        contracts: {
            isMicrosoft365Hosted: false,
            ...hostAccount.contracts,
        },
        externalData: hostAccount.externalData,
        mailboxInfo,
        // CoprincipalAccountSource
        bootState: BootState.Booting,
        persistenceId,
        isSharedWithMe: false,
        aliases: [],
        licensingMailboxInfo: {
            ...mailboxInfo,
            sourceType: AccountSourceType.PstFile,
            mailboxRank: 'Licensing',
            diagnosticData: 'ASLSMPstFileLicensing',
        },
    };
}

// Converts from the auth provider type to the account source type
function authProviderTypeToAccountSourceType(authProviderType: SourceAuthType): AccountSourceType {
    switch (authProviderType) {
        case SourceAuthType.Microsoft365:
            return AccountSourceType.Office365;
        case SourceAuthType.OutlookDotCom:
            return AccountSourceType.OutlookDotCom;
        case SourceAuthType.Google:
            return AccountSourceType.Google;
        case SourceAuthType.Yahoo:
            return AccountSourceType.Yahoo;
        case SourceAuthType.ICloud:
            return AccountSourceType.ICloud;
        case SourceAuthType.IMAP:
            return AccountSourceType.IMAP;
        case SourceAuthType.POP3:
            return AccountSourceType.POP3;
        default:
            return AccountSourceType.OutlookDotCom; // we were already defaulting to OutlookDotCom so let's keep that
    }
}

export function buildM365CoprincipalAccountSourceFromHostSourceInformation(
    sourceId: string,
    hostAccount: HostSourceInformation
): M365UserMailboxAccountSource {
    const contracts = buildMailboxContractsFromHostSourceInformation(hostAccount);

    const m365mailboxType = M365MailboxType.User;
    const m365mailbox = buildM365MailboxFromHostSourceInformation(m365mailboxType, hostAccount);

    const sourceType: AccountSourceType = authProviderTypeToAccountSourceType(
        hostAccount.authProviderType
    );
    const externalData = hostAccount.externalData;

    const m365AccountSource = buildM365AccountSource({
        sourceId,
        displayName: hostAccount.displayName,
        contracts,
        m365mailbox,
        sourceType,
        externalData,
    });

    const persistenceId = hostAccount.persistenceId;
    const aliases = hostAccount.aliases ?? [];

    // If the user principal name (or UPN) used to authenticate the user is different
    // from the email addresses of mailbox then this mailbox is shared with the user
    const isSharedWithMe =
        sourceType === AccountSourceType.Office365 &&
        !(
            m365mailbox.emailAddress === m365mailbox.userIdentity ||
            aliases.includes(m365mailbox.userIdentity)
        );

    return {
        ...m365AccountSource,
        bootState: BootState.Booting,
        mailboxRank: CoprincipalMailboxRank,
        persistenceId,
        m365mailboxType,
        anchorMailbox: hostAccount.anchorMailbox,
        aliases,
        isSharedWithMe,
        groupSources: buildAdditionalAccountInfo(sourceId),
        sharedSources: buildAdditionalAccountInfo(sourceId),
        archiveSources: buildAdditionalAccountInfo(sourceId),
        publicFolderSources: buildAdditionalAccountInfo(sourceId),
        teamsSources: buildAdditionalAccountInfo(sourceId),
        licensingMailboxInfo: {
            ...m365AccountSource.mailboxInfo,
            sourceType: m365AccountSource.sourceType,
            mailboxRank: 'Licensing',
            diagnosticData: 'ASLSM365Licensing',
        },
    };
}

/**
 * create a new M365GroupMailboxAccountSource
 * @param memberOrOwnerUserIdentity userIdentity of the member of the owner of this group
 * @param group UnifiedGroup that has to be attached to the account source
 * @returns
 */
export function buildM365GroupMailboxAccountSource(
    sourceType: AccountSourceType,
    memberOrOwnerUserIdentity: string,
    displayName: string,
    smtpAddress: string,
    coprincipalPersistenceId: string,
    mailboxGuid?: string
): M365GroupMailboxAccountSource {
    const sourceId = buildResourceSourceId(
        coprincipalPersistenceId,
        resourceTypeGroup,
        smtpAddress
    );

    const contracts = buildMailboxContracts({
        supportsMail: true,
        supportsCalendar: true,
    });

    const m365mailboxType = M365MailboxType.Group;
    const m365mailbox = buildM365Mailbox(
        m365mailboxType,
        displayName,
        memberOrOwnerUserIdentity,
        smtpAddress
    );

    const m365AccountSource = buildM365AccountSource({
        sourceId,
        displayName,
        contracts,
        m365mailbox,
        sourceType,
    });

    return {
        ...m365AccountSource,
        mailboxInfo: { ...m365AccountSource.mailboxInfo, auxiliaryMailboxGuid: mailboxGuid },
        m365mailboxType,
    };
}

/**
 * Creates an M365SharedMailboxAccountSource
 * @param displayName
 * @param smtpAddress
 * @param hostingSmtpAddress
 * @param hostingUserIdentity: userIdentity of the account source that hosts this archive
 * @returns M365SharedMailboxAccountSource
 */
export function buildM365SharedMailboxAccountSource(
    displayName: string,
    smtpAddress: string,
    hostingUserIdentity: string,
    isAutomapped: boolean,
    coprincipalPersistenceId: string
): M365SharedMailboxAccountSource {
    const m365mailboxType = M365MailboxType.Shared;
    const m365account = buildM365AccountSourceWithoutSourceId(
        m365mailboxType,
        displayName,
        smtpAddress,
        resourceTypeShared,
        hostingUserIdentity,
        {
            supportsMail: true,
        },
        coprincipalPersistenceId
    );

    return {
        ...m365account,
        m365mailboxType,
        isAutomapped,
    };
}

/**
 * Creates an M365ArchiveMailboxAccountSource
 * @param displayName
 * @param smtpAddress
 * @param hostingSmtpAddress
 * @param hostingUserIdentity: userIdentity of the account source that hosts this archive
 * @returns M365ArchiveMailboxAccountSource
 */
export function buildM365ArchiveMailboxAccountSource(
    auxiliaryMailboxGuid: string,
    displayName: string,
    smtpAddress: string,
    hostingUserIdentity: string,
    coprincipalPersistenceId: string
): M365ArchiveMailboxAccountSource {
    const m365mailboxType = M365MailboxType.Archive;
    const m365account = buildM365AccountSourceWithoutSourceId(
        m365mailboxType,
        displayName,
        smtpAddress,
        resourceTypeArchive,
        hostingUserIdentity,
        {
            supportsMail: true,
        },
        coprincipalPersistenceId,
        auxiliaryMailboxGuid
    );
    return {
        ...m365account,
        m365mailboxType,
    };
}

/**
 * Creates an M365PublicFolderMailboxAccountSource
 * @param displayName
 * @param smtpAddress
 * @param hostingUserIdentity: userIdentity of the account source that hosts this archive
 * @returns M365PublicFolderMailboxAccountSource
 */
export function buildM365PublicFolderMailboxAccountSource(
    displayName: string,
    smtpAddress: string,
    hostingUserIdentity: string,
    coprincipalPersistenceId: string
): M365PublicFolderMailboxAccountSource {
    const m365mailboxType = M365MailboxType.PublicFolder;
    const m365account = buildM365AccountSourceWithoutSourceId(
        m365mailboxType,
        displayName,
        smtpAddress,
        resourceTypePublicFolder,
        hostingUserIdentity,
        {
            supportsMail: true,
            supportsCalendar: true,
        },
        coprincipalPersistenceId
    );
    return {
        ...m365account,
        m365mailboxType,
    };
}

/**
 * Creates an M365M365TeamsMailboxAccountSource
 * @param displayName
 * @param smtpAddress
 * @param hostingSmptAddress
 * @param hostingUserIdentity: userIdentity of the account source that hosts this archive
 * @returns M365TeamsMailboxAccountSource
 */
export function buildM365TeamsMailboxAccountSource(
    displayName: string,
    smtpAddress: string,
    hostingUserIdentity: string,
    coprincipalPersistenceId: string
): M365TeamsMailboxAccountSource {
    const m365mailboxType = M365MailboxType.Teams;
    const m365account = buildM365AccountSourceWithoutSourceId(
        m365mailboxType,
        displayName,
        smtpAddress,
        resourceTypeTeams,
        hostingUserIdentity,
        {
            supportsCalendar: true,
            supportsContacts: true,
        },
        coprincipalPersistenceId
    );
    return {
        ...m365account,
        m365mailboxType,
    };
}

export function buildM365ConnectedMailboxAccountSourceFromHostSourceInformation(
    sourceId: string,
    hostAccount: HostSourceInformation
): M365ConnectedMailboxAccountSource {
    const contracts = buildMailboxContractsFromHostSourceInformation(hostAccount);

    const m365mailbox = buildM365MailboxFromHostSourceInformation(
        M365MailboxType.User,
        hostAccount
    );

    const sourceType = authProviderTypeToAccountSourceType(hostAccount.authProviderType);
    const externalData = hostAccount.externalData;

    const m365AccountSource = buildM365AccountSource({
        sourceId,
        displayName: hostAccount.displayName,
        contracts,
        m365mailbox,
        sourceType,
        externalData,
    });

    const accountState = hostAccount.accountState ?? {
        connectedAccountState: 0 /** OWAConnectedAccountState.Valid */,
    };

    return {
        ...m365AccountSource,
        bootState: BootState.Booting,
        mailboxRank: CoprincipalMailboxRank,
        accountState,
        m365mailboxType: M365MailboxType.Connected,
        persistenceId: hostAccount.persistenceId,
        isSharedWithMe: false,
        aliases: hostAccount.aliases ?? [],
        licensingMailboxInfo: {
            ...m365AccountSource.mailboxInfo,
            sourceType: m365AccountSource.sourceType,
            mailboxRank: 'Licensing',
            diagnosticData: 'ASLSConnectedFile',
        },
    };
}
