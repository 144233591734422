import type { PopupRequest, PublicClientApplication } from '@azure/msal-browser-1p';
import type MsalTokenCallback from '../schema/MsalTokenCallback';
import acquireTokenMsal from './acquireTokenMsal';
import { getScopeMsal } from './getScopeMsal';
import type { MailboxInfo } from 'owa-client-types';

export default function loginUserAndAcquireTokenMsal(
    msalInstance: PublicClientApplication,
    resource: string,
    authChallenge: string,
    allowConsentPrompt: boolean,
    userPrincipalName: string | undefined,
    getAccessTokenCallback: MsalTokenCallback,
    mailboxInfo: MailboxInfo
): void {
    const scopes = [getScopeMsal(resource)];
    const domain_hint = 'organizations';

    const loginRequest: PopupRequest = {
        scopes,
        loginHint: userPrincipalName,
        domainHint: domain_hint,
        claims: authChallenge,
    };

    msalInstance
        .ssoSilent(loginRequest)
        .catch(function (error) {
            if (shouldTryLoginPopup(error, allowConsentPrompt)) {
                return msalInstance.loginPopup(loginRequest);
            }

            return Promise.reject(error);
        })
        .then(function (loginResponse) {
            if (loginResponse?.account) {
                const userAccount = loginResponse.account;
                acquireTokenMsal(
                    msalInstance,
                    resource,
                    authChallenge,
                    allowConsentPrompt,
                    userAccount,
                    userPrincipalName?.toString(),
                    getAccessTokenCallback,
                    mailboxInfo
                );
            } else {
                // Either loginResponse is null or loginResponse.account.
                let error: any;
                if (!loginResponse) {
                    error.errorCode = 'loginpopup_response_null';
                    error.errorMessage = 'loginPopup returned empty response';
                } else {
                    error.errorCode = 'loginpopup_response_no_useraccount';
                    error.errorMessage = 'loginPopup returned response without userAccount';
                }
                getAccessTokenCallback(error, null /* token */);
            }
        })
        .catch(function (error) {
            getAccessTokenCallback(error, null /* token */);
        });
}

function shouldTryLoginPopup(error: any, allowConsentPrompt: boolean): boolean {
    if (!error) {
        return false;
    }
    if (error.errorCode === 'interaction_required' || error.errorCode === 'login_required') {
        return true;
    }
    if (error.errorCode === 'consent_required' && allowConsentPrompt) {
        return true;
    }

    return false;
}
