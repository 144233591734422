/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type { ResourceId } from '../ResourceId';
import type { LocalizedString } from '../LocalizedString';

const store = createStore('localizedStrings', {
    currentLocale: '',
    currentCulture: '',
    localizedStrings: new ObservableMap<ResourceId, LocalizedString>({}),
})();
export const getLocalizedStringStore = () => store;
