import { LazyModule, createLazyComponent } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NewOutlookPromoDialog" */ './lazyIndex')
);

export const NewOutlookPromoDialogInner = createLazyComponent(
    lazyModule,
    m => m.NewOutlookPromoDialogInner
);
