import { GetTokenApi } from './GetTokenApi';
import type {
    TokenRequestParams,
    EnterpriseTokenRequestParams,
    ConsumerTokenRequestParams,
} from './schema/TokenRequestParams';
import type { ILogger } from './utils/ILogger';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import { getTokenFromMsalForEnterprise } from './utils/getTokenFromMsalForEnterprise';
import { getTokenFromMsalForConsumer } from './utils/getTokenFromMsalForConsumer';
import { shouldMsalFetchTokenForEnterprise } from 'owa-msaljs/lib/shouldMsalFetchTokenForEnterprise';
/*
    GetTokenApiUsingMSAL: used to fetch token from OWA in MSAL,
    Uses GetTokenApi as a fallback to fetch token from Server
*/
export class GetTokenApiUsingMSAL extends GetTokenApi {
    public readonly LoggerPrefix = 'GetTokenApiUsingMSAL';

    public async getTokenForEnterpriseCallback(
        params: TokenRequestParams,
        logger: ILogger
    ): Promise<TokenResponse> {
        const enterpriseParams = params as EnterpriseTokenRequestParams;

        if (shouldMsalFetchTokenForEnterprise(enterpriseParams.resource)) {
            const cacheKey = this.tokenCache.getCacheKey(enterpriseParams);
            logger.addCheckpoint(`${this.LoggerPrefix}EnterpriseCallback_FetchingTokenFromMSAL`);
            const tokenResponse = await getTokenFromMsalForEnterprise(enterpriseParams, logger);
            if (tokenResponse) {
                logger.addCheckpoint(`${this.LoggerPrefix}EnterpriseCallback_FetchedFromMSAL`);

                // OWS/OWA API request should not be redirected to fallback
                if (tokenResponse.AccessToken || enterpriseParams.shouldFetchTokenFromMsalOnly) {
                    // only cache the response if we're not going to fallback to getTokenForResource
                    this.tokenCache.putCachedToken(
                        cacheKey,
                        enterpriseParams,
                        tokenResponse,
                        function (this: GetTokenApiUsingMSAL) {
                            return getTokenFromMsalForEnterprise(enterpriseParams, logger);
                        }
                    );
                    return tokenResponse;
                }
            }
        }

        return super.getTokenForEnterpriseCallback(enterpriseParams, logger);
    }

    public async getTokenForConsumerCallback(
        params: TokenRequestParams,
        logger: ILogger
    ): Promise<TokenResponse> {
        const consumerParams = params as ConsumerTokenRequestParams;
        const cacheKey = this.tokenCache.getCacheKey(consumerParams);

        logger.addCheckpoint(`${this.LoggerPrefix}ConsumerCallbac_FetchingTokenFromMSAL`);
        const tokenResponse = await getTokenFromMsalForConsumer(consumerParams, logger);

        if (tokenResponse) {
            logger.addCheckpoint(`${this.LoggerPrefix}ConsumerCallback_FetchedFromMSAL`);
            this.tokenCache.putCachedToken(cacheKey, consumerParams, tokenResponse);
        }

        return tokenResponse;
    }
}
