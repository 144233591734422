import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OutlookMailComposeWeb" */ './lazyIndex')
);

export const lazyCreateDraftWeb = createLazyResolver(
    'MUTATION_CREATE_DRAFT_WEB',
    lazyModule,
    m => m.createDraftWeb
);

export const lazyCreateSmartResponseWeb = createLazyResolver(
    'MUTATION_CREATE_SMART_RESPONSE_WEB',
    lazyModule,
    m => m.createSmartResponseWeb
);

export const lazySaveDraftWeb = createLazyResolver(
    'MUTATION_SAVE_DRAFT_WEB',
    lazyModule,
    m => m.saveDraftWeb
);

export const lazySendItemWeb = createLazyResolver(
    'MUTATION_SEND_ITEM_WEB',
    lazyModule,
    m => m.sendItemWeb
);

export const lazyAddAttachmentToDraftWeb = createLazyResolver(
    'MUTATION_ADD_ATTACHMENT_TO_DRAFT_WEB',
    lazyModule,
    m => m.addAttachmentToDraftWeb
);

export const lazySaveSmartResponseWeb = createLazyResolver(
    'MUTATION_SAVE_SMART_RESPONSE_WEB',
    lazyModule,
    m => m.saveSmartResponseWeb
);

export const lazyUndoSendWeb = createLazyResolver(
    'MUTATION_UNDO_SEND_WEB',
    lazyModule,
    m => m.undoSendWeb
);
