import React from 'react';
import {
    RibbonBottomBar,
    getRibbonBottomBarId,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { getRibbonBottomBarStylingConstants } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/ribbonStylingConstants';
import type {
    ControlInMenuRendererFunction,
    ControlRendererFunction,
    RibbonBottomBarClassNames,
    RibbonBottomBarStylingConstants,
    RibbonModeToggleProps,
    RibbonPaddleProps,
    RibbonTabDefinitionMLR,
    RibbonTabDefinitionSLR,
} from '@1js/acui-ribbon-like';
import { appIcons, getAppStrings } from 'owa-acui-theme';
import {
    bottomBar,
    bottomBarContainer,
    bottomBarContainerDefaultChevron,
    chevronContainer,
    root,
    tabContentContainer,
} from './CommandRibbonBottomBar.scss';
import type { AppPaneUnderlayViewState } from 'owa-application/lib/store/store';
import { type CommandingViewMode } from 'owa-outlook-service-option-store/lib/store/schema/options/CommandingOptions';
import { FluentIconStyleProvider } from 'owa-fluent-icons-svg';
import { RibbonModeMenuContainer } from './RibbonModeMenuContainer';
import { onViewModeChanged } from 'owa-command-ribbon-store';
import isAppPaneUnderlayExpanded from 'owa-application/lib/utils/isAppPaneUnderlayExpanded';
import { isFlexPaneExpanded } from 'owa-suite-header-store';
import { observer } from 'owa-mobx-react';
import { useControlInMenuRendererFunction } from '../util/useControlInMenuRendererFunction';
import { isMultiLineRibbon } from '../util/isMultiLineRibbon';
import ChevronDown from 'owa-fluent-icons-svg/lib/icons/ChevronDownRegular';
import { isFeatureEnabled } from 'owa-feature-flags';

const iconStyles = {
    rootStyle: {
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'top',
        width: '100%',
    },
};

// Overwriting styles that `FluentIconStyleProvider` applies
const iconStylesFluentV9 = {
    iconStyle: {
        contain: 'inherit',
        height: 'inherit',
        verticalAlign: 'inherit',
        width: 'inherit',
    },
    rootStyle: {
        contain: 'inherit',
        display: 'inherit',
        height: 'inherit',
        verticalAlign: 'inherit',
        width: 'inherit',
    },
};

const customStylingConstants: RibbonBottomBarStylingConstants = {
    ...getRibbonBottomBarStylingConstants('Multiline'),
    bottomBarHeight: 92,
    groupDividerPadding: {
        ...getRibbonBottomBarStylingConstants('Multiline').groupDividerPadding,
        bottom: 4,
        top: 4,
    },
};

const ribbonModeToggleProps: RibbonModeToggleProps = {
    isRibbonModeToggleDisabled: false,
    onRibbonModeToggle: () => {
        onViewModeChanged(isMultiLineRibbon() ? 1 : 2);
    },
    ribbonModeToggleIcon: ChevronDown,
};

const customStyles: Partial<RibbonBottomBarClassNames> = {
    chevronContainer,
    root,
    tabContentContainer,
};

export const CommandRibbonBottomBar = observer(function CommandRibbonBottomBar({
    disableAnimation,
    isProjectionPopout,
    projectionUnderlay,
    selectedTab,
    controlRendererFunction,
}: {
    disableAnimation?: boolean;
    isProjectionPopout: boolean;
    projectionUnderlay?: AppPaneUnderlayViewState;
    selectedTab: RibbonTabDefinitionSLR | RibbonTabDefinitionMLR;
    controlRendererFunction: (c: ControlInMenuRendererFunction) => ControlRendererFunction;
}) {
    const { controlGroups, id, initialScalingSteps, scalingSteps } = selectedTab;
    const controlInMenuRendererFunction = useControlInMenuRendererFunction(isProjectionPopout);

    const isFluentV9Enabled = isFeatureEnabled('mon-ribbon-fluent-v9-ribbon');
    const isDefaultChevronEnabled = isFeatureEnabled('mon-ribbon-1js-chevron');

    const isAppPaneExpanded = isAppPaneUnderlayExpanded(projectionUnderlay) || false;
    const flexPaneExpanded = isFlexPaneExpanded();

    // A bit of a hack since `paddleProps` and `isAppPaneExpanded` are not related, but:
    // 1. We need `RibbonBottombar` to re-render when a pane opens, closes, or expands.
    // 2. `RibbonBottombar` will only re-render when its props change.
    //
    // Using `useMemo`, we redefine `paddleProps` only when `isAppPaneExpanded` changes
    // (resulting in `RibbonBottombar` re-rendering).
    const paddleProps: RibbonPaddleProps = React.useMemo(
        () => ({
            disableModeTogglePadding: !isDefaultChevronEnabled,
            ribbonPaddlesIcon: appIcons.downChevron.icon12,
        }),
        [isAppPaneExpanded, flexPaneExpanded]
    );

    return (
        <div id="BottomBar" className={isFluentV9Enabled ? undefined : bottomBar}>
            <div
                className={
                    isFluentV9Enabled
                        ? undefined
                        : isDefaultChevronEnabled
                        ? bottomBarContainerDefaultChevron
                        : bottomBarContainer
                }
            >
                <FluentIconStyleProvider {...(isFluentV9Enabled ? iconStylesFluentV9 : iconStyles)}>
                    <RibbonBottomBar
                        appStrings={getAppStrings()}
                        ariaLabeledBy={id}
                        controlGroups={controlGroups}
                        controlRendererFunction={controlRendererFunction(
                            controlInMenuRendererFunction
                        )}
                        controlInMenuRendererFunction={controlInMenuRendererFunction}
                        customStyles={isFluentV9Enabled ? undefined : customStyles}
                        customStylingConstants={
                            isMultiLineRibbon() && !isFluentV9Enabled
                                ? customStylingConstants
                                : undefined
                        }
                        disableAnimations={disableAnimation}
                        id={getRibbonBottomBarId(id)}
                        initialScalingSteps={initialScalingSteps}
                        overflowMenuLauncherIcon={appIcons.overflowMenuLauncher}
                        paddleProps={paddleProps}
                        scalingSteps={scalingSteps}
                        preventShadowRendering={true}
                        disableQuickScaling={true}
                        ribbonModeToggleProps={
                            isDefaultChevronEnabled ? ribbonModeToggleProps : undefined
                        }
                    />
                </FluentIconStyleProvider>
            </div>

            {!isDefaultChevronEnabled && <RibbonModeMenuContainer />}
        </div>
    );
},
'CommandRibbonBottomBar');
