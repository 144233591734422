import React from 'react';
import { useFluentProviderThemeStyleTag } from '@fluentui/react-components';
import type { FluentProviderProps } from '@fluentui/react-provider';
import { addClass, removeClass } from 'owa-griffel-utils';
import { observer } from 'owa-mobx-react';

export const FluentThemeWrapper = observer(function FluentThemeWrapper({
    theme,
    targetDocument = window.document,
    children,
}: Pick<FluentProviderProps, 'theme' | 'targetDocument' | 'children'>) {
    const { styleTagId: className } = useFluentProviderThemeStyleTag({
        theme,
        targetDocument,
        rendererAttributes: {},
    });

    React.useLayoutEffect((): void | (() => void) => {
        if (className) {
            addClass(targetDocument.documentElement, className);

            return () => {
                removeClass(targetDocument.documentElement, className);
            };
        }
    }, [targetDocument, className]);

    return <>{children}</>;
},
'FluentThemeWrapper');
