import type { NovaEvent } from '@nova/types';
import type { MailboxInfo } from 'owa-client-types';
import type { AppHostServiceEventOriginator } from './originator';

export const initializeLPCEventType = 'initializeLPC' as const;

export interface InitializeLPCEvent extends NovaEvent<InitializeLPCEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof initializeLPCEventType;
    data: () => InitializeLPCEventData;
}

export interface InitializeLPCEventData {
    viewerMailboxInfo: MailboxInfo;
}
