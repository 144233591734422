import extractConfig from './utils/extractConfig';
import { PerformanceDatapoint } from './datapoints/PerformanceDatapoint';
import { PerformanceCoreDatapoint } from './datapoints/PerformanceCoreDatapoint';
import type { ActionMessage, DispatchFunction } from 'satcheljs';
import { DatapointStatus } from 'owa-analytics-types';
import { registerCreateServiceResponseCallback as registerOwsCreateServiceResponseCallback } from 'owa-service/lib/fetchWithRetry';
import { registerCreateServiceResponseCallback as registerOwsPrimeCreateServiceResponseCallback } from 'owa-ows-gateway/lib/registerCreateServiceResponseCallback';
import type RequestOptions from 'owa-service/lib/RequestOptions';
import type { CoreDatapointConfig, DatapointConfig } from 'owa-analytics-types';
import { lazyTrackNetworkResponse } from './lazyFunctions';
import type {
    DatapointConfigWithFunctions,
    CoreDatapointConfigWithFunctions,
} from './utils/extractConfig';
import { getActionMetadata } from './utils/getActionMetadata';
import { getNextPaint } from 'owa-analytics-shared/lib/utils/getNextPaint';
import type { AnalyticsCoreEventNames } from 'owa-analytics-events';

const MAX_ACTIONS_TO_KEEP = 7;

let actionDatapointStack: PerformanceDatapoint[] = [];
export function getActionDatapointStack() {
    return actionDatapointStack;
}

export function clearActionDatapointStack() {
    actionDatapointStack = [];
}

export function returnTopExecutingActionDatapoint(
    filter: string | ((dp: PerformanceDatapoint) => boolean)
): PerformanceDatapoint | null | undefined {
    return actionDatapointStack.filter(
        typeof filter == 'function' ? filter : dp => dp.getEventName() == filter
    )[0];
}

function trackServiceResponses(
    responsePromise: Promise<Response>,
    actionName: string,
    _url: string,
    _attemptCount: number,
    optionsPromise: Promise<RequestOptions>
) {
    var datapoint = actionDatapointStack[0];
    if (datapoint) {
        lazyTrackNetworkResponse.importAndExecute(
            datapoint,
            responsePromise,
            actionName,
            optionsPromise
        );
    }
}

registerOwsCreateServiceResponseCallback(trackServiceResponses);
registerOwsPrimeCreateServiceResponseCallback(trackServiceResponses);

export function addDatapointMiddleware(next: DispatchFunction, actionMessage: ActionMessage) {
    getActionMetadata().count++;
    const actionType = actionMessage.type;

    while (getActionMetadata().queue.length >= MAX_ACTIONS_TO_KEEP) {
        getActionMetadata().queue = getActionMetadata().queue.slice(1);
    }
    if (actionType) {
        getActionMetadata().queue.push(actionType);
    }

    // if there is a datapoint config or action datapoint
    if (actionMessage.dp || actionMessage.actionDatapoint) {
        if (actionType) {
            getActionMetadata().stack.push(actionType);
        }
        return addDataPointInternal(
            next.bind(null, actionMessage),
            actionMessage.dp,
            actionMessage
        );
    }

    return next(actionMessage);
}

export function wrapFunctionForDatapoint<
    DatapointArgs extends any[],
    WrappedFunctionArgs extends [...DatapointArgs, ...any[]],
    WrappedFunctionReturnType
>(
    config: DatapointConfigWithFunctions<DatapointArgs>,
    funcToWrap: (...args: WrappedFunctionArgs) => WrappedFunctionReturnType
): (...args: WrappedFunctionArgs) => WrappedFunctionReturnType {
    return wrapFunctionForDatapointInternal<
        DatapointArgs,
        WrappedFunctionArgs,
        WrappedFunctionReturnType
    >(config, funcToWrap, false /* isCore */);
}

export function wrapFunctionForCoreDatapoint<
    DatapointArgs extends any[],
    WrappedFunctionArgs extends [...DatapointArgs, ...any[]],
    WrappedFunctionReturnType
>(
    config: CoreDatapointConfigWithFunctions<DatapointArgs>,
    funcToWrap: (...args: WrappedFunctionArgs) => WrappedFunctionReturnType
): (...args: WrappedFunctionArgs) => WrappedFunctionReturnType {
    return wrapFunctionForDatapointInternal<
        DatapointArgs,
        WrappedFunctionArgs,
        WrappedFunctionReturnType
    >(config, funcToWrap, true /* isCore */);
}

function wrapFunctionForDatapointInternal<
    DatapointArgs extends any[],
    WrappedFunctionArgs extends [...DatapointArgs, ...any[]],
    WrappedFunctionReturnType
>(
    config: DatapointConfigWithFunctions<DatapointArgs>,
    funcToWrap: (...args: WrappedFunctionArgs) => WrappedFunctionReturnType,
    isCore: boolean
): (...args: WrappedFunctionArgs) => WrappedFunctionReturnType {
    return config
        ? (...args: WrappedFunctionArgs) => {
              return addDataPointInternal(
                  () => funcToWrap.apply(null, args),
                  extractConfig(config, args as unknown as DatapointArgs),
                  undefined /* actionMessage */,
                  isCore
              );
          }
        : funcToWrap;
}

function addDataPointInternal(
    executeNext: () => any,
    config: DatapointConfig & {
        skipAutoEndDatapoint?: string;
    },
    actionMessage?: ActionMessage & {
        lazyOrchestrator?: boolean;
    },
    isCore?: boolean
) {
    let datapoint: PerformanceDatapoint | PerformanceCoreDatapoint | null = null;
    if (actionMessage?.actionDatapoint) {
        datapoint = actionMessage.actionDatapoint;
    } else if (config.name) {
        datapoint =
            isCore && !(config as CoreDatapointConfig).disableIsCore
                ? /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
                   * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
                   *	> Datapoint's event names can only be a string literals as the first argument of the constructor. */
                  new PerformanceCoreDatapoint(
                      config.name as AnalyticsCoreEventNames,
                      config.options
                  )
                : /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
                   * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
                   *	> Datapoint's event names can only be a string literals as the first argument of the constructor. */
                  new PerformanceDatapoint(config.name, config.options);
        // only push the datapoint on the stack if it has just been created
        actionDatapointStack.push(datapoint);

        if (datapoint) {
            try {
                if (config.customData) {
                    datapoint.addCustomData(config.customData);
                }
                if (config.cosmosOnlyData) {
                    datapoint.addCosmosOnlyData(config.cosmosOnlyData);
                }
                if (config.actionSource) {
                    datapoint.addActionSource(config.actionSource);
                }
                if (config.unifiedTelemetry) {
                    datapoint.addUnifiedTelemetryData(config.unifiedTelemetry);
                }
            } catch {
                // ignore errors when trying to add custom data
            }
        }
    }

    var returnValue: any = null;
    try {
        returnValue = executeNext();
    } catch (error) {
        if (datapoint) {
            datapoint.endAction(undefined, error);
            actionDatapointStack.pop();
        }

        throw error;
    } finally {
        getActionMetadata().stack.pop();
    }

    // if it is a lazy orchestrator, we want the clone of the action to log it,
    // not the initial action that imports the bundle
    if (datapoint && actionMessage?.lazyOrchestrator) {
        actionMessage.actionDatapoint = datapoint;
        datapoint = null;
    }

    if (datapoint) {
        if (!config.skipAutoEndDatapoint) {
            const dp = datapoint;
            // if the return value is not a promise, then let's create a promise and resolve it right away
            // this will capture all of the javascript in the call stack
            const returnPromise = returnValue?.then ? returnValue : Promise.resolve();
            returnPromise
                .then(() => {
                    getNextPaint(isVisible =>
                        dp.endAction(
                            isVisible ? DatapointStatus.Success : DatapointStatus.BackgroundSuccess
                        )
                    );
                })
                .catch((error: Error) => {
                    dp.endAction(undefined, error);
                });
        }

        actionDatapointStack.pop();
    }

    return returnValue;
}
