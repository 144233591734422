import { dedupeArrayValues } from 'owa-selected-calendars-utils';
import { trace } from 'owa-trace';
import { updateUserConfigurationService } from 'owa-service/lib/utils/updateUserConfigurationService';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import type { MailboxInfo } from 'owa-client-types';

const VIEW_STATE_CONFIGURATION = 'OWA.ViewStateConfiguration';

export function updateSelectedCalendarsState(
    selectedCalendars: string[],
    mailboxInfo: MailboxInfo
) {
    if (selectedCalendars) {
        updateUserConfigurationService(
            [
                {
                    key: 'SelectedCalendarsDesktop',
                    valuetype: 'StringArray',
                    value: dedupeArrayValues(selectedCalendars),
                },
            ],
            VIEW_STATE_CONFIGURATION,
            getMailboxRequestOptions(mailboxInfo)
        ).catch(() => {
            trace.warn('Could not save new selected calendars list to user configuration');
        });
    }
}
