import { store } from '../store/store';
import { addThemeData } from '../mutators/addThemeData';
import { getThemeDataName } from '../utils/getThemeDataName';
import type { ThemeResources } from 'owa-theme-shared';
import { fetchThemeFile, ThemeConstants } from 'owa-theme-shared';
import { getMappedTheme } from '../utils/getMappedTheme';

export function fetchThemeResource(themeId: string, isDarkTheme: boolean): ThemeResources {
    const themeDataName = getThemeDataName(getMappedTheme(themeId).mappedToThemeId, isDarkTheme);
    if (store.themeData.has(themeDataName)) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return store.themeData.get(themeDataName)!;
    } else {
        fetchThemeFile(themeDataName).then(themeData => {
            addThemeData(themeDataName, themeData);
        });

        // The base light and dark theme resources are added to the store at initialization
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return store.themeData.get(
            isDarkTheme ? ThemeConstants.BASE_DARK_THEME_ID : ThemeConstants.BASE_THEME_ID
        )!;
    }
}
