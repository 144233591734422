import type { MailboxInfo } from 'owa-client-types';
import type { OpenLPCEvent, OpenLPCEventData } from './OpenLPCEvent';
import { OpenLPCEventType } from './OpenLPCEvent';
import type {
    OpenLivePersonaEditorEvent,
    OpenLivePersonaEditorData,
    PersonaIdentifiers,
} from './OpenLivePersonaEditor';
import { OpenLivePersonaEditorEventType } from './OpenLivePersonaEditor';
import { AppHostServiceEventOriginator } from './originator';
import {
    ComposeEmailEventType,
    type ComposeEmailEvent,
    type ComposeEmailEventData,
} from './ComposeEmailEvent';
import {
    StartCallViaSipProtocolEventType,
    type StartCallViaSipProtocolEvent,
    type StartCallViaSipProtocolEventData,
} from './StartCallViaSipProtocolEvent';
import {
    StartChatViaSipProtocolEventType,
    type StartChatViaSipProtocolEvent,
    type StartChatViaSipProtocolEventData,
} from './StartChatViaSipProtocolEvent';
import {
    initializeLPCEventType,
    type InitializeLPCEvent,
    type InitializeLPCEventData,
} from './InitializeLPCEvent';

export type AppHostServiceEvent = OpenLPCEvent | OpenLivePersonaEditorEvent;

export const AppHostServiceEventTypes = {
    openLPC: OpenLPCEventType,
    initializeLPC: initializeLPCEventType,
    openLivePersonaEditor: OpenLivePersonaEditorEventType,
    composeEmail: ComposeEmailEventType,
    startCallViaSipProtocol: StartCallViaSipProtocolEventType,
    startChatViaSipProtocol: StartChatViaSipProtocolEventType,
} as const;

export const AppHostServiceEvents = {
    initializeLPC: (data: () => InitializeLPCEventData): InitializeLPCEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.initializeLPC,
        data,
    }),
    openLPC: (data: () => OpenLPCEventData): OpenLPCEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.openLPC,
        data,
    }),
    openLivePersonaEditor: (
        mailboxInfo: MailboxInfo,
        personaIdentifiers?: PersonaIdentifiers
    ): OpenLivePersonaEditorEvent => ({
        originator: AppHostServiceEventOriginator,
        type: OpenLivePersonaEditorEventType,
        data: (): OpenLivePersonaEditorData => ({
            personaIdentifiers,
            mailboxInfo,
        }),
    }),
    composeEmail: (data: () => ComposeEmailEventData): ComposeEmailEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.composeEmail,
        data,
    }),
    startCallViaSip: (
        data: () => StartCallViaSipProtocolEventData
    ): StartCallViaSipProtocolEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.startCallViaSipProtocol,
        data,
    }),
    startChatViaSip: (
        data: () => StartChatViaSipProtocolEventData
    ): StartChatViaSipProtocolEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.startChatViaSipProtocol,
        data,
    }),
} as const;
