import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isOfflinePeopleDataViaPDVEnabled } from './isOfflinePeopleDataViaPDVEnabled';

export function isPrefetchForPDVDisabled(viewerMailboxInfo: MailboxInfo) {
    // We are intentionally not passing in MailboxInfo to below
    // flight checks because framework sync currently is
    // completely conditioned on the global settings mailbox account's
    // flights since they have some trouble at this time
    // to completely flight offline by account.
    return (
        isOfflinePeopleDataViaPDVEnabled(viewerMailboxInfo) &&
        isFeatureEnabled('peo-offline-disable-prefetch')
    );
}
