import React from 'react';
import { observer } from 'owa-mobx-react';
import { AppRibbonContainer } from './AppRibbonContainer';
import { AppRibbonContent } from './AppRibbonContent';
import type { AppRibbonProps } from './AppRibbonProps';

const AppRibbon = observer(function AppRibbon(props: AppRibbonProps) {
    return (
        <AppRibbonContainer>
            <AppRibbonContent
                getRibbonProps={props.getRibbonProps}
                renderExtraElements={props.renderExtraElements}
                ribbonPropsDependency1={props.ribbonPropsDependency1}
                ribbonPropsDependency2={props.ribbonPropsDependency2}
            />
        </AppRibbonContainer>
    );
}, 'AppRibbon');

export { AppRibbon };
