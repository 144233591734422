import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/store';
import type BrowserHeightBucket from '../store/schema/BrowserHeightBucket';

/**
 * Sets browser's current height bucket
 * @param browserHeightBucket Browser height bucket
 */
export default mutatorAction(
    'setBrowserHeightBucket',
    (browserHeightBucket: BrowserHeightBucket) => {
        getStore().browserHeightBucket = browserHeightBucket;
    }
);
