import { ApolloLink } from '@apollo/client';
import type { Resolvers, ResolverContext } from 'owa-graph-schema';
import { mailboxServerLink } from '../links/mailboxServerLink';
import { canUsePstGqlEndpoint, pstServerLink, isPstFileOperation } from '../links/pstServerLink';

export type ResolverImports = {
    offline?: Resolvers<ResolverContext>;
    pst?: Resolvers<ResolverContext>;
    web: Resolvers<ResolverContext>;
};

export function createGraphServerLink(
    context: Record<string, any>,
    resolverImports: ResolverImports,
    remoteLink: ApolloLink
) {
    if (canUsePstGqlEndpoint()) {
        return ApolloLink.split(
            isPstFileOperation,
            pstServerLink(context, resolverImports.pst || {}),
            mailboxServerLink(context, resolverImports, remoteLink)
        );
    } else {
        return mailboxServerLink(context, resolverImports, remoteLink);
    }
}
