import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isCopilotBusinessChat } from './isCopilotBusinessChat';
import { trace } from 'owa-trace';

/**
 * Checks to see if the user has Copilot License
 * @returns true if the user has Copilot License
 */
export function ShouldEnableCopilotChat(mailboxInfo?: MailboxInfo) {
    if (isFeatureEnabled('immersive-bizchat-license-check')) {
        const hasCopilotBusinessChatLicense = isCopilotBusinessChat(mailboxInfo);
        trace.info(
            'License Check Feature enabled and HasCopilotBusinessChatLicense: ' +
                hasCopilotBusinessChatLicense
        );
        return hasCopilotBusinessChatLicense;
    } else if (
        isFeatureEnabled('immersive-bizchat-feature-a100-users') ||
        isFeatureEnabled('immersive-bizchat-feature')
    ) {
        trace.info('Allow List Validation Successful, Enabling CopilotChat');
        return true;
    } else {
        trace.info('Validation Unsuccessful, disabling CopilotChat');
        return false;
    }
}
