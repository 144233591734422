import { getSourceIdByMailboxInfo, isGlobalSettingsMailbox } from 'owa-account-source-list-store';
import { addSetAccountConfigurationLog } from 'owa-account-user-configuration-monitor/lib/addSetAccountConfigurationLog';
import { logStartGreyError } from 'owa-analytics-start';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import { mutatorAction } from 'satcheljs';
import { getRawStoreInternalOnly } from '../store/store';
import type { AccountConfigurationOrigin } from '../types/AccountConfigurationOrigin';
import { makeAdditionalAccountConfiguration } from '../utils/makeAdditionalAccountConfiguration';
import { isGlobalSettingsAccountSourceId } from '../utils/isGlobalSettingsAccountSourceId';
import { logSetAccountConfiguration } from '../utils/logSetAccountConfiguration';
import { userConfigurationSet } from './userConfigurationSet';

const setAccountConfigurationInternal = mutatorAction(
    'setAccountConfigurationInternal',
    (
        mailboxInfo: MailboxInfo,
        userConfiguration: OwaUserConfiguration,
        forceIsGlobalAccountMailboxInfoResult: boolean // Remove with Task 252844: Update session store to rely exclusively on sourceId for account uniqueness and identity checks
    ): void => {
        const store = getRawStoreInternalOnly();

        const additionalAccountProps = makeAdditionalAccountConfiguration(userConfiguration);
        const config = {
            ...userConfiguration,
            ...additionalAccountProps,
        };

        addSetAccountConfigurationLog(
            forceIsGlobalAccountMailboxInfoResult,
            isGlobalSettingsMailbox(mailboxInfo),
            userConfiguration
        );

        if (forceIsGlobalAccountMailboxInfoResult /*isGlobalSettingsMailbox(mailboxInfo)*/) {
            store.userConfiguration = config;
        } else {
            const accountKey = getIndexerValueForMailboxInfo(mailboxInfo);
            store.nonGlobalAccountDataMap.set(accountKey, config);
        }

        // An aggregate map is also maintained with a merged view of all folder id name mappings for all accounts.
        const currentMap = store.defaultFolderIdToNameMap;
        store.defaultFolderIdToNameMap = new Map([
            ...currentMap,
            ...additionalAccountProps.defaultFolderIdToNameMap,
        ]);
    }
);

export const setAccountConfiguration = (
    configurationOrigin: AccountConfigurationOrigin,
    mailboxInfo: MailboxInfo,
    userConfiguration: OwaUserConfiguration,
    forceIsGlobalAccountMailboxInfoResult: boolean // Remove with Task 252844: Update session store to rely exclusively on sourceId for account uniqueness and identity checks
) => {
    logGlobalStatusMisalignment(forceIsGlobalAccountMailboxInfoResult, mailboxInfo); // Remove with Task 252844: Update session store to rely exclusively on sourceId for account uniqueness and identity checks
    logSetAccountConfiguration(
        configurationOrigin,
        mailboxInfo,
        forceIsGlobalAccountMailboxInfoResult
    );

    setAccountConfigurationInternal(
        mailboxInfo,
        userConfiguration,
        forceIsGlobalAccountMailboxInfoResult
    );

    if (forceIsGlobalAccountMailboxInfoResult /*isGlobalSettingsMailbox(mailboxInfo)*/) {
        const globalConfig = getRawStoreInternalOnly().userConfiguration;
        if (globalConfig) {
            userConfigurationSet(globalConfig);
        }
    } else {
        const indexerValue = getIndexerValueForMailboxInfo(mailboxInfo);
        const accountConfig = getRawStoreInternalOnly().nonGlobalAccountDataMap.get(indexerValue);
        if (accountConfig) {
            userConfigurationSet(accountConfig, indexerValue, mailboxInfo);
        }
    }
};

const logGlobalStatusMisalignment = (
    forceIsGlobalAccountMailboxInfoResult: boolean,
    mailboxInfo: MailboxInfo
) => {
    const callerGlobal = forceIsGlobalAccountMailboxInfoResult;

    const sourceId = getSourceIdByMailboxInfo(mailboxInfo);
    const sourceIdGlobal = sourceId ? isGlobalSettingsAccountSourceId(sourceId) : 'undefined';

    if (callerGlobal !== sourceIdGlobal) {
        const e = new Error('SessionStoreGlobalStateMisalignment');
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logStartGreyError(e.message, e, { callerGlobal, sourceIdGlobal });
    }
};
