import { isAccountSourceListStoreInitialized } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import { getOrigin } from 'owa-url/lib/getOrigin';
import {
    isIndexedPathForShadowMailbox,
    areMsalConsumerFlightsEnabled,
} from './isMsalEnabledForConsumerInZeroAccount';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { isResourceBlocked } from './utils/isResourceBlocked';
import { RESOURCE_BLOCK_LIST_CONSUMER } from './utils/constants';

const cachedResult: Map<string, boolean> = new Map();
/**
 * Checks if the request is for Consumer account and whether token fetch from MSAL is enabled.
 **/
export function isMsalEnabledForConsumer(mailboxInfo?: MailboxInfo, resource?: string): boolean {
    if (!areMsalConsumerFlightsEnabled()) {
        return false;
    }

    const zeroAccountState = !isAccountSourceListStoreInitialized() && !mailboxInfo;
    if (zeroAccountState) {
        // If store is not initialized and no mailboxInfo is passed, check host origin to determine if it is consumer request
        return getOrigin().toLowerCase().includes('live') && !isIndexedPathForShadowMailbox();
    }

    mailboxInfo = mailboxInfo ?? getModuleContextMailboxInfo();

    const cacheKey = `${mailboxInfo.mailboxSmtpAddress}-${resource}`;
    let result = cachedResult.get(cacheKey);
    if (result === undefined) {
        result =
            isExoConsumer(mailboxInfo) &&
            !isResourceBlocked(resource, RESOURCE_BLOCK_LIST_CONSUMER);
        cachedResult.set(cacheKey, result);
    }
    return result;
}

function isExoConsumer(mailboxInfo: MailboxInfo): boolean {
    const sessionType = getAccountScopeUserSettings(mailboxInfo).SessionSettings?.WebSessionType;
    return sessionType === 1;
}
