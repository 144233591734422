import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { isFeatureEnabled } from 'owa-feature-flags';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { isMsalEnabledForConsumer } from './isMsalEnabledForConsumer';
import { isConsumer } from 'owa-session-store';
import { isMsalEnabledForBusiness } from './isMsalEnabledForBusiness';
import { isResourceBlocked } from './utils/isResourceBlocked';
import { RESOURCE_BLOCK_LIST_ENTERPRISE } from './utils/constants';

/**
 * Checks if the token fetch from MSAL is enabled for the given resource url.
 **/
export function shouldMsalFetchTokenForEnterprise(resource?: string): boolean {
    const mailboxInfo = getModuleContextMailboxInfo();
    if (isConsumer(undefined /*smtpAddress*/, mailboxInfo)) {
        return isMsalEnabledForConsumer(mailboxInfo, resource);
    }

    if (!areMsalFlightsEnabledForBusiness()) {
        return false;
    }

    return !isResourceBlocked(resource, RESOURCE_BLOCK_LIST_ENTERPRISE);
}

function areMsalFlightsEnabledForBusiness() {
    return (
        (isMsalEnabledForBusiness() ||
            isFeatureEnabled(
                'auth-msaljs-fetchResourceToken',
                undefined /*mailboxInfo*/,
                true /*dontThrowErrorIfNotInitialized*/
            )) &&
        isHostAppFeatureEnabled('msalTokenFetch')
    );
}
