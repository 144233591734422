import {
    getGlobalSettingsAccount,
    getGlobalSettingsAccountMailboxInfo,
    setCoprincipalAccountBootState,
} from 'owa-account-source-list-store';
import { initializeApplicationSettings } from 'owa-application-settings';
import { parseExtraSettings } from 'owa-config';
import {
    extractFlightsFromSettings,
    initializeFeatureFlags,
    isFeatureEnabled,
} from 'owa-feature-flags';
import { getLocalStorageOverrides } from 'owa-feature-flags/lib/utils/overrides/localStorageOverrides';
import { setAccountConfiguration } from 'owa-session-store/lib/actions/setAccountConfiguration';
import { setLogicalRing } from 'owa-config/lib/getLogicalRing';
import type { AnalyticsOptions } from 'owa-analytics-types';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { BootState } from 'owa-account-source-list-store';
import type { WorkerBootstrapOptions } from './types/WorkerBootstrapOptions';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import { setLicesningMailboxInfoFromUserConfiguration } from 'owa-easid-link';

/**
 * Performs bootstrap initialization of stores based on the data returned from the
 * StartupData call. This function is named V1 because the next planned change is
 * to add support for passing in MailboxInfo rather than sourceId
 * @param sessionData SessionData for the coprincipal account returned by the StartupData call
 * @param sourceId Identifies the coprincipal account
 * @param bootState Boot state to which the account will be set
 * @param analyticsOptions Options which will be used for logging analytics
 */
export default function initializeStartupDataV1(
    sessionData: SessionData,
    sourceId: string,
    bootState: BootState,
    analyticsOptions?: AnalyticsOptions,
    workerOptions?: WorkerBootstrapOptions
) {
    const extraSettings = parseExtraSettings(sessionData.extraSettings);
    const featureOverrides: string[] = extraSettings.featureOverrides || [];
    const featureFlags = (sessionData.features || []).concat(featureOverrides);
    if (analyticsOptions) {
        analyticsOptions.defaultFeatureFlags = featureFlags;
    }

    const flightsFromSettings = extractFlightsFromSettings(
        sessionData.applicationSettings?.settings
    );
    const logicalRing = initializeFeatureFlags(
        [...featureFlags, ...flightsFromSettings],
        getLocalStorageOverrides()
    );
    setLogicalRing(logicalRing);

    if (isFeatureEnabled('fwk-increaseStackTraceLimit')) {
        Error.stackTraceLimit = 20;
    }

    if (isFeatureEnabled('fwk-worker-preload')) {
        workerOptions?.preload?.();
    }

    initializeApplicationSettings(sessionData.applicationSettings);
    // NOTE: make a copy of user configuration to modifications to original object
    const userConfig: OwaUserConfiguration = JSON.parse(JSON.stringify(sessionData.owaUserConfig));

    // NOTE: setAccountConfiguration needs to happen after initializeApplicationSettings to make sure the flights
    // and applications settings are initialized when userConfigurationSet is called
    setAccountConfiguration(
        'StartupData',
        getGlobalSettingsAccountMailboxInfo(),
        userConfig,
        true /* workaround for indexer values not always being unique: this is known in this context to be the global account */
    );

    setLicesningMailboxInfoFromUserConfiguration(getGlobalSettingsAccount(), userConfig);

    setCoprincipalAccountBootState(sourceId, bootState);
}
