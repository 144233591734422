import type AccessIssue from 'owa-attachment-policy-access-issue-checker/lib/schema/AccessIssue';
import getCombinedAccessIssue from 'owa-attachment-policy-access-issue-checker/lib/utils/getCombinedAccessIssue';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { isVanityDomain } from './isVanityDomain';
import { logUsage } from 'owa-analytics';

export function isMOSLaunchPagesEnabled(mailboxInfo?: MailboxInfo): boolean {
    const combinedAccessIssue = getCombinedAccessIssue(
        mailboxInfo ?? getModuleContextMailboxInfo()
    );
    const isVanityDomainValue = isVanityDomain();

    logUsage('isMOSLaunchPagesEnabled', { combinedAccessIssue, isVanityDomainValue });

    return (
        isFeatureEnabled('mos-mos3AppService', mailboxInfo) &&
        combinedAccessIssue === 2 &&
        !isVanityDomainValue
    );
}
