import React from 'react';
import type { FluentProviderProps } from '@fluentui/react-provider';
import type { BrandVariants, Theme } from '@fluentui/react-theme';
import { createDOMRenderer, RendererProvider } from '@fluentui/react-components';
import { createDarkTheme, createLightTheme } from '@fluentui/react-theme';
import { HeadlessFluentProvider } from '@fluentui-contrib/react-headless-provider';
import type { IPalette } from '@fluentui/style-utilities';
import { isCurrentCultureRightToLeft } from 'owa-localize';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { getPaletteAsRawColors } from 'owa-theme';
import { observer } from 'owa-mobx-react';
import type { ColorInput } from '@ctrl/tinycolor';
import { TinyColor } from '@ctrl/tinycolor';
import { FluentThemeWrapper } from './FluentThemeWrapper';
import { FluentComponentsThemeContext } from './FluentComponentsThemeContext';

function mixColors(colorA: ColorInput, colorB: ColorInput, amount?: number) {
    return new TinyColor(colorA).mix(new TinyColor(colorB), amount).toHexString();
}

export function mixByPairs(colors: IPalette): BrandVariants {
    return {
        10: colors.themeDarker,
        20: mixColors(colors.themeDarker, colors.themeDark),
        30: colors.themeDark,
        40: mixColors(colors.themeDark, colors.themeDarkAlt),
        50: colors.themeDarkAlt,
        60: mixColors(colors.themeDarkAlt, colors.themePrimary),
        70: colors.themePrimary,
        80: mixColors(colors.themePrimary, colors.themeSecondary),
        90: colors.themeSecondary,
        100: mixColors(colors.themeSecondary, colors.themeTertiary),
        110: colors.themeTertiary,
        120: mixColors(colors.themeTertiary, colors.themeLight),
        130: colors.themeLight,
        140: mixColors(colors.themeLight, colors.themeLighter),
        150: colors.themeLighter,
        160: mixColors(colors.themeLighter, colors.themeLighterAlt),
    };
}

export function useV9BrandTheme(isDarkMode: boolean = getIsDarkTheme()) {
    const palette = getPaletteAsRawColors() as IPalette;

    return React.useMemo<Theme>(() => {
        const brandVariant = mixByPairs(palette);
        return (isDarkMode ? createDarkTheme : createLightTheme)(brandVariant);
    }, [palette, isDarkMode]);
}

export const FluentContext = observer(function FluentContext({
    dir = isCurrentCultureRightToLeft() ? 'rtl' : 'ltr',
    targetDocument = window.document,
    children,
}: Pick<FluentProviderProps, 'theme' | 'dir' | 'targetDocument' | 'children'>) {
    const theme = useV9BrandTheme();

    const renderer = React.useMemo(() => {
        const insertionPoint = targetDocument.head.querySelector('title') ?? undefined;
        (targetDocument as any).fluentRendererProvider =
            (targetDocument as any).fluentRendererProvider ??
            createDOMRenderer(targetDocument, { insertionPoint });
        return (targetDocument as any).fluentRendererProvider;
    }, [targetDocument]);

    return (
        <RendererProvider renderer={renderer} targetDocument={targetDocument}>
            <FluentComponentsThemeContext>
                <FluentThemeWrapper theme={theme} targetDocument={targetDocument}>
                    <HeadlessFluentProvider dir={dir} targetDocument={targetDocument}>
                        {children}
                    </HeadlessFluentProvider>
                </FluentThemeWrapper>
            </FluentComponentsThemeContext>
        </RendererProvider>
    );
},
'FluentContext');
