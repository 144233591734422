import { isUrlPresent } from 'owa-config';
import { findMetatag } from 'owa-metatags';
import { isConsumer } from 'owa-session-store';
import { getHostLocation } from 'owa-url/lib/hostLocation';

export function isVanityDomain(): boolean {
    if (isConsumer()) {
        return false;
    }

    const publicUrl = (findMetatag('publicUrl') || '').toLowerCase();

    const hostname = getHostLocation()?.hostname.toLowerCase();

    const OWAResourceUrls = [
        'outlook.office.com',
        'outlook-sdf.office.com',
        'outlook.office365.com',
        'outlook-sdf.office365.com',
    ];

    /* gulp doesn't reliably set the publicUrl metaTag
        so we need to make sure it is a valid domain string first*/
    return isUrlPresent(publicUrl) && publicUrl !== hostname && !OWAResourceUrls.includes(hostname);
}
