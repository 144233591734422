import {
    accountSourceDataTypeChecker,
    addCoprincipalAccount,
    getAccountBySourceId,
} from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';

import type { MailboxInfo } from 'owa-client-types';
import { type HostSourceInformation, SourceType } from '../types';
import {
    buildCoprincipalAccountSourceFromHostSourceInformation,
    buildM365ConnectedMailboxAccountSourceFromHostSourceInformation,
} from './accountSourceBuilder';
import { buildCoprincipalSourceId } from './sourceIdBuilder';
import { isPstFileAccountInfraEnabled } from '../flights';

/**
 * Returns the MailboxInfo for the specified sourceId
 * @param sourceId the sourceId for the account
 * @returns The mailbox information, or undefined if the account is not a mailbox
 */
export function getMailboxBySourceId(sourceId: string): MailboxInfo | undefined {
    const sourceInfo = getAccountBySourceId(sourceId);
    if (isPstFileAccountInfraEnabled()) {
        return sourceInfo?.mailboxInfo;
    } else {
        return accountSourceDataTypeChecker.isM365Mailbox(sourceInfo)
            ? {
                  sourceId,
                  type: sourceInfo.m365mailbox.type,
                  userIdentity: sourceInfo.m365mailbox.userIdentity,
                  mailboxSmtpAddress: sourceInfo.m365mailbox.emailAddress,
              }
            : undefined;
    }
}

export function getMailboxTypeBySourceId(sourceId: string): string | undefined {
    const sourceInfo = getAccountBySourceId(sourceId);
    switch (sourceInfo?.sourceType) {
        case AccountSourceType.Office365:
            return 'OFFICE365';

        case AccountSourceType.OutlookDotCom:
            return 'OUTLOOKDOTCOM';

        default:
            return undefined;
    }
}

function isPst(account: HostSourceInformation): boolean {
    return account.sourceType === SourceType.PstFile;
}

/**
 * convert the given account (HostSourceInformation) to the appropriate CoprincipalAccountSource
 * derivation and adds to the source list store, and returns the sourceId
 * @param account
 */
export function addCoprincipalHostAccount(account: HostSourceInformation): string {
    if (!account.mailbox && !isPst(account)) {
        throw new Error('Cannot create a Coprincipal account without a mailbox or PST file');
    }

    const sourceId = buildCoprincipalSourceId(account.persistenceId);
    if (!getAccountBySourceId(sourceId)) {
        const accountSource = buildCoprincipalAccountSourceFromHostSourceInformation(
            sourceId,
            account
        );
        addCoprincipalAccount(accountSource);
    }

    return sourceId;
}

/**
 * convert the given account (HostSourceInformation) to M365ConnectedMailboxAccountSource
 * and adds to the source list store, and returns the sourceId
 * @param account
 */
export function addM365ConnectedMailboxHostAccount(account: HostSourceInformation): string {
    if (!account.mailbox) {
        throw new Error('Can not create a Connected Mailbox for an account that has no mailbox');
    }

    const sourceId = buildCoprincipalSourceId(account.persistenceId);

    if (!getAccountBySourceId(sourceId)) {
        const accountSource = buildM365ConnectedMailboxAccountSourceFromHostSourceInformation(
            sourceId,
            account
        );
        addCoprincipalAccount(accountSource);
    }

    return sourceId;
}
