export interface ActionMetadata {
    stack: string[]; // current actions that are firing
    queue: string[];
    count: number;
}

const actionMetadata: ActionMetadata = {
    stack: [],
    queue: [],
    count: 0,
};

export function getActionMetadata(): ActionMetadata {
    return actionMetadata;
}
