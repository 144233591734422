import type { OcpsPolicyStore, UserOcpsPolicies } from 'owa-ocps-get-policies';
import { USER_OCPS_POLICY_STORE } from 'owa-ocps-get-policies/lib/utils/constants';
import { OcpsPolicy } from './OcpsPolicy';
import { getItem, itemExists, localStorageExists } from 'owa-local-storage';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

export function getOcpsPolicyFromLocalStore(
    mailboxInfo: MailboxInfo,
    policyName: OcpsPolicy
): string | undefined {
    if (localStorageExists(window) && itemExists(window, USER_OCPS_POLICY_STORE)) {
        const ocpsPolicyStore: OcpsPolicyStore = JSON.parse(
            getItem(window, USER_OCPS_POLICY_STORE) ?? 'null'
        );
        const mailboxInfoIndexer = getIndexerValueForMailboxInfo(mailboxInfo);

        const PolicyToStoreMap: Record<OcpsPolicy, keyof UserOcpsPolicies> = {
            [OcpsPolicy.Feedback]: 'feedbackEnabled',
            [OcpsPolicy.Contact]: 'emailCollectionEnabled',
            [OcpsPolicy.NpsSurvey]: 'npsSurveyEnabled',
            [OcpsPolicy.ScreenShot]: 'screenshotEnabled',
            [OcpsPolicy.Content]: 'logContentEnabled',
            [OcpsPolicy.OutlookRecommendation]: 'outlookRecommendationEnabled',
            [OcpsPolicy.ContactSupport]: 'contactSupportEnabled',
            [OcpsPolicy.DiagnosticsTroubleshooting]: 'diagnosticsTroubleshootingEnabled',
            [OcpsPolicy.OptionalConnectedExperiences]: 'optionalConnectedExperiencesEnabled',
            [OcpsPolicy.ConnectedOfficeExperience]: 'connectedOfficeExperiencesEnabled',
            [OcpsPolicy.Loop]: 'loopEnabled',
            [OcpsPolicy.LoopEnabledForOutlook]: 'loopEnabledForOutlook',
            [OcpsPolicy.DisableSharingPermissions]: 'disableSharingPermissions',
            [OcpsPolicy.ExperiencesAnalyzingContent]: 'experiencesAnalyzingContentEnabled',
            [OcpsPolicy.ExperiencesDownloadingContent]: 'experiencesDownloadingContentEnabled',
            [OcpsPolicy.EmailCollectionDefault]: 'emailCollectionDefault',
            [OcpsPolicy.ScreenShotDefault]: 'screenshotDefault',
            [OcpsPolicy.ContentSamplesDefault]: 'contentSamplesDefault',
            [OcpsPolicy.M365LinksHandlingBrowserChoice]: 'M365LinksHandlingBrowserChoice',
            [OcpsPolicy.LoopPolls]: 'loopPollsEnabled',
            [OcpsPolicy.ShowCardLoops]: 'showCardLoops',
            [OcpsPolicy.LoopPlanner]: 'loopPlannerEnabled',
            [OcpsPolicy.LoopVideoPlayback]: 'loopVideoPlaybackEnabled',
            [OcpsPolicy.LoopVideoRecord]: 'loopVideoRecordEnabled',
        };

        if (ocpsPolicyStore && ocpsPolicyStore.policies[mailboxInfoIndexer]) {
            const userPolicies: UserOcpsPolicies = ocpsPolicyStore.policies[mailboxInfoIndexer];
            const storePolicyValue: keyof UserOcpsPolicies = PolicyToStoreMap[policyName];
            return userPolicies[storePolicyValue];
        }
    }

    return undefined;
}
