enum FirstPartyAppId {
    Mail = 'ddea774c-382b-47d7-aab5-adc2139a802b',
    Calendar = '8cbeb86f-83e1-43b5-aaba-cd3514322f0b',
    People = '355fbd79-3ba2-4554-8f2d-0300fde91f30',
    Groups = '3961f27f-be9a-447a-b2bd-dc7a6132f763',
    Files = 'd7a12bea-e4fe-49a7-8cd1-c2e6d53722a6',
    ToDo = '59391057-d7d7-49fd-a041-d8e4080f05ec',
    Tasks = 'e9f9b6d1-7948-4b5b-84f2-8a97e3fdb427',
    Word = '34318026-c018-414b-abb3-3e32dfb9cc4c',
    Excel = 'c5251a9b-a95d-4595-91ee-a39e6eed3db2',
    PowerPoint = '48cb9ead-1c19-4e1f-8ed9-3d60a7e52b18',
    OneNote = '6c548c14-5489-42d8-938e-b45a861fefe6',
    OneDrive = '39109bd4-9389-4731-b8d6-7cc1a128d0b3',
}

enum LaunchPageAppId {
    Test = 'com.example.metaostestapp.test',
    Yammer = 'db5e5970-212f-477f-a3fc-2227dc7782bf',
    VivaEngage = 'db5e5970-212f-477f-a3fc-2227dc7782bf',
    Bookings = '4c4ec2e8-4a2c-4bce-8d8f-00fc664a4e5b',
    ToDoMetaOs = '0d5c91ee-5be2-4b79-81ed-23e6c4580427',
    PhoneMetaOs = 'd6acbce9-b904-4cd5-bdbf-8baeef2f50b1',
    AppStore = 'f3a6e67f-850d-4dd9-960a-04c6638ded36',
    AppStoreModal = 'b6b18421-3bba-40df-a983-05fbbc9e2d13',
    AppStoreAbout = 'a9750531-44bc-4346-84a7-c4ceda140ef4',
    OrgExplorer = '1f8c20f5-d70f-4f8e-93e1-31d8fce0c8c9',
    OneDriveMetaOs = '377c982d-9686-450e-9a7c-22aeaf1bc162',
    Meet = '957ed0e3-e5ca-4c82-9676-fd037185c4d8',
    EventsSdf = '8527a80e-88e8-4473-bda1-ab1bddcf72e0', // If updating GUID make sure to change in getNewGroup and onNewEventifyEvent. We cannot use consts there due to large boot size increase
    EventsProd = '260f432d-8a76-46c2-bf48-0f0748ac3eba', // If updating GUID make sure to change in getNewGroup and onNewEventifyEvent. We cannot use consts there due to large boot size increase
    MicrosoftPlaces = '668d0aee-f732-4811-9907-2fbad75526a8', // Not to be confused with Bing Places (see MessageExtensionAppId)
    MicrosoftPlacesSdf = 'd2f8a961-38f9-4452-bc25-e8d42838f570',
    Hoop = '4a6520d6-94a8-4730-9184-24997b9b3401',
    Newsletters = '218795fc-272e-49c0-98b1-a5d648c2d3c7',
    ImmersiveBizChat = 'd870f6cd-4aa5-4d42-9626-ab690c041429',
    OneDriveConsumerMetaOs = '242df6f5-6e64-4e8b-9d45-58502eef7c2d',
}
enum MessageExtensionAppId {
    YouTube = 'com.microsoft.teamspace.tab.youtube',
    Weather = '4fbe105f-b772-4b09-9279-6e8589087289',
    Stocks = '852a6067-4fec-4895-a3ab-a776c77be161',
    Places = 'b4f7d39f-ddc7-4f7e-b320-4344444c5e0e',
    Images = 'd5c805b6-2882-45d3-8657-c4f27520f184',
    News = '306a2667-81f6-4286-a9e4-242c88d60251',
    WikipediaSearch = '25492237-70ef-4c56-a36e-dd3405edd40e',
    ADO = '5b951ce3-bb72-46e1-bbf2-83ea791e4600',
}

enum AddInsAppId {
    ClientStore = '3b67c06a-10af-48ba-831d-933f0d730b0b',
}

type M365AppId = FirstPartyAppId | LaunchPageAppId | MessageExtensionAppId;
type MetaOsAppId = LaunchPageAppId | MessageExtensionAppId;

const M365AppId = { ...FirstPartyAppId, ...LaunchPageAppId, ...MessageExtensionAppId };
const Office365Apps = [
    M365AppId.Word,
    M365AppId.Excel,
    M365AppId.PowerPoint,
    M365AppId.OneNote,
    M365AppId.OneDrive,
];
const isAppOffice365 = (appId: string) => Office365Apps.some(staticApp => staticApp == appId);

export {
    AddInsAppId,
    FirstPartyAppId,
    LaunchPageAppId,
    MessageExtensionAppId,
    M365AppId,
    type MetaOsAppId,
    isAppOffice365,
};
