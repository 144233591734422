type EventSubscriberMap = {
    [K in keyof WindowEventMap]?: Map<unknown, (event: WindowEventMap[K]) => void>;
};
const eventSubscriberMapByWindow = new Map<EventTarget, EventSubscriberMap>();
function onEvent<K extends keyof WindowEventMap, E extends WindowEventMap[K]>(event: E) {
    const targetWindow = event.currentTarget ?? window;
    const eventSubscriberMap = eventSubscriberMapByWindow.get(targetWindow);

    if (eventSubscriberMap) {
        const values = eventSubscriberMap[event.type as K]?.values();
        if (values) {
            for (const handler of values) {
                handler(event as any);
            }
        }
    }
}
function getEventSubscribers<K extends keyof WindowEventMap>(
    targetWindow: Window,
    eventName: K
): Map<unknown, (event: WindowEventMap[K]) => void> {
    if (!eventSubscriberMapByWindow.has(targetWindow)) {
        eventSubscriberMapByWindow.set(targetWindow, {});
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    const eventSubscriberMap = eventSubscriberMapByWindow.get(targetWindow)!;

    return (eventSubscriberMap[eventName] =
        eventSubscriberMap[eventName] ??
        (new Map<unknown, (event: WindowEventMap[K]) => void>() as any));
}
function cleanUpEventSubscriberMapByWindowIfNecessary(targetWindow: Window) {
    const eventSubscriberToTargetWindowMap = eventSubscriberMapByWindow.get(targetWindow);

    if (eventSubscriberToTargetWindowMap) {
        let isNoMoreSubscriberToTargetWindow = true;

        for (const [_, subscribers] of Object.entries(eventSubscriberToTargetWindowMap)) {
            if (subscribers.size > 0) {
                isNoMoreSubscriberToTargetWindow = false;
                break;
            }
        }

        if (isNoMoreSubscriberToTargetWindow) {
            eventSubscriberMapByWindow.delete(targetWindow);
        }
    }
}
export function subscribeToWindowEvent<K extends keyof WindowEventMap>(
    targetWindow: Window,
    eventName: K,
    ref: any,
    handler: (event: WindowEventMap[K]) => void
) {
    const subscribers = getEventSubscribers<K>(targetWindow, eventName);
    if (subscribers.size === 0) {
        targetWindow.addEventListener(eventName, onEvent);
    }
    subscribers.set(ref, handler);
}
export function unsubscribeToWindowEvent<K extends keyof WindowEventMap>(
    targetWindow: Window,
    eventName: K,
    ref: any
) {
    const subscribers = getEventSubscribers<K>(targetWindow, eventName);
    subscribers.delete(ref);
    if (subscribers.size === 0) {
        targetWindow.removeEventListener(eventName, onEvent);

        cleanUpEventSubscriberMapByWindowIfNecessary(targetWindow);
    }
}
