export interface OcpsPolicyStore {
    policies: Record<string, UserOcpsPolicies>;
    retryFetchCount: number;
}

export interface UserOcpsPolicies {
    feedbackEnabled: string;
    emailCollectionEnabled: string;
    emailCollectionDefault: string;
    npsSurveyEnabled: string;
    screenshotEnabled: string;
    screenshotDefault: string;
    logContentEnabled: string;
    contentSamplesDefault: string;
    outlookRecommendationEnabled: string;
    contactSupportEnabled: string;
    diagnosticsTroubleshootingEnabled: string;
    optionalConnectedExperiencesEnabled: string;
    connectedOfficeExperiencesEnabled: string;
    loopEnabled: string;
    loopEnabledForOutlook: string;
    disableSharingPermissions: string;
    experiencesAnalyzingContentEnabled: string;
    experiencesDownloadingContentEnabled: string;
    expirationDate: string;
    M365LinksHandlingBrowserChoice: string;
    loopPollsEnabled: string;
    showCardLoops: string;
    loopPlannerEnabled: string;
    loopVideoPlaybackEnabled: string;
    loopVideoRecordEnabled: string;
}

export interface OcpsPolicyStoreState {
    state: OcpsStoreState;
}

export enum OcpsStoreState {
    ReadyToFetch,
    Fetching,
    Ready,
    FailedRetry,
}
