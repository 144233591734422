import { ApolloLink, Observable } from '@apollo/client';
import type { FetchResult, Operation } from '@apollo/client';
import type { OperationTypeNode, SelectionNode } from 'graphql';
import { enabledForIdb, enabledForRemote, enabledForWeb } from 'owa-application-settings';

import type { Resolvers, ResolverContext } from 'owa-graph-schema';
import { getOperationAST, GraphQLError } from 'graphql';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isIdbFallbackResult } from 'owa-graph-idb-fallback-result';
import objMerge from 'lodash-es/merge';
import { trace } from 'owa-trace';
import { createOperation } from '@apollo/client/link/utils';

type TopLevelResolver =
    | keyof Resolvers['Query']
    | keyof Resolvers['Mutation']
    | keyof Resolvers['Subscription'];

/**
 * The local remote router link takes a graphql operation document and examines the data resolvers that are defined on the client.
 * (data resolvers resolve the operation against OWS/HX/etc)
 *
 * If there is a resolver for the operation, the operation is forwarded to that local execution link.  If not, the operation is
 * dispatched to the remote graphql gateway endpoint.
 *
 * If a single operation document has multiple selections, some local and some remote, document is split into a local-only document
 * and a remote-only document, evaluated, and then the results combined into a single result set.
 */
export const localRemoteRouterLink = ({
    localLink,
    remoteLink,
    resolvers,
    isOfflineEnabled,
}: {
    localLink: ApolloLink;
    remoteLink: ApolloLink;
    resolvers: Resolvers;
    isOfflineEnabled: boolean;
}) => {
    const link = new ApolloLink(operation => {
        const context = operation.getContext() as ResolverContext & {
            headers: Record<string, any>;
        };
        const opNode = getOperationAST(operation.query);
        const remoteSelections: SelectionNode[] = [];
        const localSelections: SelectionNode[] = [];
        const remoteEnabledSelections: SelectionNode[] = [];

        if (!opNode) {
            // don't have a valid single operation.  just forward it to the local (default) link and let it deal with it
            return localLink.request(operation);
        }

        const opTypeName: 'Query' | 'Mutation' | 'Subscription' = capitalizeOp(opNode.operation);
        const selections = opNode.selectionSet.selections;
        const localResolverRoot = resolvers[opTypeName] || {};

        // Force skipping the remote gateway path for some scenarios that we do not support
        // 1. We bypass all the requests of archivemailbox, sharedmailbox, publicfolder, teamsmailbox, groupmailbox, etc to Gateway. We allow the regular delegation, shared mailbox, explicit logon to the gateway.
        // 2. When fwk-useoutlookgateway-sendArchiveRequest is on, we allow the archive mailbox's request to the gateway.
        let skipRemoteCallForcefully = false;
        const mailboxInfo = operation?.variables?.mailboxInfo;
        if (mailboxInfo) {
            skipRemoteCallForcefully = mailboxInfo.mailboxSmtpAddress != mailboxInfo.userIdentity;

            if (
                isFeatureEnabled('fwk-useoutlookgateway-sendArchiveRequest') &&
                mailboxInfo.type === 'ArchiveMailbox'
            ) {
                skipRemoteCallForcefully = false;
            }

            if (
                mailboxInfo.type != 'GroupMailbox' &&
                mailboxInfo.type != 'PublicMailbox' &&
                mailboxInfo.type != 'TeamsMailbox' &&
                mailboxInfo.type != 'ArchiveMailbox'
            ) {
                skipRemoteCallForcefully = false;
            }
        }

        // see if any of the toplevel selections are missing a local execution resolver OR configured to be remote only
        // (__typename is a meta field that is always resolvable locally)
        const sendRemote = (resolverName: TopLevelResolver): boolean => {
            if (context?.gatewayGraphRequest) {
                // call site is forcing execution to remote gateway
                return true;
            } else if (!localResolverRoot[resolverName]) {
                // don't have a local resolver, send remote if configured
                return enabledForRemote(
                    opTypeName,
                    resolverName,
                    skipRemoteCallForcefully,
                    context?.onlyOfflineResolvers
                );
            } else if (isOfflineEnabled) {
                // MONARCH: see if we're not enabled locally and also enabled remote
                return (
                    !enabledForIdb(
                        opTypeName,
                        resolverName,
                        skipRemoteCallForcefully,
                        context?.skipOfflineResolvers
                    ) &&
                    !enabledForWeb(
                        opTypeName,
                        resolverName,
                        skipRemoteCallForcefully,
                        context?.onlyOfflineResolvers
                    ) &&
                    enabledForRemote(
                        opTypeName,
                        resolverName,
                        skipRemoteCallForcefully,
                        context?.onlyOfflineResolvers
                    )
                );
            } else {
                // WEB: see if we're not enabled for web and also enabled for remote
                return (
                    !enabledForWeb(
                        opTypeName,
                        resolverName,
                        skipRemoteCallForcefully,
                        context?.onlyOfflineResolvers
                    ) &&
                    enabledForRemote(
                        opTypeName,
                        resolverName,
                        skipRemoteCallForcefully,
                        context?.onlyOfflineResolvers
                    )
                );
            }
        };

        const sendLocal = (resolverName: string): boolean => {
            if (context?.gatewayGraphRequest) {
                // call site is forcing execution against the remote gateway
                return false;
            } else if (isOfflineEnabled) {
                // MONARCH: local resolvers
                return (
                    enabledForIdb(
                        opTypeName,
                        resolverName,
                        skipRemoteCallForcefully,
                        context?.skipOfflineResolvers
                    ) ||
                    enabledForWeb(
                        opTypeName,
                        resolverName,
                        skipRemoteCallForcefully,
                        context?.onlyOfflineResolvers
                    )
                );
            } else {
                // WEB: local resolvers
                return enabledForWeb(
                    opTypeName,
                    resolverName,
                    skipRemoteCallForcefully,
                    context?.onlyOfflineResolvers
                );
            }
        };

        selections.forEach(s => {
            if (s.kind === 'Field') {
                var opName: TopLevelResolver = s.name.value as TopLevelResolver;

                if (
                    enabledForRemote(
                        opTypeName,
                        opName,
                        skipRemoteCallForcefully,
                        context?.onlyOfflineResolvers
                    )
                ) {
                    remoteEnabledSelections.push(s);
                }

                if (s.name.value != '__typename' && sendRemote(opName)) {
                    remoteSelections.push(s);
                } else if (sendLocal(opName)) {
                    localSelections.push(s);
                } else {
                    trace.warn(`[localRemoteRouterLinke] ${opTypeName}.${opName} is not enabled`);
                }
            }
        });

        // execute the remote ops
        let remoteOperation = operation;
        let remoteObserver = Observable.of<FetchResult>();
        if (remoteSelections.length > 0) {
            remoteOperation = buildOperation(operation, remoteSelections);
            remoteObserver = remoteLink.request(remoteOperation) || remoteObserver;
        }

        // ...and the local ops (in parallel)
        let localOperation = operation;
        let localObserver = Observable.of<FetchResult>();
        if (localSelections.length > 0) {
            localOperation = buildOperation(operation, localSelections);
            localObserver = localLink.request(localOperation) || localObserver;
        }

        // ...and combine the results into a single result set
        // ...mapping hx fallbacks to remote operations
        let combinedObserver = localObserver
            .concat(remoteObserver)
            .flatMap(result =>
                mapIdbFallbackToRemote(result, operation, remoteLink, remoteEnabledSelections)
            );

        const activeSelections = remoteSelections.length + localSelections.length;
        if (opTypeName === 'Subscription') {
            // ...if it's a gql subscription, the consumer will pull results as they come in.  So, just return the combinedObserver.
            // don't reassign combined observer
        } else if (activeSelections > 0) {
            // merge the local the remote results, preferring any remote result that replaced a local fallback
            combinedObserver = combinedObserver.reduce(objMerge);
        } else {
            // there were no active selections in the operation
            const error = new GraphQLError('there were no active resolvers in the operation');
            combinedObserver = Observable.of<FetchResult>({
                errors: [error],
            });
        }

        // the local operation is a new operation forked off the original.  it is initialized with the original's context,
        // but if we want the original context to reflect any changes made by the forked operation, we need to stamp those on
        // the original context
        return new Observable(observer => {
            const sub = combinedObserver.subscribe({
                next: result => {
                    operation.setContext(localOperation.getContext());
                    observer.next?.(result);
                },
                error: err => {
                    operation.setContext(localOperation.getContext());
                    observer.error?.(err);
                },
                complete: () => {
                    operation.setContext(localOperation.getContext());
                    observer.complete?.();
                },
            });

            return () => {
                sub.unsubscribe();
            };
        });
    });

    return link;
};

/**
 * If local resolvers returns a graphql error with a fallback code, it means the selection needs to fallback to the web implementation
 * If the web implementation has a locally defined resolver, it will consume the fallback error code and execute the operation.
 * But, if the web implementation is a remote operation (i.e, there is no local web resolver for it), then the operation needs to
 * fallback to the remote operation, here
 * @param result the result of the local results, which may include hx fallbacksn
 * @param operation the original operation
 * @param remoteLink the remote link
 * @param remoteSelections remote selections for this operation
 */
function mapIdbFallbackToRemote(
    result: FetchResult,
    operation: Operation,
    remoteLink: ApolloLink,
    remoteSelections: SelectionNode[]
) {
    const nonfallbackErrors: Array<GraphQLError> = [];
    const fallbacks: Array<SelectionNode> = [];

    result.errors?.reduce(
        (accumulator, err) => {
            if (!isIdbFallbackResult(err)) {
                accumulator.nonfallbackErrors.push(err);
            } else {
                const node = findSelectionNode(remoteSelections, err);
                if (node) {
                    accumulator.fallbacks.push(node);
                } else {
                    accumulator.nonfallbackErrors.push(err);
                }
            }

            return accumulator;
        },
        { nonfallbackErrors, fallbacks }
    );

    const originalResult: FetchResult = { ...result, errors: nonfallbackErrors };
    if (nonfallbackErrors.length == 0) {
        delete originalResult.errors;
    }

    const originalObserver = Observable.of(originalResult);

    if (fallbacks.length > 0) {
        return originalObserver.concat(
            remoteLink.request(buildOperation(operation, fallbacks)) || Observable.of<FetchResult>()
        );
    } else {
        return originalObserver;
    }
}

function findSelectionNode(selections: readonly SelectionNode[], err: GraphQLError) {
    // find selection nodes that resulted in hx fallback errors
    let rv = null;
    const path = err.path?.[0];
    if (path) {
        selections.some(s => {
            if (s.kind === 'Field') {
                if (s.alias) {
                    if (s.alias.value === path) {
                        rv = s;
                        return true;
                    }
                } else if (s.name?.value === path) {
                    rv = s;
                    return true;
                }
            }

            return false;
        });
    }

    return rv;
}

function buildOperation(operation: Operation, selections: SelectionNode[]): Operation {
    // we need to make a copy of the gql request specific to the subset of selections for the
    // local or remote endpoint.  the variables/context are assumed to be safe to share between
    // the two operations.
    const copy = {
        ...operation,
        query: { ...operation.query },
        context: operation.getContext(),
    };

    copy.query.definitions = operation.query.definitions.map(d => {
        if (d.kind !== 'OperationDefinition') {
            return d;
        } else {
            return {
                ...d,
                selectionSet: { ...d.selectionSet, selections },
            };
        }
    });

    return createOperation(operation.getContext(), copy);
}

const capitalizeOp = (str: OperationTypeNode) => {
    switch (str) {
        case 'query':
            return 'Query';
        case 'mutation':
            return 'Mutation';
        case 'subscription':
            return 'Subscription';
    }
};
