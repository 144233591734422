import { ThemeConstants } from 'owa-theme-shared';
import { isFeatureEnabled } from 'owa-feature-flags';

// TODO: The currentValidThemes array is going to be removed after we graduate the flight: `fwk-new-modern-themes-v1`
// Tracking it here: https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/256287
const currentValidThemes = [
    ThemeConstants.MODERN_MOUNTAIN_THEME_ID,
    ThemeConstants.MODERN_OFFICE_NEUTRAL_THEME_ID,
    ThemeConstants.OFFICE_NEUTRAL_THEME_ID,
];

const modernImageThemes = [
    ThemeConstants.MODERN_ARCTIC_SOLITUDE_THEME_ID,
    'natureundefined',
    'readyconfetti',
    'magneticmood',
    'futureplus',
    'ribbonrelease',
];

const modernColorThemes = [
    ThemeConstants.MODERN_OFFICE_NEUTRAL_THEME_ID,
    'neworange',
    'red',
    'green',
    'purple',
    'newpink',
];

export const getModernThemes = () =>
    isFeatureEnabled('fwk-new-modern-themes-v1') || isFeatureEnabled('fwk-bleed-through-themes')
        ? [...modernImageThemes, ...modernColorThemes]
        : currentValidThemes;

export const getModernImageThemes = () => modernImageThemes;

export const getModernColorThemes = () => modernColorThemes;
