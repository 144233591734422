import { ThemeConstants } from 'owa-theme-shared';
import { getThemeDataNameWithoutOverrides } from 'owa-theme-shared/lib/getThemeDataNameWithoutOverrides';
import { isBookingsV2 } from '../utils/optionalArgumentHelpers';
import { shouldUseCobranding } from 'owa-theme-common';

export function getThemeDataName(themeId: string, isDarkTheme: boolean): string {
    if (
        themeId === ThemeConstants.BASE_THEME_ID ||
        themeId === ThemeConstants.BASE_OFFICE_THEME_ID
    ) {
        if (isBookingsV2()) {
            themeId = 'base.bookings';
        } else if (isDarkTheme && shouldUseCobranding(themeId)) {
            themeId = ThemeConstants.OFFICE_NEUTRAL_THEME_ID;
        } else if (!isDarkTheme) {
            return `${ThemeConstants.BASE_THEME_ID}`;
        }
    }
    return getThemeDataNameWithoutOverrides(themeId, isDarkTheme);
}
