import initializeOcpsPolicyStore from './initializeOcpsPolicyStore';
import { logUsage } from 'owa-analytics';
import { getItem, setItem, itemExists } from 'owa-local-storage';
import { USER_OCPS_POLICY_STORE } from './constants';
import type { OcpsPolicyStore } from '../store/schema/OcpsPolicyStore';

export function shouldRetryFetchPoliciesAndSetStore() {
    const maxRetries = 3;
    if (itemExists(window, USER_OCPS_POLICY_STORE)) {
        const ocpsPolicyStore: OcpsPolicyStore = JSON.parse(
            getItem(window, USER_OCPS_POLICY_STORE) ?? ''
        );
        let retryCount = ocpsPolicyStore.retryFetchCount ?? 0;

        if (retryCount < maxRetries) {
            try {
                retryCount++;
                ocpsPolicyStore.retryFetchCount = retryCount;
                setItem(window, USER_OCPS_POLICY_STORE, JSON.stringify(ocpsPolicyStore));

                // Fetch the store here
                initializeOcpsPolicyStore();
            } catch (error) {
                logUsage('FetchPoliciesAndSetStore_RetryFailed', {
                    error: error.message,
                    stack: error.stack,
                });
            }
        }
    }
}
