export type { ResourceId } from './ResourceId';
export type { LocalizedString } from './LocalizedString';

import { loc } from './loc';

export default loc;
export { getPromiseForLocalizedString } from './loc';
export { setLocale, onLocaleChanged } from './actions/setLocale';
export { format } from './utilities/format';
export { formatToArray } from './utilities/formatToArray';
export { isStringNullOrWhiteSpace } from './utilities/isStringNullOrWhiteSpace';
export { addLocstringsToStore } from './actions/addLocstringsToStore';

export { getCurrentCulture } from './selectors/getCurrentCulture';
export {
    getCurrentLanguage,
    getCurrentLanguageFromCurrentLocale,
} from './selectors/getCurrentLanguage';
export { isCurrentCultureRightToLeft } from './selectors/isCurrentCultureRightToLeft';
export { isLanguageRightToLeft } from './selectors/isLanguageRightToLeft';
export { isLanguageWithoutWordSpaces } from './selectors/isLanguageWithoutWordSpaces';

export { getLocalizedStringStore } from './store/store';
