import { isFeatureEnabled } from 'owa-feature-flags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { getFromStoreOrLocalStorageOrStartupData } from '../utils/getFromStoreOrLocalStorageOrStartupData';
import { isOptionalExperiencesAllowedByPolicy } from './isOptionalExperiencesAllowedByPolicy';

export function isOptionalExperiencesDisabledForPrivacy(): boolean {
    if (!isFeatureEnabled('settings-privacySettings')) {
        return false;
    }

    if (isConsumer()) {
        return false;
    }

    const optionalExperiencesEnabled = getFromStoreOrLocalStorageOrStartupData(
        'optionalExperiencesEnabled'
    );

    return !optionalExperiencesEnabled || !isOptionalExperiencesAllowedByPolicy();
}
