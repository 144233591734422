import store from '../store/Store';
import type { FeatureName } from '../store/featureDefinitions';
import { errorThatWillCauseAlert, type TraceErrorObject } from 'owa-trace';
import { areFeatureFlagsInitialized } from '../actions/initializeFeatureFlags';
import type { MailboxInfo } from 'owa-client-types';
import isAnonymousFlightingEnabled from './anonymousFlighting';
import getFeatureFlagsIndexerForMailboxInfo from './getFeatureFlagsIndexerForMailboxInfo';
import { getGlobalSettingsAccountIndexer } from 'owa-account-source-list-store';
import getAccountDiagnosticDataForMailboxInfo from 'owa-account-source-list-store/lib/utils/getAccountDiagnosticDataForMailboxInfo';
import { isTryLookupIndexerFuncSet, tryLookupIndexer } from 'owa-client-types/lib/tryLookupIndexer';

export default function isFeatureEnabled(
    feature: FeatureName,
    mailboxInfo?: MailboxInfo,
    dontThrowErrorIfNotInitialized?: boolean
): boolean {
    if (!areFeatureFlagsInitialized(mailboxInfo) && !process.env.JEST_WORKER_ID) {
        if (dontThrowErrorIfNotInitialized || isAnonymousFlightingEnabled()) {
            return false;
        }

        // We are adding this clue to see if the flags were not
        // initialized for a specific mailbox info.
        const accountDiagnostic = getAccountDiagnosticDataForMailboxInfo(mailboxInfo);
        const globalSettingsIndexer = getGlobalSettingsAccountIndexer(
            /*throwIfNotInitialized*/ false
        );
        const tryIndexer = !!mailboxInfo ? tryLookupIndexer(mailboxInfo) : undefined;
        const featureFlagsIndexer = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);

        const info = {
            feature,
            hasMailboxInfo: !!mailboxInfo,
            mailboxType: mailboxInfo?.type,
            hasUserId: !!mailboxInfo?.userIdentity,
            hasSmtp: !!mailboxInfo?.mailboxSmtpAddress,
            rank: mailboxInfo?.mailboxRank,
            isAnonymous: mailboxInfo?.isAnonymous,
            isRemoved: mailboxInfo?.isRemoved,
            hasIndexer: featureFlagsIndexer !== '',
            isGlobalInitalized: areFeatureFlagsInitialized(),
            isTryLookupSet: isTryLookupIndexerFuncSet(),
            hasGlobalSettingsIndexer: !!globalSettingsIndexer,
            hasTryIndexer: !!tryIndexer,
            isTryIndexerGSA: tryIndexer === globalSettingsIndexer,
            isTryIndexderFF: tryIndexer === featureFlagsIndexer,
            isTryIndexderUID: tryIndexer === mailboxInfo?.userIdentity,
            ...accountDiagnostic,
        };
        const error: TraceErrorObject = new Error(
            'Attempted to read feature before feature flags were initialized'
        );
        error.diagnosticInfo = JSON.stringify(info);
        errorThatWillCauseAlert(error);
    }

    const result = store(mailboxInfo).featureFlags.get(feature.toLowerCase()) || false;

    return result;
}
