import { isFeatureEnabled } from 'owa-feature-flags';
import { ThemeConstants } from 'owa-theme-shared';
import { getModernColorThemes } from '../utils/getModernThemes';
import { getThemeIdFromParameter } from '../utils/optionalArgumentHelpers';

export default function getIsCurrentThemeModernColor(themeId?: string) {
    themeId = getThemeIdFromParameter(themeId);

    if (
        isFeatureEnabled('fwk-new-modern-themes-v1') ||
        isFeatureEnabled('fwk-bleed-through-themes')
    ) {
        return getModernColorThemes().includes(themeId);
    }

    return themeId === ThemeConstants.MODERN_OFFICE_NEUTRAL_THEME_ID;
}
