export enum DiagnosticDataLevel {
    None,
    RequiredOnly,
    RequiredAndOptional,
    Unset,
}

export interface PrivacySettings {
    diagnosticDataLevel: DiagnosticDataLevel;
    downloadContentEnabled: boolean;
    analyzeContentEnabled: boolean;
    optionalExperiencesEnabled: boolean;
    optionalConnectedExperiencesNoticeVersion: number;
    requiredDiagnosticDataNoticeVersion: number;
    optionalDiagnosticDataConsentVersion: number;
    connectedExperiencesNoticeVersion: number;
}

export interface PrivacySettingsStore extends PrivacySettings {
    isInitialized: boolean;
}
