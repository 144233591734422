import { FreezeDryItemTypes, EventTypes } from './fdTypes';
import type { FreezeDryItem } from './fdTypes';

interface FdEventManager {
    listeners: {
        [key in EventTypes]: {
            [itemType in FreezeDryItemTypes]: ((item?: FreezeDryItem) => void)[];
        };
    };
    emitFdEvent: (
        eventType: EventTypes,
        itemType: FreezeDryItemTypes,
        item?: FreezeDryItem
    ) => void;
    addFdListener: (
        eventType: EventTypes,
        itemType: FreezeDryItemTypes,
        listener: (item?: FreezeDryItem) => void
    ) => void;
    removeFdListener: (
        eventType: EventTypes,
        itemType: FreezeDryItemTypes,
        listener: (item?: FreezeDryItem) => void
    ) => void;
}

export function addFdListener(
    eventType: EventTypes,
    itemType: FreezeDryItemTypes,
    listener: (item?: FreezeDryItem) => void
) {
    fdEventManager.addFdListener(eventType, itemType, listener);
}

export const fdEventManager: FdEventManager = {
    listeners: {
        [EventTypes.Restore]: {
            [FreezeDryItemTypes.MainWindow]: [],
            [FreezeDryItemTypes.MailItems]: [],
            [FreezeDryItemTypes.MailComposeItems]: [],
            [FreezeDryItemTypes.CalendarItems]: [],
            [FreezeDryItemTypes.CalendarComposeItems]: [],
        },
        [EventTypes.Persist]: {
            [FreezeDryItemTypes.MainWindow]: [],
            [FreezeDryItemTypes.MailItems]: [],
            [FreezeDryItemTypes.MailComposeItems]: [],
            [FreezeDryItemTypes.CalendarItems]: [],
            [FreezeDryItemTypes.CalendarComposeItems]: [],
        },
    },
    emitFdEvent(eventType: EventTypes, itemType: FreezeDryItemTypes, item?: FreezeDryItem) {
        for (const listener of fdEventManager.listeners[eventType][itemType]) {
            listener(item);
        }
    },
    addFdListener(
        eventType: EventTypes,
        itemType: FreezeDryItemTypes,
        listener: (item?: FreezeDryItem) => void
    ) {
        fdEventManager.listeners[eventType][itemType].push(listener);
    },
    removeFdListener(
        eventType: EventTypes,
        itemType: FreezeDryItemTypes,
        listener: (item?: FreezeDryItem) => void
    ) {
        const index = fdEventManager.listeners[eventType][itemType].indexOf(listener);
        if (index !== -1) {
            fdEventManager.listeners[eventType][itemType].splice(index, 1);
        }
    },
};
