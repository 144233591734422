import { getCurrentThemeId } from 'owa-theme';
import { getMappedTheme } from 'owa-theme/lib/utils/getMappedTheme';
import { ThemeConstants } from 'owa-theme-shared';
import generateSuitePaletteFromFabricPalette from './generateSuitePaletteFromFabricPalette';
import React from 'react';
import {
    BASE_LIGHT_THEME_PALETTE,
    OFFICENEUTRAL_LIGHT_THEME_PALETTE_DIFF_BASE,
} from 'owa-theme-common';

export default function useOwaBrandTheme() {
    /**
     * When the UserThemeId is set to 'base', the Shell uses an AppBrandTheme palette, if provided.
     * This will be taken from the OWA theme definition with Id 'base'.
     * OfficeNeutral theme Id will be used as the AppBrandTheme if OfficeNeutral is selected
     */
    const themeId = getCurrentThemeId();
    const mappedThemeId = React.useMemo(() => getMappedTheme(themeId).mappedToThemeId, [themeId]);

    return React.useMemo(() => {
        return mappedThemeId === ThemeConstants.OFFICE_NEUTRAL_THEME_ID
            ? generateSuitePaletteFromFabricPalette({
                  ...BASE_LIGHT_THEME_PALETTE,
                  ...OFFICENEUTRAL_LIGHT_THEME_PALETTE_DIFF_BASE,
              })
            : generateSuitePaletteFromFabricPalette(BASE_LIGHT_THEME_PALETTE);
    }, [mappedThemeId]);
}
