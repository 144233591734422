import type { Getter } from 'owa-bundling-light';
import { LazyModule, LazyImport } from 'owa-bundling-light';
import type { ResolverFn } from 'owa-graph-schema';
import { PerformanceDatapoint } from 'owa-analytics';
import { GraphQLError } from 'graphql';
import { DatapointStatus } from 'owa-analytics-types';

const isPerformanceSupported = self?.performance;

export function createLazyResolver<TModule, TResolver extends ResolverFn<any, any, any, any>>(
    resolverName: string,
    importCbOrlazyModule: (() => Promise<TModule>) | LazyModule<TModule>,
    getter: Getter<TResolver, TModule>
): TResolver {
    const lazyModule =
        typeof importCbOrlazyModule === 'function'
            ? new LazyModule(importCbOrlazyModule)
            : importCbOrlazyModule;
    const lazyImport = new LazyImport(lazyModule, getter);

    const lazyResolver = (parent: any, args: any, context: any, info: any) => {
        const contextDatapoint = context.datapoint;
        const start = isPerformanceSupported && self.performance.now();

        if (contextDatapoint && start) {
            contextDatapoint.addCheckmark('resolver_start', start);
        }

        return lazyImport.import().then(async resolver => {
            const perfDatapoint = new PerformanceDatapoint('resolver_perf', {
                ring: 'Dogfood',
            });
            perfDatapoint.addCustomData({ resolverName });

            let result;
            try {
                result = await resolver(parent, args, context, info, perfDatapoint);
            } catch (error) {
                // Turn exceptions thrown into GraphQL errors with extension property on them
                const graphQLError = new GraphQLError(
                    error.message,
                    null, // nodes
                    null, // source
                    null, // positions
                    error.path, // path
                    error, // originalError
                    {
                        ...error,
                    } // extensions
                );
                graphQLError.stack = error.stack;
                result = graphQLError;
            }

            if (result instanceof GraphQLError) {
                if (contextDatapoint && start) {
                    contextDatapoint.addCheckmark('resolver_error', self.performance.now() - start);
                }
                perfDatapoint.endWithError(DatapointStatus.RequestNotComplete, result);
            } else {
                if (contextDatapoint && start) {
                    contextDatapoint.addCheckmark('resolver_end', self.performance.now() - start);
                }
                perfDatapoint.end();
            }
            return result;
        });
    };

    return lazyResolver as TResolver;
}
