import { isFeatureEnabled } from 'owa-feature-flags';
import type { PrivacySettings } from '../store/schema/PrivacySettings';
import { DiagnosticDataLevel } from '../store/schema/PrivacySettings';
import { logUsageFromAccounts } from 'owa-account-analytics';
import {
    OPTIONAL_CONNECTED_EXPERIENCES_NOTICE_LATEST_VERSION,
    REQUIRED_DIAGNOSTICS_DATA_NOTICE_LATEST_VERSION,
    DIAGNOSTIC_DATA_CONSENT_LATEST_VERSION,
    CONNECTED_EXPERIENCES_NOTICE_LATEST_VERSION,
} from './constants';

export type ServerDiagnosticDataLevel = typeof ServerDiagnosticDataValues[number];
const ServerDiagnosticDataValues = [
    'None',
    'RequiredOnly',
    'RequiredAndOptional',
    'Unset',
] as const;

type ServerPrivacySettings = {
    enableContentAnalyzingExperiences: boolean;
    enableContentDownloadingExperiences: boolean;
    enableDiagnosticData: ServerDiagnosticDataLevel;
    enableOptionalConnectedExperiences: boolean;
    optionalConnectedExperiencesNoticeVersion: number | undefined;
    requiredDiagnosticDataNoticeVersion: number | undefined;
    optionalDiagnosticDataConsentVersion: number | undefined;
    connectedExperiencesNoticeVersion: number | undefined;
};

const isServerPrivacySettings = (value: any): value is ServerPrivacySettings =>
    value &&
    isBoolean(value.enableContentAnalyzingExperiences) &&
    isBoolean(value.enableContentDownloadingExperiences) &&
    ServerDiagnosticDataValues.includes(value.enableDiagnosticData) &&
    isBoolean(value.enableOptionalConnectedExperiences) &&
    isValidNoticeVersion(value.optionalConnectedExperiencesNoticeVersion) &&
    isValidNoticeVersion(value.requiredDiagnosticDataNoticeVersion) &&
    isValidNoticeVersion(value.optionalDiagnosticDataConsentVersion) &&
    isValidNoticeVersion(value.connectedExperiencesNoticeVersion);

// When the flight is disabled, the server returns undefined for all the notice versions.
const isValidNoticeVersion = (value: any) =>
    isFeatureEnabled('settings-privacySettingsRoaming') ? isNumber(value) : true;

const isNumber = (value: any) => typeof value === 'number';
const isBoolean = (value: any) => typeof value === 'boolean';

const serverToClientDiagnosticsDataLevel = (
    serverDiagnostics: string | undefined
): DiagnosticDataLevel => {
    switch (serverDiagnostics) {
        case 'None':
            return DiagnosticDataLevel.None;
        case 'RequiredOnly':
            return DiagnosticDataLevel.RequiredOnly;
        case 'RequiredAndOptional':
            return DiagnosticDataLevel.RequiredAndOptional;
        case 'Unset':
        default:
            return DiagnosticDataLevel.Unset;
    }
};

function objectToPropertyTypes(obj: any) {
    if (typeof obj !== 'object') {
        return typeof obj;
    }

    if (obj === null) {
        return 'null';
    }

    if (Object.keys(obj).length === 0) {
        return {};
    }

    const propertyTypes: {
        [key: string]: any;
    } = {};

    Object.keys(obj).forEach(key => {
        propertyTypes[key] = objectToPropertyTypes(obj[key]);
    });

    return propertyTypes;
}

export const parseServerValue = (
    serverResponse: any,
    callerForLogging?: string
): PrivacySettings => {
    const serverSettings = serverResponse?.settings;

    if (!isServerPrivacySettings(serverSettings)) {
        let objectPropertyTypes = '';

        try {
            objectPropertyTypes = JSON.stringify(objectToPropertyTypes(serverSettings));
        } catch (e) {
            objectPropertyTypes = 'Object_with_cycles';
        }

        logUsageFromAccounts('NonParsablePrivacySettingsObject', {
            serverSettings: objectPropertyTypes,
            callerForLogging,
        });
    }

    return {
        analyzeContentEnabled: valueOrFallbackToTrue(
            serverSettings?.enableContentAnalyzingExperiences
        ),
        downloadContentEnabled: valueOrFallbackToTrue(
            serverSettings?.enableContentDownloadingExperiences
        ),
        diagnosticDataLevel: serverToClientDiagnosticsDataLevel(
            serverSettings?.enableDiagnosticData
        ),
        optionalExperiencesEnabled: valueOrFallbackToTrue(
            serverSettings?.enableOptionalConnectedExperiences
        ),
        optionalConnectedExperiencesNoticeVersion: valueOrFallback(
            serverSettings?.optionalConnectedExperiencesNoticeVersion,
            OPTIONAL_CONNECTED_EXPERIENCES_NOTICE_LATEST_VERSION
        ),
        requiredDiagnosticDataNoticeVersion: valueOrFallback(
            serverSettings?.requiredDiagnosticDataNoticeVersion,
            REQUIRED_DIAGNOSTICS_DATA_NOTICE_LATEST_VERSION
        ),
        optionalDiagnosticDataConsentVersion: valueOrFallback(
            serverSettings?.optionalDiagnosticDataConsentVersion,
            DIAGNOSTIC_DATA_CONSENT_LATEST_VERSION
        ),
        connectedExperiencesNoticeVersion: valueOrFallback(
            serverSettings?.connectedExperiencesNoticeVersion,
            CONNECTED_EXPERIENCES_NOTICE_LATEST_VERSION
        ),
    };
};

const valueOrFallbackToTrue = (value: any): boolean => {
    return isBoolean(value) ? value : true;
};

const valueOrFallback = (value: any, fallback: number) => {
    // When the settings-privacySettingsRoaming flight is disabled,
    // the server returns undefined for all the notice versions.
    // but in that case we want to default to show the privacy notices instead of hiding them.
    const fallbackValue = isFeatureEnabled('settings-privacySettingsRoaming') ? fallback : 0;

    return isNumber(value) ? value : fallbackValue;
};
