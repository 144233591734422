import {
    getCoprincipalAccounts,
    getGlobalSettingsAccount,
    isAccountSourceListStoreInitialized,
} from 'owa-account-source-list-store';
import { trace } from 'owa-trace';

/**
 * REFLOW TECHNICAL DEBT
 *
 * In the Respond approach the sourceId should be passed into components. To support the Reflow approach the
 * client-web code continues to use the userIdentity to identify mailboxes. This helper method allows for
 * getting a sourceId from the source list based on that user identity.
 *
 * Long term, meaning when all code has moved to the Respond approach, this method will be removed.
 *
 * @param userIdentity identifies the mailbox, when not specified the selected mailbox is assumed
 * @param dontThrowWhenNotFound can be set to true if the methods should not throw when the identity is not found
 * @returns SourceId for source associated with the supplied user identity, or undefined if not source was found
 */
export default function getSourceIdForUserIdentity(userIdentity?: string | null): string {
    const sources = getCoprincipalAccounts();
    if (userIdentity) {
        const filtered = sources.filter(info => info.mailboxInfo.userIdentity === userIdentity);

        if (filtered.length === 0) {
            // log that we did not find mailbox information for the user identity, along with the number
            // of sources in the store. This will make it possible to determine if the store is not initialized
            // or if we did not get a user identity that matched the sourceId.
            trace.warn(
                `[acct-list] SourceIdNotFoundForUserIdentity:${sources.length} sources in store`
            );
            return '';
        }

        return filtered[0].sourceId;
    }

    // No user identity, use the global settings account which must be set
    if (!isAccountSourceListStoreInitialized()) {
        trace.warn(
            `[acct-list] SourceIdNotFoundStoreNotInitialized:${sources.length} sources in store`
        );

        return '';
    }

    return getGlobalSettingsAccount().sourceId;
}
