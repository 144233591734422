import type { Operation } from '@apollo/client';
import type { Resolvers } from 'owa-graph-schema';
import executeGraphLink from '../links/executeGraphLink';
import { getMailboxInfoFromOperationStrict } from 'owa-request-options';
import { isBootFeatureEnabled } from 'owa-metatags';

export function canUsePstGqlEndpoint() {
    return isBootFeatureEnabled('acct-pstFileInfra');
}

export function isPstFileOperation(operation: Operation) {
    const mailboxInfo = getMailboxInfoFromOperationStrict(operation);
    return mailboxInfo?.type === 'PstFile';
}

export function pstServerLink(context: Record<string, any>, pstResolvers: Resolvers) {
    return executeGraphLink(pstResolvers, context, undefined /* dataSource */);
}
