import React, { useState, useEffect } from 'react';
import { NewOutlookPromoDialogInner } from '../lazyFunctions';
import { isWin10, isWin11 } from 'owa-user-agent';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { NEW_OUTLOOK_PROMO_DIALOG_LIGHTNINGID } from '../constants';

export const NewOutlookPromoDialog = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const showDialog = async () => {
            const isOsSupported = isWin10() || (await isWin11());
            if (!isOpen && isConsumer() && isOsSupported) {
                setIsOpen(true);
            }
        };
        showDialog();
    }, []);

    return isOpen && <NewOutlookPromoDialogInner id={NEW_OUTLOOK_PROMO_DIALOG_LIGHTNINGID} />;
};
