import React from 'react';
import { RibbonTopBar } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { appIcons, getAppStrings, getAppColors } from 'owa-acui-theme';
import { getCoordinatesMap, onTabCoordinatesCalculated } from 'owa-layout';
import type {
    RibbonTabDefinitionBase,
    RibbonTabDefinitionSLR,
    RibbonTabDefinitionMLR,
    CoordinateMap,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { topBar, topBarContainer } from './CommandRibbonTopBar.scss';
import { observer } from 'owa-mobx-react';
import { isFeatureEnabled } from 'owa-feature-flags';

export const CommandRibbonTopBar = observer(function CommandRibbonTopBar({
    extraControlsBeforeTabs,
    extraControlsAfterTabs,
    onTabHeaderClick,
    ribbonTabs,
    selectedTab,
}: {
    extraControlsAfterTabs?: React.ReactNode;
    extraControlsBeforeTabs?: React.ReactNode;
    onTabHeaderClick: (tabId: string) => void;
    ribbonTabs: RibbonTabDefinitionBase[];
    selectedTab: RibbonTabDefinitionSLR | RibbonTabDefinitionMLR;
}) {
    const selectedTabId = selectedTab.id;
    const onRibbonTabCoordinatesCalculated = React.useCallback(
        (coordinates: CoordinateMap) => onTabCoordinatesCalculated(selectedTabId, coordinates),
        [selectedTabId]
    );

    const useCoordinateCache = isFeatureEnabled('fwk-coor-cache-2');

    return (
        <div id="TopBar" className={topBar}>
            {extraControlsBeforeTabs}
            <div className={topBarContainer}>
                <RibbonTopBar
                    appColors={getAppColors()}
                    appStrings={getAppStrings()}
                    // `onTabCoordinatesCalculated` and `tabCoordinates` props are only relevant
                    //  when `displayDynamicTabHeaderIndicator` is `true`
                    displayDynamicTabHeaderIndicator={true}
                    onTabCoordinatesCalculated={
                        useCoordinateCache ? onRibbonTabCoordinatesCalculated : undefined
                    }
                    onTabHeaderClick={onTabHeaderClick}
                    overflowTabHeaderMenuLauncherIcon={appIcons.tabListOverflowLauncher}
                    ribbonTabs={ribbonTabs}
                    selectedTabId={selectedTabId}
                    tabCoordinates={
                        useCoordinateCache ? getCoordinatesMap(selectedTabId) : undefined
                    }
                    isTabIndicatorRounded={true}
                />
            </div>
            {extraControlsAfterTabs}
        </div>
    );
},
'CommandRibbonTopBar');
