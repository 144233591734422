import type React from 'react';
import { useRef } from 'react';
import { useContextMenuEvent } from 'owa-react-hooks/lib/useContextMenuEvent';
import type { HTMLTags } from '../schema/HTMLTags';
import type { AppContextMenuItem } from '../schema/AppContextMenuItem';
import type { handleNativeContextMenu as HandleNativeContextMenu } from '../utils/handleNativeContextMenu';
import { isFeatureEnabled } from 'owa-feature-flags';
import { lazyHandleNativeContextMenu } from '../lazyFunctions';
import {
    type ContextMenuRequestedArguments,
    getEnhancedHostExtendedHost,
} from 'owa-config/lib/enhancedHost';

export function useAppContextMenu(
    containerReference: React.RefObject<any>,
    customMenuItemsByTag?: Map<HTMLTags, AppContextMenuItem[]>
): void {
    const contextMenuEvent = useRef<MouseEvent | null>(null);
    let handleNativeContextMenu: typeof HandleNativeContextMenu | undefined = undefined;

    useContextMenuEvent((e: MouseEvent) => {
        contextMenuEvent.current = e;
    }, containerReference);

    if (isFeatureEnabled('fwk-contextMenu')) {
        const extendedHost = getEnhancedHostExtendedHost();
        if (extendedHost) {
            const loadPromise = lazyHandleNativeContextMenu
                .import()
                .then(fn => (handleNativeContextMenu = fn));
            extendedHost.onContextMenuRequested(async (args: ContextMenuRequestedArguments) => {
                await loadPromise;
                handleNativeContextMenu?.(args, contextMenuEvent, customMenuItemsByTag);
            });
        }
    }
}
