import { LazyModule, registerLazyOrchestrator, LazyAction } from 'owa-bundling';
import {
    onAppBarTrigger,
    onOpenAboutDialog,
    onUninstallMetaOsApp,
    onFlyoutButtonHover,
} from 'owa-nova-actions';
import { Module, onModuleClick } from 'owa-workloads';
import onRefreshAppBarData from 'owa-appbar-state/lib/actions/onRefreshAppBarData';
import onSwitchMailboxM365Acquisitions from 'owa-appbar-state/lib/actions/onSwitchMailboxM365Acquisitions';
import { lazyInitializeAppBarPinnedAppsUserSetting } from 'owa-appbar-pinnedapps';
import { lazyGovern, GovernPriority } from 'owa-tti';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getCurrentModule } from 'owa-app-module-store';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { isAppBarEnabledForModule } from './utils/isAppBarEnabledForModule';

//import for orchestrators used in Appbar ContextMenu/SecondaryActions for Monarch (eg: 'onPinShortcutOrchestrator')
import 'native-controls';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "AppBar" */ './lazyIndex'), {
    name: 'AppBar',
});

registerLazyOrchestrator(onAppBarTrigger, lazyModule, m => m.appBarEventOrchestrator);
registerLazyOrchestrator(onModuleClick, lazyModule, m => m.onModuleClickOrchestrator);
registerLazyOrchestrator(onOpenAboutDialog, lazyModule, m => m.onOpenAboutDialogOrchestrator);
registerLazyOrchestrator(onRefreshAppBarData, lazyModule, m => m.onRefreshAppBarDataOrchestrator);
registerLazyOrchestrator(onUninstallMetaOsApp, lazyModule, m => m.onUninstallMetaOsAppOrchestrator);
registerLazyOrchestrator(
    onSwitchMailboxM365Acquisitions,
    lazyModule,
    m => m.onSwitchMailboxM365AcquisitionsOrchestrator
);
registerLazyOrchestrator(onFlyoutButtonHover, lazyModule, m => m.onFlyoutButtonHoverOrchestrator);
const lazyInitializeAcquisitionsData = new LazyAction(
    lazyModule,
    m => m.initializeAcquisitionsData
);
export { default as AppBar } from './components/AppBar';

// Run remote apps initialization if user has not hovered on flyout yet
lazyGovern.importAndExecute({
    task: async () => {
        // condition is included as part of task, since otherwise it will be evaluated before underlying data (e.g. app module store) is initialized
        // and return the wrong result
        if (isAppBarEnabled()) {
            const mailboxInfo = getModuleContextMailboxInfo();
            await lazyInitializeAcquisitionsData.importAndExecute(mailboxInfo);
            return lazyInitializeAppBarPinnedAppsUserSetting.importAndExecute(mailboxInfo);
        }
    },
    priority: GovernPriority.Idle,
});

export function isAppBarEnabled() {
    // we will default to the mail module
    const currentModule = getCurrentModule() || Module.Mail;
    return !isHostAppFeatureEnabled('hideAppBar') && isAppBarEnabledForModule(currentModule);
}
