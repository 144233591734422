import type { MailboxInfo, MailboxType } from 'owa-client-types';
import type { AccountState, ExternalData } from 'owa-account-source-list-store';
import type { AccountSourceType } from 'owa-account-source-list-types';
import type { CustomDataMap } from 'owa-analytics-types';

/**
 * To support multiple platform we need to be able to abstract the underlying source, whether it is
 * a OWS accessed mailbox or HxAccount, from the code above the GraphQL resolvers. For this purpose
 * account data is represented as a sourceId and a displayName.
 */
export interface SourceIdAndDisplayName {
    id: string;
    displayName: string;
}

// Defines the type uses to store information for hte source list
export interface LegacySourceInformation extends SourceIdAndDisplayName {
    stableId: string;
    properties: Map<string, string>;
    mailboxInfo?: MailboxInfo;
    mailboxType?: string;
}

export interface SourceContracts {
    supportsCalendar: boolean;
    supportsContacts: boolean;
    supportsMail: boolean;
    supportsSettings: boolean;
}

export interface SourceMailbox {
    type: MailboxType;
    name: string;
    displayName: string;
    emailAddress: string;
    loginAddress: string;
    userIdentity: string;
    routingHint: string;
    alternateId: string;
    accountUniqueId: string;
    imageUrl: string;
    profileUrl: string;
}

export enum SourceType {
    Mailbox = 'MAILBOX',
    PstFile = 'PSTFILE',
}

export enum SourceAuthType {
    Microsoft365 = 'MICROSOFT365',
    OutlookDotCom = 'OUTLOOKDOTCOM',
    Google = 'GOOGLE',
    Yahoo = 'YAHOO',
    ICloud = 'ICLOUD',
    IMAP = 'IMAP',
    POP3 = 'POP3',
    None = 'NONE',
}

export interface HostSourceInformation {
    sourceType: SourceType;
    authProviderType: SourceAuthType;
    displayName: string;
    contracts: SourceContracts;
    externalData: ExternalData;
    persistenceId: string;
    anchorMailbox?: string;
    aliases?: string[];
    mailbox?: SourceMailbox;
    accountState?: AccountState;
    isGlobalSettingsAccount?: boolean;
    userIdentity: string;
}

export interface SourceInformation extends HostSourceInformation {
    sourceId: string;
}

export enum CreateAccountErrors {
    UnknownError = 'UNKNOWN',
    GetAuthTokenFailed = 'INVALIDCREDENTIALS',
    CouldNotConnect = 'CONNECTFAILED',
    CouldNotConnectData = 'CONNECTFAILEDDATA',
    DuplicateAccount = 'DUPLICATE',
    DuplicateAccountInfra = 'DUPLICATEINFRA',
    DuplicateAccountStorage = 'DUPLICATESTORAGE',
    ProviderNotSupported = 'NOTSUPPORTED',
    ProviderNotSupported2 = 'NOTSUPPORTEDAAD',
    MissingEmailInformation = 'MISSINGEMAILINFO',
    DuplicateUserIdentity = 'DUPLICATEIDENTITY',
    SharedAndDelegatesNotAllowed = 'NONUSERMAILBOX',
    UserAccountNotAllowed = 'ACCOUNTNOTALLOWED',
    InvalidEmailAddress = 'INVALIDEMAILADDRESS',
    AutoDetectFailure = 'AUTODETECTFAILURE',
    ExceedsAccountsLimit = 'EXCEEDSACCCOUNTSLIMIT',
    Cancel = 'CANCEL',
    EASIDAccount = 'EASIDACCOUNT',
    AuthHealthCheckFailed = 'AUTHHEALTHCHECKFAILED',
    MissingProtocolInformation = 'MISSINGPROTOCOLINFO',
    EnterpriseNoOfficeProPlus = 'NOOFFICEPROPLUS',
    AutoDetectFailureImport = 'AUTODETECTFAILUREIMP',
    ProviderNotSupportedPop3 = 'NOTSUPPORTEDPOP3',
    AddLocalDataAccountFailed = 'ADDLOCALDATAACCOUNTFAILED',
    MountPstFileFailed = 'MOUNTPSTFILEFAILED',
}

/**
 * Copy from TokenResponseType in Authentication PIE Schema
 */
export enum AuthResult {
    Invalid = 'INVALID',
    Success = 'SUCCESS',
    Cancel = 'CANCEL',
    InteractionRequired = 'INTERACTIONREQUIRED',
    TemporarilyUnavailable = 'TEMPORARILYUNAVAILABLE',
    ProviderFailure = 'PROVIDERFAILURE', //deprecated, remove after updating dependencies, e.g. telemetry
    InvalidUiContext = 'INVALIDUICONTEXT', //deprecated, remove after updating dependencies, e.g. telemetry
    ShuttingDown = 'SHUTTINGDOWN',
    InternalFailure = 'INTERNALFAILURE', //deprecated, remove after updating dependencies, e.g. telemetry
    ExtendedError = 'EXTENDEDERROR',
}

/**
 * Defines the result that will be returned by create accounts calls
 */
export interface CreateAccountResult {
    wasSuccessful: boolean;
    error?: ErrorInfo;
    sourceId?: string;
    smtpAddress?: string;
    sourceType?: AccountSourceType;
    steps?: CustomDataMap;
    isEasidOverrideEmailAddress?: boolean;
}

export interface ErrorInfo {
    error: CreateAccountErrors;
    authError?: AuthResult;
    errorSubstatus?: string;
    /* This descriptive error message will almost certainly containe PII */
    errorString?: string;
    connectOwaError?: string;
    connectRawErrorBodyMayHavePii?: string;
    miscData?: any;
}

export enum RemoveAccountErrors {
    AccountNotFound = 'NOT_FOUND',
    RedirectFailed = 'REDIRECT_FAILED',
}

/**
 * Defines the result that will be returned by remove accounts calls
 */
export interface RemoveAccountResult {
    wasSuccessful: boolean;
    error?: RemoveAccountErrors;
}
