import React from 'react';
import { format, memoizeFunction } from '@fluentui/react/lib/Utilities';
import { isMac } from '@1js/acui-common';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { KeytipLayer } from '@fluentui/react/lib/KeytipLayer';
import { KeyCodes } from '@fluentui/react';
import type { IKeytipTransitionKey } from '@fluentui/react';
import Strings from './RibbonKeytipLayer.locstring.json';
import { observer } from 'owa-mobx-react';
import loc from 'owa-localize';

// Keytip start sequences
const monarchKeytipsKeys: string[] = ['Alt', 'F10'];

const getKeytipContent = memoizeFunction((): string => {
    const keytipStartSequence: string = 'Alt {0}';
    const keytipStartKeyWindows: string = 'Windows';
    const keytipStartKeyMac: string = 'Command';
    const keytipAltMetaStartSequence: string = format(
        keytipStartSequence,
        isMac() ? keytipStartKeyMac : keytipStartKeyWindows
    );

    return isHostAppFeatureEnabled('altForKeytips')
        ? loc(Strings.keytipAriaDescription, keytipAltMetaStartSequence, monarchKeytipsKeys[1])
        : keytipAltMetaStartSequence;
});

const getKeytipEntrySequences = memoizeFunction((): IKeytipTransitionKey[] => {
    if (isHostAppFeatureEnabled('altForKeytips')) {
        /** Uncomment the following when Alt is enabled in Monarch for keytips
        const monarchKeytipsTransitionKeys: IKeytipTransitionKey[] = [];

        monarchKeytipsKeys.forEach(keyString => {
            monarchKeytipsTransitionKeys.push({ key: keyString });
        });

        return monarchKeytipsTransitionKeys;
        */

        return [{ key: 'Meta', modifierKeys: [KeyCodes.alt] }, { key: monarchKeytipsKeys[1] }];
    }

    return [{ key: 'Meta', modifierKeys: [KeyCodes.alt] }];
});

const RibbonKeytipLayer = observer(function RibbonKeytipLayer() {
    return (
        <KeytipLayer
            content={getKeytipContent()}
            keytipExitSequences={getKeytipEntrySequences()}
            keytipStartSequences={getKeytipEntrySequences()}
        />
    );
}, 'RibbonKeytipLayer');

export { RibbonKeytipLayer };
