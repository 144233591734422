import type MsalTokenCallback from '../schema/MsalTokenCallback';
import type { AccountInfo, PublicClientApplication } from '@azure/msal-browser-1p';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { getScopeMsal } from './getScopeMsal';
import type { MailboxInfo } from 'owa-client-types';
import { isAddinMultiAccountEnabled } from 'owa-feature-flags';
import { getAccountScopeUserSettings } from 'owa-session-store';

export default function acquireTokenMsal(
    msalInstance: PublicClientApplication,
    resource: string,
    authChallenge: string,
    allowConsentPrompt: boolean,
    userAccount: AccountInfo,
    userPrincipalName: string | undefined,
    getAccessTokenCallback: MsalTokenCallback,
    mailboxInfo: MailboxInfo
): void {
    const scopes = [getScopeMsal(resource)];
    const domain_hint: string = userPrincipalName ? '' : 'organizations';

    const tokenRequest = {
        scopes,
        account: userAccount,
        loginHint: userPrincipalName,
        extraQueryParameters: { domain_hint },
        claims: authChallenge,
    };

    if (authChallenge) {
        msalInstance
            .acquireTokenPopup(tokenRequest)
            .then(function (accessTokenResponse) {
                const accessToken = accessTokenResponse.accessToken;
                // Success. Using callback to return token.
                getAccessTokenCallback(null, accessToken);
            })
            .catch(function (error) {
                // Error. Using callback to return error.
                getAccessTokenCallback(error, null /* token */);
            });
    } else {
        msalInstance
            .acquireTokenSilent(tokenRequest)
            .then(function (tokenResponse) {
                const accessToken = tokenResponse.accessToken;
                // Success. Using callback to return token.
                getAccessTokenCallback(null, accessToken);
            })
            .catch(function (error) {
                if (shouldTrySsoSilent(error)) {
                    acquireTokenSsoSilent(
                        msalInstance,
                        scopes,
                        authChallenge,
                        allowConsentPrompt,
                        userAccount,
                        userPrincipalName,
                        domain_hint,
                        getAccessTokenCallback,
                        mailboxInfo
                    );
                } else if (shouldTryAcquireTokenPopup(error, allowConsentPrompt)) {
                    acquireTokenWithPopup(
                        msalInstance,
                        scopes,
                        authChallenge,
                        userAccount,
                        userPrincipalName,
                        domain_hint,
                        getAccessTokenCallback
                    );
                } else {
                    // Error. Using callback to return error.
                    getAccessTokenCallback(error, null /* token */);
                }
            });
    }
}

function shouldTrySsoSilent(error: any): boolean {
    if (!error) {
        return false;
    }
    if (error.errorCode === 'no_tokens_found') {
        return true;
    }
    return false;
}

function acquireTokenSsoSilent(
    msalInstance: PublicClientApplication,
    scopes: string[],
    authChallenge: string,
    allowConsentPrompt: boolean,
    userAccount: AccountInfo,
    userPrincipalName: string | undefined,
    domain_hint: string,
    getAccessTokenCallback: MsalTokenCallback,
    mailboxInfo: MailboxInfo
) {
    let sessionSettings;
    if (isAddinMultiAccountEnabled()) {
        sessionSettings = getAccountScopeUserSettings(mailboxInfo).SessionSettings;
    } else {
        sessionSettings = getUserConfiguration().SessionSettings;
    }
    const aadSessionId = sessionSettings?.AadSessionId;

    var tokenRequest = {
        loginHint: userPrincipalName,
        extraQueryParameters: { domain_hint },
        sid: aadSessionId,
    };

    msalInstance
        .ssoSilent(tokenRequest)
        .then(function (tokenResponse) {
            const accessToken = tokenResponse.accessToken;
            // Success. Using callback to return token.
            getAccessTokenCallback(null, accessToken);
        })
        .catch(function (error) {
            if (shouldTryAcquireTokenPopup(error, allowConsentPrompt)) {
                acquireTokenWithPopup(
                    msalInstance,
                    scopes,
                    authChallenge,
                    userAccount,
                    userPrincipalName,
                    domain_hint,
                    getAccessTokenCallback
                );
            } else {
                // Error. Using callback to return error.
                getAccessTokenCallback(error, null /* token */);
            }
        });
}

function shouldTryAcquireTokenPopup(error: any, allowConsentPrompt: boolean): boolean {
    if (!error) {
        return false;
    }
    if (error.errorCode === 'interaction_required' || error.errorCode === 'login_required') {
        return true;
    }
    if (error.errorCode === 'consent_required' && allowConsentPrompt) {
        // Failed to get access token silently and allowConsentPrompt is true and error is about missing consent
        return true;
    }

    return false;
}

function acquireTokenWithPopup(
    msalInstance: PublicClientApplication,
    scopes: string[],
    authChallenge: string,
    userAccount: AccountInfo,
    userPrincipalName: string | undefined,
    domain_hint: string,
    getAccessTokenCallback: MsalTokenCallback
) {
    const tokenRequest = {
        scopes,
        account: userAccount,
        loginHint: userPrincipalName,
        extraQueryParameters: { domain_hint },
        claims: authChallenge,
    };
    msalInstance
        .acquireTokenPopup(tokenRequest)
        .then(function (tokenResponse) {
            const accessToken = tokenResponse.accessToken;
            // Success. Using callback to return token.
            getAccessTokenCallback(null, accessToken);
        })
        .catch(function (error) {
            // Error. Using callback to return error.
            getAccessTokenCallback(error, null /* token */);
        });
}
