import loc from 'owa-localize';
import { NovaEventTypes } from 'owa-nova-actions/lib/types';
import { M365AppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { pinTaskbarShortcutLabel } from '../strings/pinShortcutCommands.locstring.json';

import type { M365Acquisition } from 'owa-graph-schema';
import type { StrictAppSecondaryAction } from '../types';
import type { MailboxInfo } from 'owa-client-types';

export const taskbarPinningApps: string[] = [M365AppId.Calendar];

export function getPinShortcutToTaskbarSecondaryAction(
    acquisition: M365Acquisition,
    mailboxInfo?: MailboxInfo
): StrictAppSecondaryAction {
    return {
        __typename: 'AppSecondaryAction',
        id: 'FADD2BB0-8774-4A57-8861-D7DE5215048E',
        icon: {
            __typename: 'InternalIcon',
            name: 'PinIcon',
        },
        text: loc(pinTaskbarShortcutLabel) ?? 'Pin to Taskbar',
        eventType: NovaEventTypes.pinTaskbarShortcut,
        eventData: JSON.stringify({
            titleId: acquisition.titleId,
            appId: acquisition?.appId,
            mailboxInfo,
        }),
    };
}
