import type { MailboxInfo } from 'owa-client-types';
import getCoprincipalAccountForMailboxInfo from 'owa-account-source-list-store/lib/utils/getCoprincipalAccountForMailboxInfo';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getAccountScopeUserSettings } from 'owa-session-store';

/**
 * Shared and delegate mailboxes need to use the mailbox as the explicit logon, this
 * helper function will return the logon user if needed
 * @param mailboxInfo MailboxInfo for the request
 * @returns Logon user if needed, or undefined if not needed
 */
export function getExplicitLogonSync(mailboxInfo?: MailboxInfo): string | undefined {
    if (
        !!mailboxInfo &&
        isFeatureEnabled('acct-sharedcpa', undefined, /*dontThrowErrorIfNotInitialized*/ true) &&
        mailboxInfo.type === 'UserMailbox' &&
        getCoprincipalAccountForMailboxInfo(mailboxInfo)?.isSharedWithMe
    ) {
        // this is a delegate or shared mailbox being accessed as a coprincipal account, we need to provide
        // an explicit logon. Try and get it as a PUID address and if  that fails, falllback to the smtp address.
        if (
            isFeatureEnabled(
                'acct-sharedexplicitlogonpuid',
                undefined,
                /*dontThrowErrorIfNotInitialized*/ true
            )
        ) {
            const sessionSettings = getAccountScopeUserSettings(mailboxInfo)?.SessionSettings;
            if (sessionSettings?.UserPuid && sessionSettings?.ExternalDirectoryTenantGuid) {
                return `PUID:${sessionSettings.UserPuid}@${sessionSettings.ExternalDirectoryTenantGuid}`;
            }
        }

        return mailboxInfo.mailboxSmtpAddress;
    }

    return undefined;
}

/**
 * Asynchronous version of getExplicitLogonSync
 */
export function getExplicitLogon(mailboxInfo?: MailboxInfo): Promise<string | undefined> {
    return Promise.resolve(getExplicitLogonSync(mailboxInfo));
}
