import type {
    OneJs_MeetingOptionsConfiguration,
    OneJs_MeetingOptionsExternalFeedViewData,
    OneJs_MeetingOptionsLockedTooltipViewData,
    OneJs_MeetingOptionsViewDataResolvers,
} from 'owa-graph-schema';
import loc from 'owa-localize';
import {
    adminLockedTooltipString,
    copyConfirmationString,
    copyKeyString,
    copyLinkString,
    labelLockedTooltipString,
    learnMoreString,
    saveButtonLabelString,
    townhallLockedTooltipString,
    webinarLockedTooltipString,
} from './meetingOptionsStrings.locstring.json';

export const OneJS_MeetingOptionsViewData: OneJs_MeetingOptionsViewDataResolvers = {
    // id: lazyMeetingOptionsViewDataId,
    saveButtonLabel: () => loc(saveButtonLabelString),
    lockedTooltipModel: (): OneJs_MeetingOptionsLockedTooltipViewData => ({
        adminLockedTooltip: loc(adminLockedTooltipString),
        labelLockedTooltip: loc(labelLockedTooltipString),
        townhallLockedTooltip: loc(townhallLockedTooltipString),
        webinarLockedTooltip: loc(webinarLockedTooltipString),
    }),
    configuration: (): OneJs_MeetingOptionsConfiguration => ({
        optionLabelWeight: 'BOLD',
        showDropdownBelowTheLabel: false,
        skin: 'web',
        dropdownAppearance: 'outline',
    }),
    externalFeedModel: (): OneJs_MeetingOptionsExternalFeedViewData => ({
        copy_confirmation: loc(copyConfirmationString),
        copy_link: loc(copyLinkString),
        copy_key: loc(copyKeyString),
        learn_more: loc(learnMoreString),
    }),
};
