// these modules are duplicated a lot across the bundles but are in all the boot bundles
// including them here won't hurt boot size but will decrease duplication
import {
    Dialog as DialogV19,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Listbox,
    Option,
} from '@fluentui/react-components';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Label } from '@fluentui/react/lib/Label';
import { List } from '@fluentui/react/lib/List';
import { Modal } from '@fluentui/react/lib/Modal';
import { Overlay } from '@fluentui/react/lib/Overlay';
import { Panel } from '@fluentui/react/lib/Panel';
import { Persona } from '@fluentui/react/lib/Persona';
import { ResizeGroup } from '@fluentui/react/lib/ResizeGroup';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { TextField } from '@fluentui/react/lib/TextField';
import { Tooltip, TooltipHost } from '@fluentui/react/lib/Tooltip';
import { sanitize } from 'dompurify';

// by exporting this const, this prevents from tree shaking
// as all of these have sideEffects turned off
export const commonDuplicatedModules = [
    DialogV19,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Listbox,
    Option,
    ChoiceGroup,
    Dialog,
    Dropdown,
    Label,
    List,
    Modal,
    Overlay,
    Panel,
    Persona,
    ResizeGroup,
    Shimmer,
    Spinner,
    TextField,
    Tooltip,
    TooltipHost,
    sanitize,
];
