import { LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailBootGroupsPanel" */ './lazyFunctions')
);

export const GroupsPanelContainerProxy = createLazyComponent(
    lazyModule,
    m => m.GroupsPanelContainer
);
export const GroupsPanelHeaderProxy = createLazyComponent(lazyModule, m => m.GroupsPanelHeader);
