import { GetTokenApi } from './GetTokenApi';
import { GetTokenApiUsingHostApp } from './GetTokenApiUsingHostApp';
import { GetTokenApiUsingMSAL } from './GetTokenApiUsingMSAL';
import type { IGetTokenApi } from './IGetTokenApi';
import type { ITokenQueue } from './utils/ITokenQueue';
import { TokenQueue } from './utils/TokenQueue';
import { getTokenCache } from './getTokenCache';

// One time initializations of Queue and Cache
let tokenQueue: ITokenQueue;
export function getTokenQueue(): ITokenQueue {
    if (!tokenQueue) {
        tokenQueue = new TokenQueue();
    }
    return tokenQueue;
}

let tokenApi: IGetTokenApi;
export function getTokenApi(): IGetTokenApi {
    if (!tokenApi) {
        tokenApi = new GetTokenApi(getTokenQueue(), getTokenCache(), 'GetTokenApi');
    }
    return tokenApi;
}

let tokenApiUsingMSAL: IGetTokenApi;
export function getTokenApiUsingMSAL(): IGetTokenApi {
    if (!tokenApiUsingMSAL) {
        tokenApiUsingMSAL = new GetTokenApiUsingMSAL(
            getTokenQueue(),
            getTokenCache(),
            'GetTokenApiUsingMSAL'
        );
    }
    return tokenApiUsingMSAL;
}

let tokenApiUsingHostApp: IGetTokenApi;
export function getTokenApiUsingHostApp(hostApp?: string): IGetTokenApi {
    if (!tokenApiUsingHostApp) {
        tokenApiUsingHostApp = new GetTokenApiUsingHostApp(
            getTokenQueue(),
            getTokenCache(),
            'GetTokenApiUsingHostApp_' + hostApp ?? 'UNKNOWN'
        );
    }
    return tokenApiUsingHostApp;
}
