import type { HostSourceInformation } from 'owa-account-source-list';
import { addM365ConnectedMailboxHostAccount } from 'owa-account-source-list/lib/utils/sourceList';
import getSourceIdForUserIdentity from 'owa-account-source-list/lib/utils/getSourceIdForUserIdentity';
import { SourceAuthType, SourceType } from 'owa-account-source-list/lib/types';
import {
    accountRankTypeChecker,
    accountSourceDataTypeChecker,
    ExternalDataType,
    getCoprincipalAccounts,
    getGlobalSettingsAccount,
    isAccountSourceListStoreInitialized,
    removeCoprincipalAccountById,
} from 'owa-account-source-list-store';
import type { AccountSource } from 'owa-account-source-list-store';
import { logStartUsage } from 'owa-analytics-start';

/**
 * OWA only supports a single connected account, use a well know guid for the
 * persistence id of this account
 */
const connectedAccountPersistenceIdPrefix = 'C0C0C0C0-C0C0-C0C0-C0C0-C0C0C0C0C0C0';

/**
 * Helper function that will try and get the persistence id of the specified account
 * @param account Account to get the persistence id for
 */
function getCoprincipalAccountPersistenceId(account?: AccountSource): string | undefined {
    if (accountRankTypeChecker.isCoprincipal(account)) {
        return account.persistenceId;
    }

    return undefined;
}

/**
 * Gets the SourceAuthType for the specified account provider type, the account provider type
 * comes from the OWA calendar connected account data. The OWA calendar connected accounts only
 * support Outlook, Google, and ICloud, and Yahoo accounts (and ICloud/Yahoo are flighted) so these
 * are the only accounts for which conversions are provided.
 * @param accountProviderType Type of the OWA calendar connected account
 * @returns Corresponding SourceAuthType for the account provider type
 */
function getAuthProviderType(accountProviderType: string): SourceAuthType {
    switch (accountProviderType) {
        case 'Outlook':
            return SourceAuthType.OutlookDotCom;
        case 'Google':
            return SourceAuthType.Google;
        case 'ICloud':
            return SourceAuthType.ICloud;
        case 'Yahoo':
            return SourceAuthType.Yahoo;
        default:
            logStartUsage('AcctAdd-ConnectedAccount-UnknownAuthProviderType', {
                accountProviderType,
            });

            // Default to Outlook.com
            return SourceAuthType.OutlookDotCom;
    }
}

/**
 * This function is for use in OWA to get the sourceId that should be used for an
 * OWA calendar connected account
 * @param userIdentity User identity associated with the OWA connected account
 * @param accountProviderType Provider for the OWA connected account
 */
export function getConnectedAccountPersistenceId(
    userIdentity: string,
    accountProviderType: string
) {
    return `${connectedAccountPersistenceIdPrefix}-${accountProviderType}-${userIdentity}`;
}

// Adds or updates the OWAConnectedAccount in the source list store
export function addConnectedAccount(
    userIdentity: string,
    accountUniqueId: string,
    accountProviderType: string,
    anchorMailbox: string,
    accountState: number
) {
    // We need to build a persistence id that is unique for the connected account, to do this we use
    // the connected account prefix and combine it with the account provider type and the user identity.
    const persistenceId = getConnectedAccountPersistenceId(userIdentity, accountProviderType);

    const hostAccount: HostSourceInformation = {
        sourceType: SourceType.Mailbox,
        authProviderType: getAuthProviderType(accountProviderType),
        displayName: userIdentity,
        contracts: {
            supportsCalendar: true,
            supportsContacts: false,
            supportsMail: false,
            supportsSettings: false,
        },
        externalData: { type: ExternalDataType.None },
        mailbox: {
            type: 'UserMailbox',
            name: userIdentity,
            displayName: userIdentity,
            emailAddress: userIdentity,
            loginAddress: userIdentity ?? '',
            userIdentity,
            accountUniqueId,
            routingHint: anchorMailbox,
            alternateId: '',
            imageUrl: '',
            profileUrl: '',
        },
        accountState: {
            connectedAccountState: accountState,
        },
        persistenceId,
        userIdentity,
    };

    let noAccountForPersistenceId: boolean | undefined = undefined;
    const noAccountForUserIdentity = !getSourceIdForUserIdentity(userIdentity);

    // Look for another (non-global settings) account that matches the persistance
    // if for the coprincipal account.
    const matching = getCoprincipalAccounts()
        .slice(1)
        .filter(account => account.persistenceId === persistenceId);
    noAccountForPersistenceId = matching.length === 0;

    // Remove any preexisting connected accounts
    matching.forEach(match => {
        removeCoprincipalAccountById(match.sourceId);
    });

    // Now add the connected account
    addM365ConnectedMailboxHostAccount(hostAccount);

    // the global settings account
    const isInitialzied = isAccountSourceListStoreInitialized();
    const defaultSettings = isInitialzied ? getGlobalSettingsAccount() : undefined;
    const isGSACoId = getCoprincipalAccountPersistenceId(defaultSettings) === persistenceId;

    logStartUsage('AcctAdd-ConnectedAccount', {
        noAccountForUserIdentity,
        noAccountForPersistenceId,
        isGSACoId,
        uit: typeof userIdentity,
        uil: userIdentity?.length,
        actt: typeof accountProviderType,
        actl: accountProviderType?.length,
        defuit: typeof defaultSettings?.mailboxInfo?.userIdentity,
        isdefcon: accountSourceDataTypeChecker.isM365ConnectedMailbox(defaultSettings),
        defuitl: defaultSettings?.mailboxInfo?.userIdentity?.length,
        equal: userIdentity === defaultSettings?.mailboxInfo?.userIdentity,
        isInit: isInitialzied,
    });
}
