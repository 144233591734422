import { type HttpStatusCode } from 'owa-http-status-codes';

// 1 minute
const DEFAULT_TIMEOUT_MS: number = 60000;
const MAX_RETRIES: number = 3;

export const fetchWithTimeoutAndRetries = async (
    url: string,
    requestInit: RequestInit = {},
    timeoutMS: number = DEFAULT_TIMEOUT_MS,
    retries: number = MAX_RETRIES
): Promise<string | null> => {
    if (retries < 0) {
        return null;
    }

    let controller: AbortController | undefined;

    if ('AbortController' in window) {
        controller = new AbortController();
        requestInit.signal = controller.signal;
    }

    const timerHandle: number = window.setTimeout(() => {
        if (controller) {
            window.clearTimeout(timerHandle);
            controller.abort();
            return fetchWithTimeoutAndRetries(url, requestInit, timeoutMS, retries - 1);
        } else {
            return null;
        }
    }, timeoutMS);

    try {
        const response = await fetch(url, requestInit);
        window.clearTimeout(timerHandle);

        // If the response is OK, return the blob
        if (response?.status && [200].indexOf(response.status) !== -1) {
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);
            return objectURL;
        }
        // If the response is not OK, retry
        return fetchWithTimeoutAndRetries(url, requestInit, timeoutMS, retries - 1);
    } catch {
        // Keep retrying until we run out of retries
        return fetchWithTimeoutAndRetries(url, requestInit, timeoutMS, retries - 1);
    }
};
