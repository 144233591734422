import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isFeatureEnabled } from 'owa-feature-flags';
import { logUsage } from 'owa-analytics';
import fetchPoliciesAndSetStore from './fetchPoliciesAndSetStore';
import type { AccountSource } from 'owa-account-source-list-store';
import {
    getCoprincipalAccountSourcesByBootState,
    BootState,
    onCoprincipalBootStateChanged,
} from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { orchestrator } from 'satcheljs';
import { setOcpsPolicyStoreState } from '../mutators/setOcpsPolicyStoreState';
import { OcpsStoreState } from '../store/schema/OcpsPolicyStore';

export default async function initializeOcpsPolicyStore() {
    try {
        setOcpsPolicyStoreState(OcpsStoreState.Fetching);
        const userMailboxes: MailboxInfo[] = getCoprincipalAccountSourcesByBootState(
            BootState.StartupComplete
        ).map(accountSource => accountSource.mailboxInfo);

        for (let i = 0; i < userMailboxes.length; i++) {
            await fetchUserOcpsPolicies(userMailboxes[i]);
        }

        // Register for boot state change and fetch policies when a new account is added
        orchestrator(
            onCoprincipalBootStateChanged,
            async (arg: { updatedAccount: AccountSource; newBootState: BootState }) => {
                const actualAccount = arg.updatedAccount;
                const actualBootState = arg.newBootState;
                if (actualBootState === BootState.StartupComplete) {
                    logUsage('FetchPoliciesAndSetStore_AccountAdded');
                    await fetchUserOcpsPolicies(actualAccount.mailboxInfo);
                }
            }
        );
        setOcpsPolicyStoreState(OcpsStoreState.Ready);
    } catch (e) {
        logUsage('InitializeOcpsPolicyStore_Failed', { error: e.message, stack: e.stack });
        setOcpsPolicyStoreState(OcpsStoreState.FailedRetry);
        throw e;
    }
}

async function fetchUserOcpsPolicies(mailboxInfo: MailboxInfo) {
    if (isFeatureEnabled('ocps-policy-check', mailboxInfo) && !isConsumer(undefined, mailboxInfo)) {
        await fetchPoliciesAndSetStore(mailboxInfo);
    }
}
