import { action, mutator } from 'satcheljs';
import { getLocalizedStringStore } from '../store/store';
import type { ResourceId } from '../ResourceId';
import type { LocalizedString } from '../LocalizedString';

export const addLocstringsToStore = action('ADD_LOCSTRINGS', (strings: Record<string, string>) => {
    return { str: strings as Record<ResourceId, LocalizedString> };
});

mutator(addLocstringsToStore, actionMessage => {
    getLocalizedStringStore().localizedStrings.merge(actionMessage.str);
});
