import type { MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getApp } from 'owa-config';
import { errorThatWillCauseAlert } from 'owa-trace';
import { isOfflineEnabled } from './isOfflineEnabled';
import { isOfflineSyncAvailable } from './isOfflineSyncAvailable';

export function isOfflineSyncEnabled(mailboxInfo?: MailboxInfo | null): boolean {
    return (
        isOfflineSyncAvailable() &&
        isOfflineSyncEnabledForApp() &&
        isOfflineEnabled(mailboxInfo || getGlobalSettingsAccountMailboxInfo())
    );
}

// We only want to sync in apps that use local data. Enabling offline based on the first app seems good enough while avoiding the complexity of starting and stopping sync when switching apps.
let firstApp: string | undefined;
export function isOfflineSyncEnabledForApp() {
    if (!firstApp) {
        firstApp = getApp();
        if (!firstApp) {
            errorThatWillCauseAlert(
                'IsOfflineSyncEnabled called before we know what app is running'
            );
        }
    }

    return doesAppSupportOfflineSync(firstApp);
}

function doesAppSupportOfflineSync(app: string) {
    switch (app) {
        case 'Mail':
        case 'Calendar':
        case 'People':
            return true;
        default:
            return false;
    }
}
