// only use for telemetry purposes
export enum ConvertIdSource {
    SelectedCalendars = 'SelectedCalendars',
    MyDay = 'MyDay',
    SavedCalendarViews = 'SavedCalendarViews',
    Spotlight = 'Spotlight',
    CalendarWidget = 'CalendarWidget',
    Addins = 'Addins',
    ActivityFeed = 'ActivityFeed',
    ReadingPane = 'ReadingPane',
    SXSAttachments = 'SXSAttachments',
    BoardMailItem = 'BoardMailItem',
    TodoLinkedEmail = 'TodoLinkedEmail',
    InfoPane = 'InfoPane',
    Search = 'Search',
    CalendarCopilot = 'CalendarCopilot',
    TimeCopilot = 'TimeCopilot',
    SemanticOverview = 'SemanticOverview',
    Compose = 'Compose',
    ListView = 'ListView',
}
