import '@1js/suiteux-shell';

import React from 'react';
import { createPortal } from 'owa-react-dom';
import { isBrowserSafari } from 'owa-user-agent';

import type {
    CustomizationRegion,
    LinkClickEventArgs,
    NavBarLinkData,
    ShellControl,
    ShellLayout,
    NavBarData as ShellNavBarData,
    SignOutEventArgs,
} from '@1js/suiteux-shell-react';
import { SuiteHeader } from '@1js/suiteux-shell-react';
import { HEADER_BUTTONS_REGION_ID, OWA_SEARCH_SCOPE_PICKER_ID } from 'owa-header-constants';
import { OwaDevSourceLabel } from './OwaDevSourceLabel';
import { setLeftCharmsRegionWidth } from './LeftCharmsRegion';
import { OwaLogoOnTileSpace } from './OwaLogoOnTileSpace';
import {
    OwaActivityFeedButton,
    OwaBusinessChatButton,
    OwaCalendarCopilotButton,
    OwaChatCopilotButton,
    OwaConsumerChatButton,
    OwaConsumerTeamsChatButton,
    OwaDiagFeedbackButton,
    OwaDiagnosticsButton,
    OwaFeedbackButton,
    OwaMeetNowButton,
    OwaNoteFeedButton,
    OwaPremiumButton,
    OwaRolloutOverridesButton,
    OwaSettingsButton,
    OwaShellHelpButton,
    OwaSupportButton,
    OwaTimePanelButton,
    OwaWhatsNewButton,
    OwaTipsButton,
} from './OwaSuiteHeaderButton';
import {
    OwaActivityFeedButtonID,
    OwaSupportButtonID,
    OwaSupportFlexPaneID,
    OwaWhatsNewButtonID,
    OwaTipsButtonID,
} from '../constants';
import {
    OwaActivityFeedFlexPane,
    OwaChatCopilotFlexPane,
    OwaDiagFeedbackFlexPane,
    OwaExpressionFlexPane,
    OwaFeedbackFlexPane,
    OwaNoteFeedFlexPane,
    OwaCalendarCopilotPane,
    OwaRolloutOverridesFlexPane,
    OwaSkypeFlexPane,
    OwaSupportFlexPane,
    OwaGetDiagnosticsFlexPane,
    OwaTimePanelFlexPane,
    OwaWhatsNewFlexPane,
    OwaGroupsPanelFlexPane,
    OwaDexFlexPane,
    OwaInfoPane,
    OwaTipsFlexPane,
    OwaDiagnosticsFlexPane,
    OwaDictationHelpFlexPane,
} from './OwaSuiteFlexPane';
import getNewScope from 'owa-url/lib/getNewScope';
import getRootVdirName from 'owa-url/lib/getRootVdirName';
import { getOrigin } from 'owa-url/lib/getOrigin';
import {
    getUnreadWhatsNewCardCount,
    initializeWhatsNewCardsLazy,
    WhatsNewInitSource,
} from 'owa-whats-new';
import { getAccountScopeUserSettings, isConsumer, isPremiumConsumer } from 'owa-session-store';

import {
    isSupportEnabled,
    lazyHandleSupportPaneEvents,
    lazyInitializeSupportOnBoot,
} from 'diagnostics-and-support';
import loc, { format } from 'owa-localize';

import { DEFAULT_COLLAPSED_WIDTH } from 'owa-search-constants';
import type { IO365ShellShim } from '@1js/suiteux-shell';
import { OpenAnotherMailboxDialog } from 'owa-explicit-logon';
import type { OwaSuiteHeaderProps } from 'owa-bootstrap';
import type OwaWorkload from 'owa-workloads/lib/store/schema/OwaWorkload';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { feedbackLink } from 'owa-locstrings/lib/strings/feedbacklink.locstring.json';
import { getApp, isGulpOrBranching, getNativeHostVersion } from 'owa-config';
import { getBposNavBarData } from 'owa-bpos-store';
import { getConfig } from 'owa-service/lib/config';
import { getMergedNotificationPane } from 'owa-header/lib/components/getMergedNotificationPane';
import { getResourcePathUrl } from 'owa-resource-url';
import { getQueryStringParameter } from 'owa-querystring';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { helpLink } from 'owa-locstrings/lib/strings/helplink.locstring.json';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'isEnvironmentAirGap' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { isEnvironmentAirGap } from 'owa-metatags';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getPrivacyUrl } from 'owa-feedback-common';
import { isTimePanelAvailable } from 'owa-time-panel-bootstrap';
import isWorkloadSupported from 'owa-workloads/lib/utils/isWorkloadSupported';
import { lazyGovern, GovernPriority } from 'owa-tti';
import { lazyHandleSocPostMessageEvents } from 'owa-help-charm';
import { lazyInitializeActivityFeed, lazyGetUnseenItemsCount } from 'owa-activity-feed';
import { initializeOneNoteFeed } from 'owa-notes-feed-bootstrap';
import { initializeSkypeForBusiness } from 'owa-skype-for-business';
import { lazyInitializeTimePanelProxy } from 'owa-time-panel/lib/lazyCalendarBoot';
import { lazyIsMeetNowEnabled } from 'owa-meet-now';
import { isTeamsChatEnabled } from 'owa-teams-policies';
import { lazyLaunchInAppFeedback } from 'owa-uservoice';
import { lazySignout } from 'owa-header';
import { observer } from 'owa-mobx-react';
import { openAnotherMailbox } from 'owa-locstrings/lib/strings/openanothermailbox.locstring.json';
import { privacyLink } from 'owa-locstrings/lib/strings/privacylink.locstring.json';
import { privacyChoicesLink } from 'owa-locstrings/lib/strings/privacychoiceslink.locstring.json';
import { reportCalendarAbuseLink } from 'owa-locstrings/lib/strings/reportcalendarabuselink.locstring.json';
import { notifNewItemsFmt } from '../strings.locstring.json';
import setAppPaneUnderlayVisibility from 'owa-application/lib/actions/setAppPaneUnderlayVisibility';
import {
    openFlexPane,
    setShellButtonCustomBadgeCount,
    setShellButtonCustomBadgeColors,
    setShellButtonCustomTooltip,
} from 'owa-suite-header-apis';
import { showModal } from 'owa-modal';
import { chatButtonRenderedCallback } from '../utils/chatButtonRenderedCallback';
import { fetchTokenForShell } from '../utils/fetchTokenForShell';
import { suiteRenderedCallback } from '../utils/suiteRenderedCallback';
import { termsLink } from 'owa-locstrings/lib/strings/termslink.locstring.json';
import { useWindowEvent } from 'owa-react-hooks/lib/useWindowEvent';
import { logUsage, logCoreUsage } from 'owa-analytics';
import useSuiteHeaderLanguageProps from '../utils/useSuiteHeaderLanguageProps';
import useOwaBrandTheme from '../utils/useOwaBrandTheme';
import useOwaThemeData from '../utils/useOwaThemeData';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getIsMicaEffectEnabled } from 'owa-theme';
import { isCapabilityEnabled } from 'owa-capabilities';
import { feedbackCapability } from 'owa-capabilities-definitions/lib/feedbackCapability';
import { diagnosticsFeedbackCapability } from 'owa-capabilities-definitions/lib/diagnosticsFeedbackCapability';
import { getApplicationSettings } from 'owa-application-settings';
import { lazyLaunchUnifiedConsentDialog } from 'owa-unified-consent';
import { lazyHandleFeedbackPostMessageEvents } from 'owa-smiley-feedback';
import {
    shouldLoadPreAuthOnIdleCallbackPromise,
    getChatUnreadMessageCallbackPromise,
} from '../utils/embedChatOnIdleCallbackPromises';
import { isNewUserPostJit } from 'owa-new-user-experience';
import { isOcpsStoreReady } from 'owa-ocps-policy-store';
import { shouldUseCobranding } from 'owa-theme-common';
import { PendingAccountsWelcomeCallout } from 'owa-mail-accounts-view-callout';
import { RepromptDialog } from 'owa-reprompt-dialog';
import { DiagnosticsHostCommon } from 'owa-diagnostics-common';
import { NewOutlookPromoDialog } from 'owa-new-outlook-promo-dialog';
import { shyHeaderCapability } from 'owa-capabilities-definitions/lib/shyHeaderCapability';

interface ExtendedWindow {
    O365Shell: IO365ShellShim;
}

declare var window: Window & ExtendedWindow;
const ShellMailId = 'ShellMail';

const OWA_CONSUMER_APP_ID = '292841';
const SELECT_ACCOUNT_PARAM = 'prompt=select_account';
const LOGIN_HINT_PARAM = 'login_hint';
const ACCOUNT_SWITCH_PARAM = 'actSwt=true';
const MaxFeedbackPaneURLFormat = '{0}/f1/home/inappfeedbackaddin?helpid=161255';
const ConsumerSocUrl = 'https://support.office.live.com';
const BusinessSocUrl = 'https://support.office.com';
const BADGE_COLOR_RED = '#D13438';
const APPEND_BOOT_FLIGHTS_PARAM = 'appendbootflights';

export default observer(function OwaSuiteHeader(props: OwaSuiteHeaderProps) {
    React.useEffect(() => {
        lazyGovern.importAndExecute({
            task: () => lazyLaunchUnifiedConsentDialog.importAndExecute(),
            condition:
                isFeatureEnabled('unified-consent-feature') ||
                isFeatureEnabled('unified-consent-exp-feature'),
            priority: GovernPriority.Lightning,
        });
    }, []);

    const [setSearchContainer, searchContainerPortal] = useReactPortal(props.renderSearch);
    const [setSearchScopeContainer, searchScopePortal] = useReactPortal(
        props.renderSearchScopePicker
    );

    const sessionSettings = getUserConfiguration().SessionSettings;
    const accountScopeSessionSettings = getAccountScopeUserSettings(
        getGlobalSettingsAccountMailboxInfo()
    ).SessionSettings;

    const [setNotificationPaneContainer, notificationPortal] = useReactPortal(() => (
        <>{getMergedNotificationPane()}</>
    ));

    // when fwk-skype-deprecation is deleted, rename the function to "lazyInitializeTeamsChatProvider"
    React.useEffect(() => {
        lazyGovern.importAndExecute({
            task: initializeSkypeForBusiness,
            condition: isFeatureEnabled('fwk-skypeBusinessV2'),
            priority: GovernPriority.SuiteHeader,
        });
    }, []);

    useWindowEvent('message', (messageEvent: any) => {
        lazyHandleSocPostMessageEvents.importAndExecute(messageEvent);
        lazyHandleSupportPaneEvents.importAndExecute(messageEvent);
        lazyHandleFeedbackPostMessageEvents.importAndExecute(messageEvent);
    });

    const feedbackNavLink = createHelpLink('OwaFeedbackLink', loc(feedbackLink), undefined, () => {
        lazyLaunchInAppFeedback.importAndExecute();
        return false;
    });

    const helpNavLink = createHelpLink(
        'OwaHelpLink',
        loc(helpLink),
        'https://go.microsoft.com/fwlink/?linkid=853225'
    );

    const privacyNavLink = createHelpLink('OwaPrivacyLink', loc(privacyLink), getPrivacyUrl());

    const privacyChoicesNavLink = createHelpLink(
        'OwaPrivacyChoicesLink',
        loc(privacyChoicesLink),
        'https://account.microsoft.com/privacy/ad-settings'
    );

    const legalNavLink = createHelpLink(
        'OwaTermsLink',
        loc(termsLink),
        'https://www.microsoft.com/en-us/servicesagreement'
    );

    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
     * Adding IsShadowMailbox to restricted properties/methods.
     *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
    const isShadowMailbox = React.useRef(accountScopeSessionSettings?.IsShadowMailbox);
    const owaChatEnabled = React.useRef(
        !(
            isFeatureEnabled('fwk-partner-code-off') ||
            isFeatureEnabled('fwk-skypeSuite') ||
            isFeatureEnabled('fwk-teamsSuite')
        ) &&
            (isFeatureEnabled('fwk-skypeBusinessV2') || isFeatureEnabled('fwk-skypeConsumer')) &&
            !isShadowMailbox.current
    );
    const headerChatEnabled = React.useRef(
        isFeatureEnabled('fwk-skypeSuite') &&
            (isFeatureEnabled('fwk-skypeBusinessV2') || isFeatureEnabled('fwk-skypeConsumer'))
    );

    const headerTeamsChatEnabled = React.useRef(isFeatureEnabled('fwk-teamsSuite'));

    const APP_BRAND_THEME = useOwaBrandTheme();
    const themeDataOverride = useOwaThemeData();
    const isCobrandingTheme = shouldUseCobranding(themeDataOverride.UserThemeId);
    const isColdStartScenario = React.useRef(true);

    // For cold start scenarios, we need to hide the tenant logo for the initial render
    // This is intended to prevent the shell from loading the TenantLogo from the ShellService response.
    const hideTenantLogoForInitialRender =
        isColdStartScenario.current && isCobrandingTheme && themeDataOverride.IsDarkTheme;

    const bPosNavBarData: ShellNavBarData = getBposNavBarData() as ShellNavBarData;
    const navBarData = React.useMemo(() => {
        if (bPosNavBarData) {
            const owaNavBarData: ShellNavBarData = {
                ...bPosNavBarData,
                CurrentMainLinkElementID: ShellMailId,
                ThemeDataOverride: themeDataOverride,
                AppBrandTheme: APP_BRAND_THEME,
                FeedbackLink: feedbackNavLink,
                HelpLink: helpNavLink,
                PrivacyLink: privacyNavLink,
                PrivacyChoicesLink: privacyChoicesNavLink,
                LegalLink: legalNavLink,
                CurrentWorkloadHelpSubLinks: getWorkloadHelpSublinks(
                    bPosNavBarData.CurrentWorkloadHelpSubLinks
                ),
            };
            return owaNavBarData;
        } else {
            return undefined;
        }
    }, [bPosNavBarData, themeDataOverride, APP_BRAND_THEME]);

    React.useEffect(() => {
        if (navBarData) {
            // A valid cold start scenario is when the theme is dark and is cobranding theme at initial render
            const isValidColdStartScenario =
                isColdStartScenario.current &&
                navBarData.ThemeDataOverride.IsDarkTheme &&
                isCobrandingTheme;
            const isValidWarmScenario = !isColdStartScenario.current && isCobrandingTheme;

            // For valid cold and warm scenarios, we need to fetch and load the TenantLogo from WCSS
            if ((isValidColdStartScenario || isValidWarmScenario) && window.O365Shell) {
                window.O365Shell.Theme.UpdateTenantLogo(navBarData.ThemeDataOverride.IsDarkTheme);
            }

            isColdStartScenario.current = false;
        }
    }, [navBarData, isCobrandingTheme]);

    const createCustomLayout = (): ShellLayout => {
        const customHeaderButtons: ShellControl[] = [];
        const { renderSearchScopePicker, searchBoxRespond } = props;

        pushOnCondition(
            customHeaderButtons,
            OwaMeetNowButton,
            isFeatureEnabled('fwk-meetNowButtonHeader') &&
                (isFeatureEnabled('fwk-teamsPolicies')
                    ? true // If Teams policy flight is enabled, use async call back to control visibility.
                    : lazyIsMeetNowEnabled.tryImportForRender()?.())
        );

        const hideChatIntegrationEnabled = isFeatureEnabled('mon-hide-chat-integration');
        pushOnCondition(
            customHeaderButtons,
            isFeatureEnabled('fwk-skypeBusinessV2')
                ? OwaBusinessChatButton
                : isFeatureEnabled('teams-migrateConsumerMeetNow')
                ? OwaConsumerTeamsChatButton
                : OwaConsumerChatButton,
            owaChatEnabled.current && !hideChatIntegrationEnabled
        );

        pushOnCondition(
            customHeaderButtons,
            { id: 'owaChatButton', nativeControlID: 'ChatIcon' } as ShellControl,
            (headerChatEnabled.current || headerTeamsChatEnabled.current) &&
                !hideChatIntegrationEnabled
        );

        pushOnCondition(
            customHeaderButtons,
            OwaPremiumButton,
            isPremiumConsumer(getGlobalSettingsAccountMailboxInfo()) && !isShadowMailbox.current
        );

        // Push button standard header button if flight is enabled
        // and we're not running against Cloud Cache mailbox.
        if (isFeatureEnabled('notes-noteFeedSidePanel') && !isShadowMailbox.current) {
            pushOnCondition(customHeaderButtons, OwaNoteFeedButton);
            lazyGovern.importAndExecute({
                task: () => initializeOneNoteFeed(),
                priority: GovernPriority.Idle,
            });
        }

        if (isTimePanelAvailable()) {
            pushOnCondition(customHeaderButtons, OwaTimePanelButton);
            lazyGovern.importAndExecute({
                task: () => lazyInitializeTimePanelProxy.importAndExecute(),
                priority: GovernPriority.Idle,
            });
        }

        pushOnCondition(customHeaderButtons, OwaActivityFeedButton);
        lazyGovern.importAndExecute({
            task: () => lazyInitializeActivityFeed.importAndExecute(),
            priority: GovernPriority.Idle,
        });
        (lazyGetUnseenItemsCount.importAndExecute() as Promise<number>).then(unseenItemsCount => {
            const showUnseenItemsBadge = unseenItemsCount > 0;
            if (showUnseenItemsBadge) {
                logUsage('ActivityFeed_UnreadBadgeCount', { badgeCount: unseenItemsCount });
            }
            setShellButtonCustomBadgeCount(OwaActivityFeedButtonID, unseenItemsCount);

            const bellTooltip = showUnseenItemsBadge ? loc(notifNewItemsFmt) : ''; // Empty string = default tooltip (name of the pane)
            setShellButtonCustomTooltip(OwaActivityFeedButtonID, bellTooltip);
        });

        const redBadgeColor = {
            badgeColor: BADGE_COLOR_RED,
            badgeHoverColor: BADGE_COLOR_RED,
            badgeFontColor: 'white',
            badgeFontHoverColor: 'white',
        };
        setShellButtonCustomBadgeColors(OwaActivityFeedButtonID, redBadgeColor);

        if (isFeatureEnabled('calendar-copilot-feature') && !isConsumer()) {
            pushOnCondition(customHeaderButtons, OwaCalendarCopilotButton);
        }

        pushOnCondition(customHeaderButtons, OwaSettingsButton);

        const doNotUseHelpTabInRibbon = !isFeatureEnabled('mon-diag-helpTab');

        pushOnCondition(
            customHeaderButtons,
            OwaShellHelpButton,
            !isEnvironmentAirGap() && doNotUseHelpTabInRibbon
        );

        if (isSupportEnabled()) {
            if (doNotUseHelpTabInRibbon) {
                pushOnCondition(customHeaderButtons, OwaSupportButton);
                lazyGovern.importAndExecute({
                    task: () =>
                        lazyInitializeSupportOnBoot.importAndExecute((badgeCount: number) => {
                            setShellButtonCustomBadgeCount(OwaSupportButtonID, badgeCount);
                        }),
                    priority: GovernPriority.Idle,
                });
            } else {
                lazyGovern.importAndExecute({
                    task: () =>
                        lazyInitializeSupportOnBoot.importAndExecute((badgeCount: number) => {
                            if (badgeCount > 0) {
                                openFlexPane(OwaSupportFlexPaneID);
                            }
                        }),
                    priority: GovernPriority.Idle,
                });
            }
        }

        if (isCapabilityEnabled(diagnosticsFeedbackCapability)) {
            pushOnCondition(
                customHeaderButtons,
                OwaDiagFeedbackButton,
                isFeatureEnabled('mon-diag-feedbackjs') && doNotUseHelpTabInRibbon
            );

            if (
                isFeatureEnabled('fwk-internalFeedback') &&
                !isFeatureEnabled('mon-diag-feedbackjs')
            ) {
                pushOnCondition(customHeaderButtons, OwaFeedbackButton);
            }
        }

        pushOnCondition(
            customHeaderButtons,
            OwaRolloutOverridesButton,
            isFeatureEnabled('fwk-devTools') && doNotUseHelpTabInRibbon
        );

        pushOnCondition(
            customHeaderButtons,
            isFeatureEnabled('tips-pane') ? OwaTipsButton : OwaWhatsNewButton
        );

        if (getApp() === 'Oobe' || (isNewUserPostJit() && isFeatureEnabled('user-intent-survey'))) {
            const initSource: WhatsNewInitSource = isNewUserPostJit()
                ? WhatsNewInitSource.UserIntent
                : WhatsNewInitSource.Oobe;
            initializeWhatsNewCardsLazy.importAndExecute(undefined, initSource);
        } else {
            lazyGovern.importAndExecute({
                task: () => initializeWhatsNewCardsLazy.importAndExecute(),
                priority: GovernPriority.Idle,
            });
        }

        setShellButtonCustomBadgeCount(
            isFeatureEnabled('tips-pane') ? OwaTipsButtonID : OwaWhatsNewButtonID,
            getUnreadWhatsNewCardCount()
        );

        pushOnCondition(
            customHeaderButtons,
            OwaDiagnosticsButton,
            getApplicationSettings('Diagnostics').panel &&
                !!props.enableDiagnostics &&
                doNotUseHelpTabInRibbon
        );

        // Always push OwaChatCopilotButton, as the show/hide logic is embedded
        // in the actual button component itself to allow for dynamicability.
        customHeaderButtons.push(OwaChatCopilotButton);

        // Now define responsive behavior for all the custom header buttons, with the
        // right-most one getting dropped first
        customHeaderButtons.forEach((control, index, array) => {
            if (!control.responsiveBehavior) {
                control.responsiveBehavior = {
                    responsivePriority: array.length - 1 - index + 2,
                    minimizeBehavior: 'overflow',
                    delayMinimize: false,
                };
            } else if (control.responsiveBehavior.responsivePriority == 0) {
                // Update the responsive priorities for the buttons as per its position in the collection
                control.responsiveBehavior.responsivePriority = array.length - 1 - index + 2;
            }
        });

        const leftCharms = [
            {
                id: 'owaBranding',
                flex: renderSearchScopePicker ? '1 1 auto' : '',
                nativeControlID: 'O365Branding',
                minWidth: 'auto',
            } as ShellControl,
        ];

        leftCharms.unshift({
            id: 'tenantLogo',
            nativeControlID: 'TenantLogo',
            minWidth: 'auto',
        } as ShellControl);

        const appLauncherButtonWidth = isFeatureEnabled('fwk-appLauncher') ? 48 : 0;
        const leftCharmsWidth =
            (props.searchAlignmentWidth /* getSearchBoxLeftPadding */ ||
                getUserConfiguration().UserOptions?.NavigationBarWidth ||
                228) - appLauncherButtonWidth; /* Subtract the button width */
        if (renderSearchScopePicker) {
            leftCharms.push({
                id: OWA_SEARCH_SCOPE_PICKER_ID,
                flex: '1 1 auto',
                justifyContent: 'flex-end',
                minWidth: 'auto',
                render: setSearchScopeContainer,
            } as ShellControl);
        }

        // When app launcher is not there, we need to leave some space to allow the Outlook logo not to touch the border directly until we find a suitable logo works for all themes.
        if (!isFeatureEnabled('fwk-appLauncher')) {
            leftCharms.unshift(OwaLogoOnTileSpace);
        }

        // Optionally add notification of gulp or branch presence
        if (isFeatureEnabled('fwk-devTools') && isGulpOrBranching()) {
            leftCharms.unshift(OwaDevSourceLabel);
        }

        const flexPaneCollection = [
            OwaFeedbackFlexPane,
            OwaDiagFeedbackFlexPane,
            OwaDiagnosticsFlexPane(DiagnosticsHostCommon),
            OwaRolloutOverridesFlexPane,
            isFeatureEnabled('tips-pane') ? OwaTipsFlexPane : OwaWhatsNewFlexPane,
            OwaSupportFlexPane,
            OwaGetDiagnosticsFlexPane,
            OwaTimePanelFlexPane,
            OwaActivityFeedFlexPane,
            OwaExpressionFlexPane,
            OwaSkypeFlexPane,
            OwaNoteFeedFlexPane,
            OwaGroupsPanelFlexPane,
            OwaCalendarCopilotPane,
            OwaChatCopilotFlexPane,
            OwaDexFlexPane,
            OwaInfoPane,
            OwaDictationHelpFlexPane,
        ];

        setLeftCharmsRegionWidth('leftCharmsRegion', leftCharmsWidth);

        const layout = {
            enableResponsive: !!props.enableResponsiveLayout,
            leftCustomizationRegion: {
                includeAppLauncher: isFeatureEnabled('fwk-appLauncher'),
            },
            centerCustomizationRegion: {
                alignItems: 'center',
                flex: '1 0 auto',
                children: [
                    {
                        /* Left aligned */
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flex: '1 0 auto',
                        children: [
                            {
                                /* Left Charms */
                                regionID: 'leftCharmsRegion',
                                children: leftCharms,
                                flex: '0 0 auto',
                                minWidth: `${leftCharmsWidth}px`,
                            } as CustomizationRegion,
                            {
                                /* Search Box */
                                id: 'owaSearchBox',
                                flex: '1 0 auto',
                                render: setSearchContainer,
                                respond: (step: number) => searchBoxRespond?.(step),
                                responsiveBehavior: {
                                    responsivePriority: 1,
                                    minimizeBehavior: 'custom',
                                    delayMinimize: true,
                                    responsiveSteps: [DEFAULT_COLLAPSED_WIDTH],
                                },
                            } as ShellControl,
                        ],
                    } as CustomizationRegion,
                    {
                        /* Menu Buttons - Right aligned */
                        regionID: HEADER_BUTTONS_REGION_ID,
                        flex: '0 0 auto',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        children: [
                            ...customHeaderButtons,
                            {
                                id: 'OwaMergedNotificationPane',
                                render: setNotificationPaneContainer,
                            } as ShellControl,
                        ],
                    } as CustomizationRegion,
                ],
            } as CustomizationRegion,
            rightCustomizationRegion: {
                includeMeControl: isFeatureEnabled('me-controlAccountSwitching'),
                id: 'O365_HeaderRightRegion2',
            },
            flexPaneCollection,
        } as ShellLayout;
        return layout;
    };
    const createMeSublinks = (): NavBarLinkData[] => {
        return !props.hideOpenAnotherMailbox && accountScopeSessionSettings?.WebSessionType === 0
            ? [
                  {
                      Id: 'OwaOpenTargetMailboxLink',
                      Text: loc(openAnotherMailbox),
                      Url: 'javascript:void(0)',
                      Action: showOpenAnotherMailboxDialog,
                  },
              ]
            : [];
    };

    const userPrincipalName = isShadowMailbox.current
        ? accountScopeSessionSettings?.UserEmailAddress
        : sessionSettings?.UserPrincipalName;

    const accountSwitchingEnabled =
        (isFeatureEnabled('auth-meControl-accountSwitcher') || isAccountSwitchingParamPresent()) &&
        !isShadowMailbox.current &&
        isFeatureEnabled('me-controlAccountSwitching');

    const isChatEnabled = React.useMemo(async (): Promise<boolean> => {
        if (isBrowserSafari() || isFeatureEnabled('mon-hide-chat-integration')) {
            return false;
        }
        if (isFeatureEnabled('fwk-teamsPolicies') && !isConsumer()) {
            return isTeamsChatEnabled();
        } else {
            return headerChatEnabled.current || headerTeamsChatEnabled.current;
        }
    }, [headerChatEnabled.current, headerTeamsChatEnabled.current]);

    const { culture, language, isRTL } = useSuiteHeaderLanguageProps();

    const isFeedbackEnabled = isCapabilityEnabled(
        feedbackCapability,
        getGlobalSettingsAccountMailboxInfo()
    );

    const suiteServiceUrl = `${window.location.origin}${getConfig().baseUrl}/service.svc`;
    const isHelpPaneFeedbackEnabled = isFeatureEnabled('help-pane-feedback');
    const isHelpPanePrivacyChoicesEnabled = isFeatureEnabled('help-pane-privacy-choices');
    const shellUseNpmMeControl = isFeatureEnabled('fwk-shell-useNpmMeControl');
    const getShellDataOverrides = React.useMemo(() => {
        return {
            SuiteServiceUrl: suiteServiceUrl,
            HideMyProfileLink: isShadowMailbox.current,
            HideMyAccountLink: isShadowMailbox.current,
            AppHeaderLinkText: props.AppHeaderLinkText,
            AppHeaderLinkUrl: props.AppHeaderLinkUrl,
            MAXHelpEnabled: !props.MAXHelpDisabled,
            FeedbackLink: isFeedbackEnabled ? feedbackNavLink : undefined,
            PrivacyChoicesLink: isHelpPanePrivacyChoicesEnabled ? privacyChoicesNavLink : undefined,
            MAXFeedbackUrl: format(
                MaxFeedbackPaneURLFormat,
                isConsumer() ? ConsumerSocUrl : BusinessSocUrl
            ),
            DisableFeedbackLink: !isFeedbackEnabled || !isHelpPaneFeedbackEnabled,
            HelpLink: props.MAXHelpDisabled ? helpNavLink : undefined,
            UniversalMeControlEnabled: getApplicationSettings('UniversalMeControl').enabled,
            SocHelpUrl: isConsumer() ? ConsumerSocUrl : BusinessSocUrl,
            AccountSwitchingEnabled: accountSwitchingEnabled,
            NpmMeControlEnabled: shellUseNpmMeControl,
            // DO NOT OVERRIDE PrivacyLink and LegalLink here.
        };
    }, [
        suiteServiceUrl,
        props.AppHeaderLinkText,
        props.AppHeaderLinkUrl,
        props.MAXHelpDisabled,
        isFeedbackEnabled,
        isHelpPaneFeedbackEnabled,
        accountSwitchingEnabled,
        isOcpsStoreReady(),
    ]);

    const suiteHeader = (
        <>
            <PendingAccountsWelcomeCallout />
            <RepromptDialog />
            {isFeatureEnabled('acct-newOutlookPromoInOwa') && <NewOutlookPromoDialog />}
            {searchContainerPortal}
            {searchScopePortal}
            {notificationPortal}
            <SuiteHeader
                onLinkClick={onLinkClick}
                culture={culture}
                language={language}
                isRTL={isRTL}
                isConsumer={isConsumer()}
                userDisplayName={sessionSettings?.UserDisplayName}
                userPrincipalName={userPrincipalName}
                workloadID={
                    isFeatureEnabled('mon-diag-helpTargeting') && getNativeHostVersion()
                        ? 'Monarch'
                        : 'Exchange'
                }
                appBrandTheme={APP_BRAND_THEME}
                currentMainLinkElementID={ShellMailId}
                themeData={themeDataOverride}
                customLayout={createCustomLayout()}
                meSublinks={createMeSublinks()}
                shellAssetsContainerOverride={getResourcePathUrl('suiteux-shell')}
                navBarData={navBarData}
                onSignOut={signOut}
                enableMicaForHeader={getIsMicaEffectEnabled()}
                onFlexPaneVisibilityChanged={onFlexPaneVisibilityChanged}
                helpSublinks={props.MAXHelpDisabled ? props.helpSublinks : undefined}
                shellDataOverrides={getShellDataOverrides}
                shellAuthProviderConfig={
                    accountSwitchingEnabled && userPrincipalName
                        ? getAuthProviderConfig(userPrincipalName)
                        : undefined
                }
                renderedCallback={suiteRenderedCallback}
                chatButtonRenderedCallback={chatButtonRenderedCallback}
                supportShyHeaderMode={isCapabilityEnabled(shyHeaderCapability)}
                disableToasts={true} // This will turn off suite header toasts
                userID={sessionSettings?.UserPuid}
                helpNamespace={
                    isConsumer()
                        ? isFeatureEnabled('help-rave-tickets')
                            ? 'OLWACB'
                            : 'OLWAC'
                        : isFeatureEnabled('mon-diag-helpTargeting') && getNativeHostVersion()
                        ? 'NWOUTWIN'
                        : undefined
                }
                shouldEnableChat={isChatEnabled}
                shouldLoadEmbedChatPreAuthOnIdle={
                    isFeatureEnabled('fwk-teamsSDKPreAuth') && isFeatureEnabled('fwk-teamsSuite')
                        ? shouldLoadPreAuthOnIdleCallbackPromise
                        : undefined
                }
                getUnreadChatCountOnIdle={
                    isFeatureEnabled('fwk-teamsSuite')
                        ? getChatUnreadMessageCallbackPromise
                        : undefined
                }
                hideTenantLogoForInitialRender={hideTenantLogoForInitialRender}
                getTokenRequestDictionary={
                    isFeatureEnabled('fwk-suite-fetchpreauthtoken') ? fetchTokenForShell : undefined
                }
            />
        </>
    );

    const wrapSuiteHeader = props.wrapSuiteHeader;
    if (wrapSuiteHeader) {
        return <>{wrapSuiteHeader(suiteHeader)}</>;
    } else {
        return <div tabIndex={-1}>{suiteHeader}</div>;
    }
}, 'OwaSuiteHeader');

function getAuthProviderConfig(userPrincipalName: string) {
    const authProviderConfigType = isConsumer() ? 'webMsaWithAadProxy' : 'webAadWithMsaProxy';

    return {
        type: authProviderConfigType,
        login_Hint: userPrincipalName,

        /* The appSignInUrl & appSignInToUrl are similar to the respective appSwitch urls,
                the difference being that these are meant for the app experience where there is
                no primary signed in user/user hasn't logged in yet. Since OWA doesn't have an
                this UX, setting these to match the corresponding appSwitch urls. */
        appSignInUrl: () => {
            return getSignInToDifferentAccountUrl();
        },

        appSignInToUrl: (params: any) => {
            return getAppSwitchToUrl(params);
        },

        /* This is the url used to signout the primary/logged in user. */
        appSignOutUrl: () => {
            return getAppSignOutUrl();
        },

        /* The appSwitchUrl is used for signing into a different account. */
        appSwitchUrl: () => {
            return getSignInToDifferentAccountUrl();
        },

        /* The appSwitchToUrl is used for switching to an account from the remembered accounts list,
           this is customised based on the type of account being switched to (aad vs msa). */
        appSwitchToUrl: (params: any) => {
            return getAppSwitchToUrl(params);
        },

        /* The wreply url for the aad & msa configs below, needs to be a ReplyTo Url registered with the respective identity provider, and should match the domain of the logged in user.
            It is used to generate the url to fetch remembered accounts, and signOut, signOutAndForget, forgetUser links that apply to each of the individual remembered accounts. */
        aad: {
            wreply: getReplyToUrl(),
            appId: getConfig().applicationId,
        },
        msa: {
            wreply: getReplyToUrl(),
            siteId: OWA_CONSUMER_APP_ID, // TODO: custom consumer app id?
        },
    } as any;
}

function isAccountSwitchingParamPresent() {
    var accountSwitchingParamValue = getQueryStringParameter('actSwt');
    return accountSwitchingParamValue?.toLowerCase() == 'true';
}

function getAadLoginDomain() {
    // When the currently logged in user is an aad user, we want to use the same domain for logging into aad,
    // to account for scenarios where outlook.office.com domain could be blocked for the user.
    // When the logged in user is an MSA type, since the aad domain would be different from the user domain,
    // we are explicitly passing https://outlook.office.com
    return isConsumer() ? 'https://outlook.office.com' : getOrigin();
}

function getReplyToUrl() {
    return `${getOrigin()}/${getRootVdirName()}/`;
}

function getAppSignOutUrl() {
    return `${getReplyToUrl()}logoff.owa`;
}

function getSignInToDifferentAccountUrl() {
    return `${getAadLoginDomain()}/${getRootVdirName()}/?${SELECT_ACCOUNT_PARAM}&${ACCOUNT_SWITCH_PARAM}${getAppendBootFlightsParam()}`;
}

function getAppSwitchToUrl(params: any) {
    if (!params || !params.nextAccount) {
        return '';
    }

    // This aad login url is used when the account selected is an aad account type.
    const aadLogin = getAadLoginDomain();

    // This is the login domain used for switching to the selected account. This depends on the account type - when selected account
    // is aad type, aadLogin would be used, and for consumer type, live.com login would be used instead.
    const domain =
        params.nextAccount.type?.toLowerCase() == 'aad' ? aadLogin : 'https://outlook.live.com';

    const account = params.nextAccount.memberName;
    const appSwitchToUrl = `${domain}/${getRootVdirName()}/?${LOGIN_HINT_PARAM}=${encodeURIComponent(
        account
    )}&${ACCOUNT_SWITCH_PARAM}${getAppendBootFlightsParam()}`;

    return appSwitchToUrl;
}

function getAppendBootFlightsParam(): string {
    // Enable internal testing with 'appendbootflights' query parameter
    const bootflights = getQueryStringParameter(APPEND_BOOT_FLIGHTS_PARAM);
    const appendBootFlightParam = bootflights ? `&${APPEND_BOOT_FLIGHTS_PARAM}=${bootflights}` : '';

    return appendBootFlightParam;
}

function useReactPortal(renderFn: ((container: HTMLDivElement) => JSX.Element) | undefined) {
    const [container, setContainer] = React.useState<HTMLDivElement>();
    return [
        setContainer,
        container && renderFn && createPortal(renderFn(container), container),
    ] as const;
}

function onLinkClick(data: LinkClickEventArgs) {
    if (data && data.Id == 'Calendar') {
        logCoreUsage('ModuleSwitchToCalendar', {
            source: 'mailWaffle',
        });
    }
}

function signOut(eventArgs: SignOutEventArgs) {
    eventArgs.triggerEvent.preventDefault();
    lazySignout.importAndExecute(location);
}

const onFlexPaneVisibilityChanged = (isVisible: boolean) => {
    setAppPaneUnderlayVisibility('suiteFlexPane', isVisible, true /* isShrinkable */);
};

function pushOnCondition(
    controlCollection: ShellControl[],
    control: ShellControl,
    condition?: boolean
) {
    if (condition === undefined || condition) {
        controlCollection.push(control);
    }
}

function createHelpLink(
    id: string,
    helpText: string,
    url?: string,
    action?: () => void
): NavBarLinkData {
    return {
        Id: id,
        Text: helpText,
        Url: url,
        TargetWindow: '_blank',
        Action: action,
    } as NavBarLinkData;
}

function showOpenAnotherMailboxDialog() {
    const [modalPromise] = showModal(OpenAnotherMailboxDialog);
    modalPromise.then(targetMailbox => {
        if (targetMailbox && targetMailbox.length > 0) {
            const encodedTargetMailbox = targetMailbox
                .split('@')
                .map(s => encodeURIComponent(s))
                .join('@');
            window.open(getNewScope(encodedTargetMailbox), '_blank');
        }
    });
}

function getWorkloadHelpSublinks(
    originalLinks: NavBarLinkData[] | null | undefined
): NavBarLinkData[] {
    if (isWorkloadSupported(2)) {
        originalLinks = (originalLinks || []).concat([
            createHelpLink(
                'OwaCalendarAbuseLink',
                loc(reportCalendarAbuseLink),
                'https://www.microsoft.com/concern/calendarabuse'
            ),
        ]);
    }
    return originalLinks || [];
}
